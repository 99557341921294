import React, { useState, useEffect } from "react";
import { setBgAction } from "actions/setBgAction";
import { setBackgroundColorAction } from "actions/setBackgroundColorAction";
import { setElementAction } from "actions/setElementAction";
import { BrowserRouter, Route, Switch, Link , useHistory} from "react-router-dom";
import { setUserAction } from "actions/setUserAction";
import { connect } from "react-redux";
import SVG from "svg.js";
import "svg.draggable.js";
import "svg.select.js";
import "svg.resize.js";
import Canvg from 'canvg';
import {Auth }from "aws-amplify";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Heading,
    Input, 
    Button,
    Link as ChakraLink,
    DrawerCloseButton,
    Stack,
    Box,
    FormControl,
    InputRightAddon,
    FormLabel, InputGroup, InputLeftAddon,
    Select, 
    FormHelperText,
    useDisclosure,
    Textarea
  } from "@chakra-ui/core";
import { Event } from "../Tracking";

export function ExportDrawer(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const firstField = React.useRef();
    const btnRef = React.useRef();

    const { isAgent, design } = props;
    const [particlesBackgroundCSS, setParticlesBackgroundCSS] = React.useState(`#particles {\ndisplay: block; \nmax-width: 100%;\nheight: auto;}`);
    const [designConfig, setDesignConfig ] = React.useState("");
    const [currentCSS, setCurrentCSS ] = React.useState("");
    const [currentHTMLString, setCurrentHTMLString ] = React.useState("");
    const [gradient_wave_str, setGradientWaveString ] = React.useState("");


    let history = useHistory();

    const exportImage = () => {
      switch(props.design.designType) {
        case "gradient":
          exportGradientImage()
          break;

        case "triangles":
          exportTriangleImage();
          break;
      }
    }

    
  const signInWithGoogle = (event) => {
    // event.preventDefault();
    // var provider = new firebase.auth.GoogleAuthProvider();

    // firebase.auth().signInWithPopup(provider).then(function(result) {
    //   // This gives you a Google Access Token. You can use it to access the Google API.
    //   var token = result.credential.accessToken;
    //   // The signed-in user info.
    //   var user = result.user;

    // }).catch(function(error) {
    //   // Handle Errors here.
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // The email of the user's account used.
    //   var email = error.email;
    //   // The firebase.auth.AuthCredential type that was used.
    //   var credential = error.credential;
    //   // ...
    // });
  
  }

    const exportGradientImage = () => {
      var c = document.createElement("canvas");
      let height = 800;
      let width = 1000;
      c.height = height;
      c.width = width;

      var ctx = c.getContext("2d");

      var x1 = width, y1 = height, x2, y2, angle,
    length = height;
      
      // var length = 1000, angle = Number(props.design.body.degree);
      // let x = 0;
      // let y = height;
      // var grd = ctx.createLinearGradient(x, y, x + Math.cos(angle) * length, y + Math.sin(angle) * length);
      // grd.addColorStop(0, props.design.body.color1);
      // grd.addColorStop(1, props.design.body.color2);
      
      // ctx.fillStyle = grd;
      // ctx.fillRect(0, 0, width, height); 

      var angle = 225 * Math.PI / 180,
      x2 = height * Math.cos(angle),
      y2 = height * Math.sin(angle),
      gr = ctx.createLinearGradient(0, 0, x2, y2);

  
      gr.addColorStop(0, props.design.body.color1);
      gr.addColorStop(1, props.design.body.color2);
      
      ctx.fillStyle = gr;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  


      c.toBlob(function(blob) {
        var url  = window.URL.createObjectURL(blob);
        window.location.assign(url);
      });
    }

    const exportTriangleImage = () => {

      let elem = document.getElementById("triangles");
      let svg = elem.firstChild;
      //

      let svg_str = svg.innerHTML;  
  
      let draw = SVG("triangles_elem").size("100%", "100%");
      let group = draw.group("main");
      let gradient_group = draw.group("gradient");
  
      draw.viewbox(0, 0, 1400, 900);
  
      let svg_elem_group = group.svg(svg_str);
  
      let gradient = draw.gradient("linear", (add) => {
        add.at(0, props.design.body.color1);
        add.at(1, props.design.body.color2);
      });
      
      let svg_background = group
        .rect(1400, 1900).move(0, 0)
        .fill(gradient)
        .id("gradient_background")
        .opacity(1.0);
      svg_background.back();
      let svg_string = draw.svg();
      //

      const canvas_svg = document.createElement('canvas');
      canvas_svg.width = 1400;
      canvas_svg.height = 900
      const ctx = canvas_svg.getContext('2d');
      
      let svg_elem = elem.firstChild;
  
      var serializer = new XMLSerializer();
      var svgString = serializer.serializeToString(svg_elem);
  
      let v = Canvg.fromString(ctx, svg_string);

  
      // // Start SVG rendering with animations and mouse handling.
      v.start();

      canvas_svg.toBlob(function(blob) {
        var url  = window.URL.createObjectURL(blob);
        window.location.assign(url);
      });
    };

    const getGradientString = () => {
      return `background: linear-gradient(${props.design.body.deg}deg, ${props.design.body.color1} 0%, ${props.design.body.color2} 100%);`;
    }

    const getRadialGradientString = () => {
      return `background: linear-gradient(45deg, ${props.design.body.color1} 0%, rgba(225, 5, 34, 0) 70%) repeat scroll 0% 0%, linear-gradient(135deg, ${props.design.body.color2} 10%, rgba(49, 5, 209, 0) 80%) repeat scroll 0% 0%, linear-gradient(225deg, ${props.design.body.color3} 10%, rgba(10, 219, 216, 0) 80%) repeat scroll 0% 0%, rgba(0, 0, 0, 0) linear-gradient(315deg, ${props.design.body.color4} 100%, rgba(9, 245, 5, 0) 70%) repeat scroll 0% 0%;`;

    }

    const exportAnimatedGradient = () => {
      let gradient_str = getGradientString();

      let animated_gradient_str = `.anim_gradient {
${gradient_str}
        backgroundSize : "400% 400%";
        animation : "gradient 25s ease infinite";
    }
      
@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
};`
      setCurrentCSS(animated_gradient_str);
    }

    const exportSVGWavesAnimated = () => {

      let gradient_wave_str = `<div class="wave">
      <svg width="100%" height="200px" fill="none" version="1.1"
       xmlns="http://www.w3.org/2000/svg">
        <path 
          fill="white" 
          d="
            M0 67
            C 273,183
              822,-41
              1920.01,106 
            V 359 
            H 0 
            V 67
            Z">
        </path>
      </svg>
    </div>
    <div id="container">
        <h1>Your content here</h1>
    </div> `
      setCurrentHTMLString(gradient_wave_str);

    let wave_css = `body{
      height: 100%;
      margin: 0;
    }
    div.wave{
      width: 100%;
      bottom: 0;
      position: absolute;
      z-index: 99;
    }
    
    .wave svg{
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    }
    
    #container {
        position: relative;
        height: 90vh;
    }`

    setCurrentCSS(wave_css);
    }

    const exportGradientWaves = () => {

      let gradient_wave_str = `<div class="wave">
      <svg width="100%" height="200px" fill="none" version="1.1"
       xmlns="http://www.w3.org/2000/svg">
        <path 
          fill="white" 
          d="
            M0 67
            C 273,183
              822,-41
              1920.01,106 
            V 359 
            H 0 
            V 67
            Z">
        </path>
      </svg>
    </div>
    <div id="container">
        <h1>Your content here</h1>
    </div> `
      setCurrentHTMLString(gradient_wave_str);

    let gradient_str = `linear-gradient(${props.design.body.gradient.deg}deg, ${props.design.body.gradient.color1} 0%, ${props.design.body.gradient.color2} 100%)`
    let wave_css = `body{
      height: 100%;
      margin: 0;
    }
    div.wave{
      width: 100%;
      bottom: 0;
      position: absolute;
      z-index: 99;
    }
    
    .wave svg{
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    }
    
    #container {
        position: relative;
        background: ${gradient_str};
        height: 90vh;
    }`

    setCurrentCSS(wave_css);
    }

    const openDrawer = async () => {
      onOpen();
      Event("EXPORT_DESIGN", "Design exported", "EXPORT_DESIGN");
      // const user = await Auth.currentAuthenticatedUser();
      // let num_designs;
      
      // try {
      //   const currentUserInfo = await Auth.currentUserInfo()
      //   const num_designs_created = currentUserInfo.attributes['custom:num_designs_created'];
      //   if (num_designs_created == undefined) {
      //     num_designs = 0;
      //   }
      //   else {
      //     num_designs = num_designs_created;
      //   }
      // } catch (err) {
      // }
      
      // let new_num_designs_created = num_designs++;
      // let result = await Auth.updateUserAttributes(user, {
      //     'custom:num_designs_created': String(new_num_designs_created)
      // });
    }


  
    useEffect(() => {    
         if (props.design) {
             let design_str = JSON.stringify(props.design.body);
             if (props.design.designType == "animated") {
                setDesignConfig(design_str);
             }
             else if (props.design.designType == "gradient") {
              let gradient_str = getGradientString();
              setCurrentCSS(gradient_str);
             }
             else if (props.design.designType == "radialGradient") {
              let gradient_str = getRadialGradientString();
              setCurrentCSS(gradient_str);
             }
             else if (props.design.designType == "animated_gradient") {
               setCurrentHTMLString("<div class='anim_gradient'></div>")
                exportAnimatedGradient();
                
             }
             else if (props.design.designType == "triangles") {

             }
             else if (props.design.designType == "svgWaves") {
              
              if (props.design.body.gradient) {
                exportGradientWaves();

              }
            }
            else if (props.design.designType == "svgWavesAnimated") {
              setDesignConfig(design_str);
              exportSVGWavesAnimated();
            }
         }
      });
  
    return (
      <>
        <Button ref={btnRef} leftIcon="add" variantColor="pink" onClick={openDrawer}>
          Export
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          initialFocusRef={firstField}
          finalFocusRef={btnRef}
          size="xl"
          onClose={onClose}
          className="drawer"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Export Background
            </DrawerHeader>
  
            <DrawerBody>
              <Stack spacing="24px">
                {/* <Box>
                  <FormLabel htmlFor="username">Name</FormLabel>
                  <Input
                    ref={firstField}
                    id="username"
                    placeholder="Please enter user name"
                  />
                </Box> */}
  
                {/* <Box>
                  <FormLabel htmlFor="url">Url</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>http://</InputLeftAddon>
                    <Input
                      type="url"
                      id="url"
                      placeholder="Please enter password"
                      rounded="0"
                    />
                    <InputRightAddon>.com</InputRightAddon>
                  </InputGroup>
                </Box> */}
{/*   
                <Box>
                  <FormLabel htmlFor="owner">Select Val</FormLabel>
                  <Select id="val" defaultValue="val1">
                    <option value="val1">Owner 1</option>
                    <option value="val2">Kola Tioluwani</option>
                  </Select>
                </Box> */}
            <div id="triangles_elem" className={(props.design.designType == "triangles") ? "" : "hidden"}></div>

                <Box className={(props.design.designType == "animated" || props.design.designType == "svg_overlay" || props.design.designType == "svgWavesAnimated") ? "" : "hidden"}>

                  <FormLabel htmlFor="desc">Particles Config</FormLabel>
                  <Textarea id="desc" defaultValue={designConfig} height="300"/>

                  <h2 className="">How To Set It Up</h2>
                  <p>Backgrounds are animated using TsParticles. See its documentation for details.
                  </p>

                  <p>Need more help?</p> <a href="https://websitebackgroundmaker.com/contact">Click here to get in touch</a>
                </Box>

                <Box className={(props.design.designType == "animated" || props.design.designType
                == "animated_gradient" || props.design.designType == "svgWaves" || props.design.designType == "svgWavesAnimated") ? "" : "hidden"}>

                  <FormLabel htmlFor="content_html">HTML</FormLabel>
                  <Textarea id="content_html" defaultValue={currentHTMLString} height="700"/>
                </Box>

                <Box className={(props.design.designType == "animated" || props.design.designType == "gradient" || props.design.designType
                == "animated_gradient" || props.design.designType == "svgWaves" || props.design.designType == "radialGradient" 
                ||props.design.designType == "svgWavesAnimated") ? "" : "hidden"}>

                  <FormLabel htmlFor="desc">CSS</FormLabel>
                  <Textarea id="desc" defaultValue={currentCSS} height="700"/>

                  <Box>
                    <Heading fontSize="xl" marginTop="1em">Get 100+ Premium Backgrounds</Heading>
                    <p>Want even more backgrounds? Discover <ChakraLink color="teal.500" href="https://www.isotopeui.com/?ref=wbmapp"> 
                    more backgrounds here</ChakraLink>, perfect for commercial and personal projects
                     
                    </p>

                  </Box>
                </Box>
                
                <Box className={(props.design.designType == "triangles") ? "" : "hidden"}>

                  <Box>
                    <FormLabel htmlFor="desc">Export Image</FormLabel>
                    <Button onClick={exportImage}>Export as PNG</Button>
                  </Box>

                  <Box>
                    <Heading fontSize="xl" marginTop="1em">Add As SVG to Your Website</Heading>
                    <p>Discover how to add triangle backgrounds to   <ChakraLink color="teal.500" href="https://www.frontendvision.io/blog/triangle-gradient-background">
                    your website here
                      </ChakraLink>
                    </p>

                  </Box>
                </Box>
                <Box className={props.user ? "" : "hidden"}>
                  <FormLabel htmlFor="desc">Want to View Later?</FormLabel>

                  <form>

                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3 mb-3">
                          <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 relative flex items-center"
                          onClick={event => {signInWithGoogle(event)}}
                          type="submit">
                            <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                            </svg>
                            <span className="flex-auto pl-16 pr-8 -ml-16 button">Sign Up with Google</span>
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3">
                          <button className="btn px-0 text-white bg-blue-900 hover:bg-blue-800 relative flex items-center"
                            type="submit">
                            <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.95 0C3.578 0 0 3.578 0 7.95c0 3.479 2.286 6.46 5.466 7.553.397.1.497-.199.497-.397v-1.392c-2.187.497-2.683-.993-2.683-.993-.398-.895-.895-1.193-.895-1.193-.696-.497.1-.497.1-.497.795.1 1.192.795 1.192.795.696 1.292 1.888.895 2.286.696.1-.497.298-.895.497-1.093-1.79-.2-3.578-.895-3.578-3.975 0-.895.298-1.59.795-2.087-.1-.2-.397-.994.1-2.087 0 0 .695-.2 2.186.795a6.408 6.408 0 011.987-.299c.696 0 1.392.1 1.988.299 1.49-.994 2.186-.795 2.186-.795.398 1.093.199 1.888.1 2.087.496.596.795 1.291.795 2.087 0 3.08-1.889 3.677-3.677 3.875.298.398.596.895.596 1.59v2.187c0 .198.1.497.596.397C13.714 14.41 16 11.43 16 7.95 15.9 3.578 12.323 0 7.95 0z" />
                            </svg>
                            <span className="flex-auto pl-16 pr-8 -ml-16 button">Sign Up With Facebook</span>
                          </button>
                        </div>
                      </div>

                    </form>

                </Box>
              </Stack>
            </DrawerBody>
  
            <DrawerFooter borderTopWidth="1px">
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button variantColor="blue"  onClick={onClose}>Done</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    );
              }