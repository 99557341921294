import { createStore } from "redux";
import rotateReducer from 'reducers/rotateReducer';

function configureStore(state = 
    { "rotating": true,
        "bgColor": {color1: "#00DBDE", color2: "#675645"},
        "element": null,
        "backgroundColor": "#671234",
        downloadSVGElement: false,
        selectedElements: [],
        draw: null,
        design: {},
        backgroundPatternName: null,
    }) 
    
    {
  return createStore(rotateReducer,state);
}

export default configureStore;