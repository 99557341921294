import React from 'react';
import Header from '../partials/Header';
import Stats from '../partials/Stats';
import Process from '../partials/Process';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import { setBgAction } from "actions/setBgAction";
import { setElementAction } from "actions/setElementAction";
import { connect } from "react-redux";
import Particles from "react-tsparticles";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setElementAction: (payload) => dispatch(setElementAction(payload)),
});

class AnimatedBackgrounds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      container: {}, 
      particlesOptions: {"background":{"color":{"value":"#0d47a1"},"image":"linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)"},"particles":{"number":{"value":80,"density":{"enable":false}},"size":{"value":3,"random":true,"anim":{"speed":4,"size_min":0.3}},"links":{"enable":false},"move":{"random":true,"speed":1,"direction":"top","out_mode":"out"}},"interactivity":{"events":{"onhover":{"enable":true,"mode":"bubble"},"onclick":{"enable":true,"mode":"repulse"}},"modes":{"bubble":{"distance":250,"duration":2,"size":0,"opacity":0},"repulse":{"distance":400,"duration":4}}}},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)

  }

  render() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

        {/*  Site header */}
        <Header />
  
        {/*  Page content */}
        <main className="flex-grow">
  
          {/*  Page sections */}
          <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Section header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="zoom-y-out">Animated Backgrounds</h1>
                  <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                    We make it easy to create animated backgrounds, complete with gradients, 
                    particle effects and more. 
                  </p>
                </div>

                <Particles
                  id="particles_anim"
                        options={this.particlesOptions}
                        container={this.container}
                        height="800"
                        width="500"
                    /> 

              </div>
            </div>
          </section>


          <Cta />
  
        </main>
  
        {/*  Site footer */}
        <Footer />
  
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnimatedBackgrounds);
