import React, { useState } from "react";
import styles from "./DashboardHeaderNav.css";
import { connect } from "react-redux";
import SVG from "svg.js";
import { motion } from "framer-motion";
import { Input, Stack, Button, useColorMode, LightMode, DarkMode } from "@chakra-ui/core";
import { BrowserRouter, Route, Switch, Link, withRouter } from "react-router-dom";
import axios from 'axios';
import {logoutUser} from "utils/authentication";
import {LogOut, Moon} from "react-feather";
import { setLoggedOutAction } from "actions/setLoggedOutAction";
import { setUserAction } from "actions/setUserAction";
import { setDesignsAction } from "actions/setDesignsAction";
import Amplify, { Auth, Hub } from 'aws-amplify';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setLoggedOutAction: (payload) => dispatch(setLoggedOutAction(payload)),
  setUserAction: (payload) => dispatch(setUserAction(payload)),
  setDesignsAction: (payload) => dispatch(setDesignsAction(payload)),

});


function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <div>
        <Moon className="rightnav logout" onClick={toggleColorMode} color="gray" className="icon"/>

    </div>

  )
}

class DashboardHeaderNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          // getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          this.setState({user: null});
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
  }
  
  logout = (event) => {
    var app = this;
    
    if (this.props.user) {
      Auth.signOut();
    }
      app.props.setLoggedOutAction(true);
      app.props.setUserAction(null);
      app.props.setDesignsAction(null);
      localStorage.setItem("inixiaLoggedIn", false);
     
  
  }

  render() {
    return (
      <div>
        <div className="containerNav darkNav">
          <nav className="headerNav darkNav">
            <div className="leftnav_dashboard text-white">
              <Link to="/" className="dashboardLogo ml-4">
              <svg
                className="w-8 h-8"
                viewBox="0 0 32 32"
                width="40"
                height="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <radialGradient
                    cx="21.152%"
                    cy="86.063%"
                    fx="21.152%"
                    fy="99.063%"
                    r="79.941%"
                    id="header-logo"
                  >
                    <stop stopColor="hsla(217, 100%, 50%, 1) " offset="0%" />
                    <stop stopColor="hsla(186, 100%, 69%, 1) " offset="100%" />
                  </radialGradient>
                </defs>
                <rect
                  width="32"
                  height="32"
                  rx="16"
                  fill="url(#header-logo)"
                  fillRule="nonzero"
                />
              </svg>
              
            </Link></div>
            
            <div className="rightnav px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700">
              <Link to="/dashboard" class="rightnav">Home</Link></div>
              <Link to="/dashboard/account" class="rightnav">
              <div className="rightnav px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700">
                Account           
              </div>
                </Link>
              
              
              <a href="https://websitebackgroundmaker.com/contact" class="rightnav">
              <div className="rightnav px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700">
              Contact
              </div>
              </a>
            <div className="rightnav">
              <LogOut  className="rightnav logout" onClick={this.logout} color="gray" className="icon"/>
            </div>
            {/* <div className="rightnav">
              <ToggleDarkMode></ToggleDarkMode>
            </div> */}

            
          </nav>
        </div>
        


        {/* <nav className="headerNav">
            <Link to="/" className="logo">
                SVGMaker
            </Link>
            <ul class="navLinks">
                <li class="nav-item"><a href="#">Account</a></li>
                <li class="nav-item"><a href="#">Help</a></li>
                <li class="nav-item" onClick={this.logout}>Log Out</li>
            </ul>
        </nav> */}
      </div>
    );
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardHeaderNav));