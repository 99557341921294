import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import Typed from "typed.js";
import styles from "./GradientDemoPreview.css"
import DemoHeader from "partials/DemoHeader"
function GradientDemoPreview() {

  const [particleOptions, setParticleOptions] = useState({"background":{"color":{"value":"#0d47a1"},"image":"linear-gradient(180deg, hsla(280, 84%, 41%, 1) 0%, hsla(218, 97%, 56%, 1) 100%)"},"particles":{"number":{"value":80,"density":{"enable":false}},"size":{"value":3,"random":true,"anim":{"speed":4,"size_min":0.3}},"links":{"enable":false},"move":{"random":true,"speed":1,"direction":"top","out_mode":"out"}},"interactivity":{"events":{"onhover":{"enable":true,"mode":"bubble"},"onclick":{"enable":true,"mode":"repulse"}},"modes":{"bubble":{"distance":250,"duration":2,"size":0,"opacity":0},"repulse":{"distance":400,"duration":4}}}});
  const [particleContainer, setParticleContainer] = useState({});

  useEffect(() => {
  }, []);

  return (
      <section className="" id="gradient_demo">
                                

        <DemoHeader />
        <div
            class="container w-full p-20 m-4 mx-auto my-16 text-center border-blueGray-300 h-96 rounded-xl">
 
            <section class="text-gray-700 body-font">
            <div class="container px-8 pb-24 mx-auto lg:px-4">
                <div class="flex flex-col w-full mb-12 text-left lg:text-center">
                    <h2 class="mb-1 text-xs font-semibold tracking-widest text-center text-gray-400 uppercase title-font">a great
                        header right here</h2>
                    <h1 class="mb-6 text-2xl font-semibold tracking-tighter text-gray-200 sm:text-5xl title-font">
                        Website Background Preview
                        <br class="md:hidden" />
                                
                    </h1>
                    <p class="mx-auto text-base font-medium leading-relaxed text-gray-300 lg:w-2/3">This is a 
                    sample website landing, with a background from Inixia. If you like what you see, be sure to 
                    try our tool out!</p>
                </div>
                <div class="flex lg:justify-center">
                    <Link to="get-started">
                    <button
                        class="inline-flex px-4 py-2 font-semibold text-white transition duration-500 ease-in-out transform rounded-lg 
                        shadow-xl bg-gradient-to-r from-blue-700 hover:from-blue-600 to-blue-600 hover:to-blue-700 focus:shadow-outline 
                        focus:outline-none">Get Started</button>
                    </Link>
                </div>
            </div>
        </section>
        <section class="block " id="section">
            <div class="container flex flex-col items-center justify-center px-10 py-2 mx-auto lg:px-48">
                <img class="object-cover object-center mx-auto mb-10 rounded-lg lg:w-full md:w-15/5 w-20/6" alt="hero"
                    src="https://dummyimage.com/720x600/F3F4F7/64748b" />
            </div>
        </section>

        </div>
{/* <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8 bg-white pb-8 pt-8 pr-8 pl-8">
    <div>
      <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Or 
        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
           start your 14-day free trial
        </a>
      </p>
    </div>
    <form class="mt-8 space-y-6" action="#" method="POST">
      <input type="hidden" name="remember" value="true" />
      <div class="rounded-md shadow-sm -space-y-px">
        <div className="mb-4">
          <label for="email-address" class="sr-only">Email address</label>
          <input id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <input id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
          <label for="remember_me" class="ml-2 block text-sm text-gray-900">
            Remember me
          </label>
        </div>

        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            Forgot your password?
          </a>
        </div>
      </div>

      <div>
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">

            <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Sign in
        </button>
      </div>
    </form>
  </div>
</div> */}
      </section>
  );
}

export default GradientDemoPreview;