import React, { useState } from "react";
import styles from "./GradientTemplates.css";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import gradientData from "components/Gradients/gradient_collection_resource.json";

class GradientTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gradients: [],
    };
  }
  
  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPressed.bind(this));
    window.scrollTo(0, 0);

  }
  
  componentWillUnmount() {
    // remove event listener
    document.removeEventListener("keydown", this.onKeyPressed.bind(this));
  }

  onKeyPressed(e) {
    if (e.keyCode == "13") {
      e.preventDefault();
      // submit login form
      this.handleSubmit();
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
  };

  createGradientElems() {
    var gradient_elems = this.state.gradients.map((gradient, i) => {
      return (
          <article className="flex flex-col h-full" data-aos="zoom-y-out">
            <Link to={`/app?designType=gradient&gradientName=${gradient.name}`} className="block mb-6">
              <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded-lg">

                <div
                className="gradient absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                // onClick={() => this.handleClick(gradient)}
                style={{
                width: "352",
                height: "198",
                backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.color1}, ${gradient.color2})`,
                }}
                />
              </figure>
            </Link>
            <div className="mb-3">

            </div>
            <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
              <Link to="/app?designType=svgOverlay" className="hover:underline"></Link>
            </h3>

        </article>

      );
    });
    return gradient_elems;
  }

  render() {
    this.state.gradients = gradientData.gradients;

    let gradient_elems = this.createGradientElems();

    return (
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-16 pb-12 md:pb-20">
    
              {/* Page header */}
              <div className="max-w-xl pb-12 pt-12 md:pb-20 text-center md:text-left">
                <h1 className="h1 text-3xl">Gradient Templates</h1>
                <h2 className="h2 text-gray-700 text-xl">Choose from a variety of gradient templates below.</h2>
              </div>
    
              {/* Section tags */}
              {/* <div className="border-b border-gray-300 pb-4 mb-12">
                <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
                  <li className="mx-5 my-1">
                    <a className="text-blue-600" href="#0">All</a>
                  </li>
                  <li className="mx-5 my-1">
                    <a className="text-gray-800 hover:underline" href="#0">Tutorials</a>
                  </li>
                  <li className="mx-5 my-1">
                    <a className="text-gray-800 hover:underline" href="#0">Tips & Tricks</a>
                  </li>
                  <li className="mx-5 my-1">
                    <a className="text-gray-800 hover:underline" href="#0">Templates</a>
                  </li>
                  <li className="mx-5 my-1">
                    <a className="text-gray-800 hover:underline" href="#0">Guides</a>
                  </li>
                </ul>
              </div> */}
    
              {/* Articles list */}
              <div className="max-w-6xl mx-auto">
                <div className="pb-12 md:pb-20">

                {/* Gradients list */}
                <div className="max-w-sm mx-auto md:max-w-none">

                    {/* Gradients container */}
                    <div className="grid gap-12 md:grid-cols-4 md:col-gap-6 md:row-gap-8 items-start">

                    {/* 1st gradient */}
                    {gradient_elems}
                    </div>

                </div>

                </div>
            </div>


    
            </div>
          </div>
        </section>
    );
  }
}

export default GradientTemplates;
