import React from 'react';

import Header from '../partials/Header';
import Stats from '../partials/Stats';
import Team from '../partials/Team';
import Process from '../partials/Process';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import { setBgAction } from "actions/setBgAction";
import { setElementAction } from "actions/setElementAction";
import { connect } from "react-redux";
import TeamImages from 'partials/TeamImages';

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setElementAction: (payload) => dispatch(setElementAction(payload)),
});

class TriangleBackgrounds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)

  }

  render() {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header/>
  
        {/*  Page content */}
        <main className="flex-grow">
  
          {/*  Page sections */}
          <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Section header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="zoom-y-out">Triangle Backgrounds</h1>
                  <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                    Create triangle backgrounds easily, plus add gradients too!
                  </p>
                </div>

                <figure className="flex justify-center items-start" data-aos="zoom-y-out" data-aos-delay="250">
                  <img className="rounded shadow-2xl" src={require('../images/triangle_background.PNG')} width="768" height="432" alt="Triangle backgrounds" />
                </figure>

              </div>
            </div>
          </section>

          <Cta />
  
        </main>
  
        {/*  Site footer */}
        <Footer />
  
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TriangleBackgrounds);
