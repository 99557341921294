import React, { useState } from "react";
import styles from "./Designs.css";
import { connect } from "react-redux";
import SVG from "svg.js";
import { motion } from "framer-motion";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Skeleton,
} from "@chakra-ui/core";
import {withToast} from "components/Home/Home"
import {
  Link,
  withRouter,
} from "react-router-dom";
import axios from "axios";
import {Auth, Hub, API} from 'aws-amplify';
import Particles from "react-tsparticles";
import { Box, Image, StatArrow, Badge, Flex } from "@chakra-ui/core";
import {Storage} from "aws-amplify";
import canvg from "canvg";
import {setUserAction} from "actions/setUserAction";
import {setDesignsAction} from "actions/setDesignsAction";
import {setDesignAction} from "actions/setDesignAction";
import { graphqlOperation } from 'aws-amplify';
import { listTodos } from 'graphql/queries';
import { deleteTodo, createTodo } from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import queryString from "query-string";
import { Event } from "../Tracking";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (payload) => dispatch(setUserAction(payload)),
  setDesignsAction: (payload) => dispatch(setDesignsAction(payload)),
  setDesignAction: (payload) => dispatch(setDesignAction(payload)),
});

class Designs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: true,
      errors: [],
      error_msg: "",
      emptyState: null,
      isAuth: false,
      designs: [],
      svgs: [],
      images: [],
      design_elems: [],
      svgwaves_animated_urls: [],
      animated_containers : [],
      user: null,
      authToken: null,
      userData: null,
      triangle_urls: [],
      animated_image_urls: [],
      svgwave_urls: [],
      img_url: "",
    };
  }

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {console.log('Not signed in')});
  }


  async componentDidMount() {
    const value = queryString.parse(this.props.location.search);
    const onboarding = value.onboarding;
    if (onboarding == "true") {
      this.setState({emptyState: true});
    }
    else {
      this.getImages();

    }

    var app = this;
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          this.getUser().then(userData => {
            this.setState({userData: userData})
            this.props.setUserAction(userData);
            // if (!onboarding) {
            //   console.log("FETCHING DESIGNS FROM SIGN IN HUB")
            //   app.fetchDesigns();

            // }
          });
          break;
          case 'signUp':
            break;
        case 'cognitoHostedUI':

          this.getUser().then(userData => {
            this.setState({userData: userData})
            this.props.setUserAction(userData);
          });
          break;
        case 'signOut':

          this.setState({user: null});
          break;
        case 'signIn_failure':
          break;
        case 'cognitoHostedUI_failure':
          break;
      }
    });

    this.getUser().then(userData => {
      if (userData) {
        this.setState({userData: userData}); 
        this.props.setUserAction(userData);
        try {
          console.log("is subscribed ", userData.attributes['custom:subscribed'])
          const subscribed = userData.attributes['custom:subscribed'];
          if (subscribed == undefined) {
            // app.signUpToNewsletter();
          }
        } catch (err) {
          console.log('error fetching user info');
        }

        app.fetchDesigns();
      }

     });


    }


    componentDidUpdate(prevProps, prevState, snapshot) {
      // if (prevProps.user != this.props.user && this.props.user != null) {
      //   this.getDesigns();
      // }
    }

    async fetchDesigns() {
      // const designs_response = await API.graphql(graphqlOperation(listTodos));

      const designs_response = await API.graphql({
        query: listTodos,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });


      let designs = designs_response.data.listTodos.items;
      console.log("designs ", designs_response)
      if (designs.length == 0) {
        this.setState({ emptyState: true, isLoading: false });
      }
      else {
        this.setState({isLoading: false, designs: designs, emptyState: false});
        this.initDesignElems();
      }

      this.props.setDesignsAction(designs);
    
    }

    async signUpToNewsletter() {
      const apiName = 'inixiawbmrestapi'
      const apiEndpoint = '/subscriber'
      const body = {
          email:   this.state.userData.attributes.email
      }
      const session = await API.post(apiName, apiEndpoint, { body });
  

        try {
          const result = await Auth.updateUserAttributes(this.state.userData, {
              'custom:subscribed': 'true'
          });
        } catch (err) {
          console.log('error fetching user info');
        }
    }

    createNewDesign() {
      Event("DESIGN", "Navigate to Create New Design", "CREATE_DESIGN")
      this.props.history.push("/get-started");
    }

    async initDesignElems() {
      let designs = this.state.designs;
      for (var i = 0; i < designs.length; i++) {
        var design = designs[i];
        let string_id = String(design.id + i);
        let designs_cont = document.getElementById("designs");

        if (design.designType == "svg") {

          let svg_container = document.createElement("div");

          svg_container.id = string_id;
          svg_container.className = "svg_container";

          // let designs_cont = document.getElementById("designs");

          // var draw = SVG(string_id).size("100%", "100%");
          // draw.viewbox(0, 0, 497, 480);
          // draw.svg(design.body);
          // designs_cont.appendChild(svg_container);

          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let design_str = JSON.parse(design.body);


          let canvas_str = `<svg width='1000' height='800'>${design_str.defs} ${design_str.svg_str}</svg>`;

          let v = canvg.fromString(ctx, canvas_str);
          v.start();
          var img = canvas.toDataURL("image/png");
          let image_elem = document.createElement("img");

          image_elem.src = img;
          design.image_src = img;

          this.state.images.push(image_elem);
        }
        else if (design.designType == "animated") {

          let body = JSON.parse(design.body);
          //body.interactivity.events.onClick.enable = "false";
          //body.interactivity.events.onHover.enable = "false";
          design.body = body;
          if (design.body.backgroundMode) {
            design.body.backgroundMode.enable = false;
          }
          design.parsedBody = body;
          let new_container = {};
          design.container = new_container;

          let img_name = design.id + ".png";
          var app = this;
          Storage.get(img_name, {level: "private"}).then(url =>
          {
            let animated_image_urls = this.state.animated_image_urls;
            animated_image_urls.push(url);
            app.setState({animated_image_urls: animated_image_urls});

          })
        }

        else if (design.designType == "triangles") {
          let img_name = design.id + ".png";
          var app = this;
          Storage.get(img_name, {level: "private"}).then(url =>
          {
            let triangle_urls = this.state.triangle_urls;
            triangle_urls.push(url);
            app.setState({triangle_urls: triangle_urls});
          })
              
          
          // console.log("url ", url)
          // this.state.triangle_urls[design.id] = url;
        }

        else if (design.designType == "svgWaves") {
          let img_name = design.id + ".png";
          var app = this;
          Storage.get(img_name, {level: "private"}).then(url =>
          {
            let svgwave_urls = this.state.svgwave_urls;
            svgwave_urls.push(url);
            app.setState({svgwave_urls: svgwave_urls});
          })
              
          
          // console.log("url ", url)
          // this.state.triangle_urls[design.id] = url;
        }

        else if (design.designType == "svgWavesAnimated") {
          let img_name = design.id + ".png";
          var app = this;
          Storage.get(img_name, {level: "private"}).then(url =>
          {
            let svgwaves_animated_urls = this.state.svgwaves_animated_urls;
            svgwaves_animated_urls.push(url);
            app.setState({svgwaves_animated_urls: svgwaves_animated_urls});
          })
              
          
          // console.log("url ", url)
          // this.state.triangle_urls[design.id] = url;
        }

        else if (design.designType == "gradient" || design.designType == "animated_gradient" || design.designType == "radialGradient") {
          let body = JSON.parse(design.body);
          design.body = body;
        }

      }
      let elems = this.createDesignElems(designs);
      this.setState({design_elems: elems });
    }

    async getImgUrl(design) {
      let img_name = design.id + ".png";
      let url = await Storage.get(img_name, {
             level: "public" 
      });
      return url;
    }


  getDesigns() {
  //   const authToken = this.props.user.idToken;

  //   axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

  //   axios
  //     .get(
  //       "https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/designs"
  //     )
  //     .then((response) => {
        
  //       let designs = response.data;
  //       if (designs.length == 0) {
  //         this.setState({ emptyState: true, isLoading: false });
  //       } else {
  //         this.setState({designs: designs});
  //         this.props.setDesignsAction(designs);

  //         for (var i = 0; i < designs.length; i++) {
  //           var design = designs[i];
  //           let string_id = String(design.designId + i);
  //           let designs_cont = document.getElementById("designs");

  //           if (design.designType == "svg") {

  //             let svg_container = document.createElement("div");

  //             svg_container.id = string_id;
  //             svg_container.className = "svg_container";
  
  //             // let designs_cont = document.getElementById("designs");
  
  //             // var draw = SVG(string_id).size("100%", "100%");
  //             // draw.viewbox(0, 0, 497, 480);
  //             // draw.svg(design.body);
  //             // designs_cont.appendChild(svg_container);
  
  //             const canvas = document.createElement("canvas");
  //             const ctx = canvas.getContext("2d");
  
  //             let design_str = JSON.parse(design.body);

  
  //             let canvas_str = `<svg width='1000' height='800'>${design_str.defs} ${design_str.svg_str}</svg>`;
  
  //             let v = canvg.fromString(ctx, canvas_str);
  //             v.start();
  //             var img = canvas.toDataURL("image/png");
  //             let image_elem = document.createElement("img");
  
  //             image_elem.src = img;
  //             design.image_src = img;
  
  //             this.state.images.push(image_elem);
  //           }
  //           else if (design.designType == "animated") {
  //             console.log("design: ", design);
  //             let body = JSON.parse(design.body);
  //             // body.interactivity.events.onClick.enable = "false";
  //             // body.interactivity.events.onHover.enable = "false";
  //             design.body = body;
  //             if (design.body.backgroundMode) {
  //               design.body.backgroundMode.enable = false;
  //             }
  //             design.parsedBody = body;
  //             let new_container = {};
  //             design.container = new_container
  //           }

  //           else if (design.designType == "gradient" || design.designType == "animated_gradient") {
  //             let body = JSON.parse(design.body);
  //             design.body = body;
  //           }

  //         }
  //         let elems = this.createDesignElems(designs);
  //         this.setState({design_elems: elems });
  //       }
  //     })
  //     .catch((error) => {
  //       this.handleError(error);
  //       console.log("ERROR IN LOGIN", error);
  //     });
  }

  // handleLoad() {
  //   setTimeout(
  //     ()=>{window.dispatchEvent(new Event('resize'));},
  //     0.01
  //     );
  // }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onDesignClicked = (design) => {
    this.props.setDesignAction(design);
    this.props.history.push("/app?design=" + design.id);
  }

  
  firebaseAuth() {
        
    //   firebase.auth().onAuthStateChanged(function(user) {
  
    //     if (app.props.user == null) {
    //       if (app.state.isAuth == false) {
  
  
    //       if (user) {
    //           app.setState({isAuth: true});
    //           user.getIdToken().then(function(idToken) { 
    //             user.idToken = idToken;
    //             app.props.setUserAction(user);
    //          });
    //         }
    //       else if (user == null) {
    //         app.props.history.push("/login");
    //       }
  
    //       }
    //     }
    //     else {
    //       // if (app.props.designs != null) {
    //       //   let elems = app.createDesignElems(app.props.designs);
    //       //   app.setState({design_elems: elems, isLoading: false });
    //       // }
    //       // else {
    //         app.getDesigns();
    //       //}
    //     };
  
    // });
    }

  createDesignElems(designs) {
    // this.handleLoad();
    var element;

    const variants = {
      visible: { opacity: 1, scale: 1 },
      hidden: { opacity: 0,  scale: 0 },
    }

    let design_elems = designs.map((design, i) => {
      // if (design.designType == "animated") {
      //   console.log("creating animated design ; i is ", i);
      //   let new_container = {};
      //   this.state.animated_containers.push(new_container);

      //   element = <Box
      //               maxW="sm"
      //               borderWidth="1px"
      //               rounded="lg"
      //               overflow="hidden"
      //               className="design"
      //               data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
      //               >

      //               <Flex align="end" justify="end">

      //               <Menu justifyContent="right">
      //                   <MenuButton as={Button} leftIcon="drag-handle">
      //                   </MenuButton>
      //                   <MenuList>
      //                     <MenuItem>Download</MenuItem>
      //                     <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem>
      //                     <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
      //                   </MenuList>
      //               </Menu>

      //               </Flex>

      //               <div onClick={() => this.onDesignClicked(design)}>

      //               <Particles className="gradient_preview" params={design.parsedBody} resize={true} particlesRef={design.new_container} />

      //               <Box p="6">
      //                 <Box d="flex" alignItems="baseline">
      //                   <Badge rounded="full" px="2" variantColor="red">
      //                     Animated
      //                   </Badge>
      //                 </Box>

      //                 <Box
      //                   mt="1"
      //                   fontWeight="semibold"
      //                   as="h4"
      //                   lineHeight="tight"
      //                   isTruncated
      //                 >
      //                   design
      //                 </Box>

      //                 <Box as="span" color="gray.600" fontSize="sm">
      //                   {design.createdAt}
      //                 </Box>
      //               </Box>

      //               </div>
      //               </Box>
      //       // </motion.div>
      // }
      
      if (design.designType == "svg") {

        element = <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <Box
                maxW="sm"
                borderWidth="1px"
                rounded="lg"
                overflow="hidden"
                className="design"
              >
  
            <div>
            <div align="end" justify="end" className="flex_menu">
  
              <Menu justifyContent="right">
                    <MenuButton as={Button} leftIcon="drag-handle">
                    </MenuButton>
                    <MenuList>
                      <MenuItem>Download</MenuItem>
                      <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem>
                      <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
                    </MenuList>
              </Menu>
  
            </div>
            
            <Link to={"/app?design=" + design.id}>
  
                <Image src={design.image_src} alt={this.state.imageAlt} />
                <Box p="6">
                  <Box d="flex" alignItems="baseline">
                    <Badge rounded="full" px="2" variantColor="teal">
                      SVG
                    </Badge>
                  </Box>
  
                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                  >
                    {design.title}
                  </Box>
  
                  <Box as="span" color="gray.600" fontSize="sm">
                    {design.createdAt}
                  </Box>
                </Box>
            </Link>
            </div>
            </Box>
  
          </motion.div>
      }

      
      else if (design.designType == "svg_overlay") {

        element = 
            <Box
                maxW="sm"
                borderWidth="1px"
                rounded="lg"
                overflow="hidden"
                className="design"
                key = {design.id}

              >
  
            <div>
            <div align="end" justify="end" className="flex_menu">
  
              <Menu justifyContent="right">
                    <MenuButton as={Button} leftIcon="drag-handle">
                    </MenuButton>
                    <MenuList>
                      <MenuItem>Download</MenuItem>
                      <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem>
                      <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
                    </MenuList>
              </Menu>
  
            </div>
            
            <Link to={"/app?design=" + design.id}>
  
                <Image src={design.image_src} alt={this.state.imageAlt} />
                <Box p="6">
                  <Box d="flex" alignItems="baseline">
                    <Badge rounded="full" px="2" variantColor="teal">
                      SVG
                    </Badge>
                  </Box>
  
                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                  >
                    {design.title}
                  </Box>
  
                  <Box as="span" color="gray.600" fontSize="sm">
                    {design.createdAt}
                  </Box>
                </Box>
            </Link>
            </div>
            </Box>
  
          // </motion.div>
      }

      // else if (design.designType == "gradient") {
      //   element = 
      //       <Box
      //             maxW="sm"
      //             borderWidth="1px"
      //             rounded="lg"
      //             overflow="hidden"
      //             className="design"
      //             data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
      //             key = {design.id}

      //           >
    
      //         <Flex align="end" justify="end">
    
      //           <Menu justifyContent="right">
      //                 <MenuButton as={Button} leftIcon="drag-handle">
      //                 </MenuButton>
      //                 <MenuList>
      //                   <MenuItem>Download</MenuItem>
      //                   <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem>
      //                   <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
      //                 </MenuList>
      //           </Menu>
    
      //         </Flex>
            
      //         <div onClick={() => this.onDesignClicked(design)}>

      //           <div className="gradient_preview" style={{background: `linear-gradient(${design.body.deg}deg, ${design.body.color1} 0%, ${design.body.color2} 100%)`}}></div>

      //             <Box p="6">
      //               <Box d="flex" alignItems="baseline">
      //                 <Badge rounded="full" px="2" variantColor="purple">
      //                   Gradient
      //                 </Badge>
      //               </Box>
    
      //               <Box
      //                 mt="1"
      //                 fontWeight="semibold"
      //                 as="h4"
      //                 lineHeight="tight"
      //                 isTruncated
      //               >
      //                 design
      //               </Box>
    
      //               <Box as="span" color="gray.600" fontSize="sm">
      //                 {design.createdAt}
      //               </Box>
      //             </Box>

      //         </div>
      //         </Box>
      //       // </motion.div>
      // }

    
      // else if (design.designType == "triangles") {
      //   element = 
      //       <Box
      //             maxW="sm"
      //             borderWidth="1px"
      //             rounded="lg"
      //             overflow="hidden"
      //             className="design"
      //             data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
      //           >
    
      //         <Flex align="end" justify="end">
    
      //           <Menu justifyContent="right">
      //                 <MenuButton as={Button} leftIcon="drag-handle">
      //                 </MenuButton>
      //                 <MenuList>
      //                   <MenuItem>Download</MenuItem>
      //                   <MenuItem onClick={() => this.deleteDesign(design, i)}>Delete</MenuItem>
      //                   <MenuItem>Create a Copy</MenuItem>
      //                 </MenuList>
      //           </Menu>
    
      //         </Flex>
            
      //         <div onClick={() => this.onDesignClicked(design)}>
      //           <Link to={"/app?design=" + design.id}>

      //             <Box p="6">
      //               <Box d="flex" alignItems="baseline">
      //               <img src={this.state.img_url} key={Date.now()} className="gradient_preview"/>

      //                 <Badge rounded="full" px="2" variantColor="red">
      //                   Triangles
      //                 </Badge>
      //               </Box>

      //               <Box
      //                 mt="1"
      //                 fontWeight="semibold"
      //                 as="h4"
      //                 lineHeight="tight"
      //                 isTruncated
      //               >
      //                 design
      //               </Box>
    
      //               <Box as="span" color="gray.600" fontSize="sm">
      //                 {design.createdAt}
      //               </Box>
      //             </Box>
      //         </Link>
      //         </div>
      //         </Box>

      // }

      return element;
    });
    // let anim_designs = designs.filter(design => design.designType == "animated");
    // for (let j = 0; j < anim_designs.length; j++) {
    //     let container = anim_designs[j].container;
    //     if (container.current) {
    //       container.current.refresh();
    //     }
    // }
    // window.dispatchEvent(new Event('resize'));
    this.setState({ isLoading: false });

    return design_elems;
  }

  async createCopy(design) {
    let original_design_elems = this.state.design_elems;
    let body_str = JSON.stringify(design.body);
    let design_data = {name: design.name, id: uuidv4(), body: body_str, designType: design.designType };

    const designs_response = await API.graphql(graphqlOperation(createTodo, { input: design_data }))

    if (designs_response && designs_response.data) {

      const list_response = await API.graphql(graphqlOperation(listTodos));
      this.setState({designs: list_response.data.listTodos.items});
      this.initDesignElems();

      this.props.addToast('Created copy successfully', { appearance: 'success', placement: 'top-center', autoDismiss: true, autoDismissTimeout: 1500 });

    }
    else {
      // there was an error 
      this.props.addToast('Error: Could not create copy', { appearance: 'warning', placement: 'top-center', autoDismiss: true, autoDismissTimeout: 2500 });
    }
      
          
  }

  async initDesignsAfterDelete() {
    const list_response = await API.graphql(graphqlOperation(listTodos));
    this.setState({designs: list_response.data.listTodos.items});
    this.initDesignElems();

    this.props.addToast('Deleted successfully', { appearance: 'success', placement: 'top-center', autoDismiss: true, autoDismissTimeout: 1500 });

  }

  async deleteDesign(design, i) {
    let original_design_elems = this.state.design_elems;
    let design_data = {id: design.id};

    const designs_response = await API.graphql(graphqlOperation(deleteTodo, { input: design_data }));
    var app = this;
    if (designs_response && designs_response.data) {

      // delete storage images form S3 
      if (design.designType == "animated" || design.designType == "svgWaves" || design.designType == "triangles" || design.designType == "svgWavesAnimated") {
        Storage.remove(design.id + ".png", {level: "private"}).then((result) => {
          app.initDesignsAfterDelete();
        });
      }
      else {
        this.initDesignsAfterDelete();
      }


    }
    else {
      // there was an error 
      this.props.addToast('Error: Could not delete design', { appearance: 'warning', placement: 'top-center', autoDismiss: true, autoDismissTimeout: 2500 });
    }
      
          
  }

  handleError(error) {
    
    if (error.response) {
      
      if (error.response.data) {
        if (error.response.data.code == "auth/id-token-expired") {
          // generate a new token 
          var app = this;
          if (this.state.user) {
            this.state.user.getIdToken().then(function(idToken) { 
              app.state.user.idToken = idToken;
              app.props.setUserAction(app.state.user);
           });
          }

        }
      }
      
      if (error.response.status === 403) {
        // this.props.history.push("/login");
      }
    } else if (error.request) {
      // Request made, no response from server was received
    } else {
    }


  }

  getImages() {
    this.updateImgSrc();
  }

  updateImgSrc() {
    this.setState({img_url: "https://images.unsplash.com/photo-1547304206-e2e68bae3729?ixlib=rb-1.2.1&q=80&fm=png&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjEyMDkwNH0.png"})
  }

  refreshAnimatedContainers() {

  }
  
  render() {
    var skeleton_boxes = [];
    var triangle_elems = [];
    var svgwave_elems = [];
    var svgwaves_animated_elems = [];
    var animated_elems = [];
    var other_design_elems = [];

    for (var i = 0; i < 10; i++) {
      skeleton_boxes.push(<Box
        maxW="sm"
        rounded="lg"
        overflow="hidden"
        className="design"
      >

    <div>

    <Skeleton key={i} isLoaded={!this.state.isLoading} className="skeleton" />

    </div>
    </Box>);
    }

    let triangle_designs = this.state.designs.filter(design => design.designType == "triangles");
    triangle_designs.forEach((design, i) => {
      triangle_elems.push(<Box
                  maxW="sm"
                  borderWidth="1px"
                  rounded="lg"
                  overflow="hidden"
                  className="design"
                  data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
                >
    
              <div align="end" justify="end" className="flex_menu">
    
                <Menu justifyContent="right" className="align_end">
                      <MenuButton as={Button} leftIcon="drag-handle">
                      </MenuButton>
                      <MenuList>
                        <MenuItem>Download</MenuItem>
                        <MenuItem onClick={() => this.deleteDesign(design, i)}>Delete</MenuItem>
                        <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
                      </MenuList>
                </Menu>
    
              </div>
            
              <div onClick={() => this.onDesignClicked(design)}>
                <Link to={"/app?design=" + design.id}>

                  <Box p="6">
                  <img src={this.state.triangle_urls[i]} key={Date.now()} />
                    <Box d="flex" alignItems="baseline">
                    
                      <Badge rounded="full" px="2" variantColor="red">
                        Triangles
                      </Badge>
                    </Box>

                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      isTruncated
                    >
                      design
                    </Box>
    
                    <Box as="span" color="gray.600" fontSize="sm">
                      {design.createdAt}
                    </Box>
                  </Box>
              </Link>
              </div>
              </Box>);
    });

    let svgwave_animated_designs = this.state.designs.filter(design => design.designType == "svgWavesAnimated");
    svgwave_animated_designs.forEach((design, i) => {
      if (design.parsedBody == undefined) {
        let parsed_body = JSON.parse(design.body);
        design.parsedBody = parsed_body;
      }
      svgwaves_animated_elems.push(<Box
        maxW="sm"
        borderWidth="1px"
        rounded="lg"
        overflow="hidden"
        className="design"
        data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
      >

    <div align="end" justify="end" className="flex_menu">

      <Menu justifyContent="right">
            <MenuButton as={Button} leftIcon="drag-handle">
            </MenuButton>
            <MenuList>
              <MenuItem>Download</MenuItem>
              <MenuItem onClick={() => this.deleteDesign(design, i)}>Delete</MenuItem>
              <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
            </MenuList>
      </Menu>

    </div>
  
    <div onClick={() => this.onDesignClicked(design)}>
      <Link to={"/app?design=" + design.id}>

        <Box p="6">
          
          <img src={this.state.svgwaves_animated_urls[i]} key={Date.now()} style={{backgroundImage: `${design.parsedBody.background.image}`}}/>

          
          <Box d="flex" alignItems="baseline">
          
            <Badge rounded="full" px="2" variantColor="pink">
              SVG Waves (Animated)
            </Badge>
          </Box>

          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            design
          </Box>

          <Box as="span" color="gray.600" fontSize="sm">
            {design.createdAt}
          </Box>
        </Box>
    </Link>
    </div>
    </Box>);
    })

    let svgwave_designs = this.state.designs.filter(design => design.designType == "svgWaves");
    svgwave_designs.forEach((design, i) => {
      svgwave_elems.push(<Box
        maxW="sm"
        borderWidth="1px"
        rounded="lg"
        overflow="hidden"
        className="design"
        data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
      >

<div align="end" justify="end" className="flex_menu">

      <Menu justifyContent="right">
            <MenuButton as={Button} leftIcon="drag-handle">
            </MenuButton>
            <MenuList>
              <MenuItem>Download</MenuItem>
              <MenuItem onClick={() => this.deleteDesign(design, i)}>Delete</MenuItem>
              <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
            </MenuList>
      </Menu>

    </div>
  
    <div onClick={() => this.onDesignClicked(design)}>
      <Link to={"/app?design=" + design.id}>

        <Box p="6">
          
          <img src={this.state.svgwave_urls[i]} key={Date.now()}/>

          
          <Box d="flex" alignItems="baseline">
          
            <Badge rounded="full" px="2" variantColor="pink">
              SVG Waves
            </Badge>
          </Box>

          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            design
          </Box>

          <Box as="span" color="gray.600" fontSize="sm">
            {design.createdAt}
          </Box>
        </Box>
    </Link>
    </div>
    </Box>);
    })

    let animated_designs = this.state.designs.filter(design => design.designType == "animated");
    animated_designs.forEach((design, i) => {
      if (design.parsedBody == undefined) {
        let parsed_body = JSON.parse(design.body);
        design.parsedBody = parsed_body;
      }
      animated_elems.push(<Box
                  maxW="sm"
                  borderWidth="1px"
                  rounded="lg"
                  overflow="hidden"
                  className="design"
                  data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
                >
    
          <div align="end" justify="end" className="flex_menu">
    
                <Menu justifyContent="right">
                      <MenuButton as={Button} leftIcon="drag-handle">
                      </MenuButton>
                      <MenuList>
                        <MenuItem>Download</MenuItem>
                        <MenuItem onClick={() => this.deleteDesign(design, i)}>Delete</MenuItem>
                        <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
                      </MenuList>
                </Menu>
    
              </div>
            
              <div onClick={() => this.onDesignClicked(design)}>
                <Link to={"/app?design=" + design.id}>

                  <Box p="6"  >
                  <img src={this.state.animated_image_urls[i]} key={Date.now()}   style={{backgroundImage: `${design.parsedBody.background.image}`}}/>
                    <Box d="flex" alignItems="baseline" className="mt-4">
                    
                      <Badge rounded="full" px="2" variantColor="purple">
                        Animated
                      </Badge>
                    </Box>

                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      isTruncated
                    >
                      design
                    </Box>
    
                    <Box as="span" color="gray.600" fontSize="sm">
                      {design.createdAt}
                    </Box>
                  </Box>
              </Link>
              </div>
              </Box>);
    })

    let other_designs = this.state.designs.filter(design => (design.designType != "animated" && design.designType != "triangles"));
    other_designs.forEach((design, i) => {
      if (design.designType == "gradient") {

        other_design_elems.push(   <Box
          maxW="sm"
          borderWidth="1px"
          rounded="lg"
          overflow="hidden"
          className="design"
          data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
          key = {design.id}

        >

        <div align="end" justify="end" className="flex_menu">

        <Menu justifyContent="right">
              <MenuButton as={Button} leftIcon="drag-handle">
              </MenuButton>
              <MenuList>
                <MenuItem>Download</MenuItem>
                <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem>
                <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
              </MenuList>
        </Menu>

      </div>
    
      <div onClick={() => this.onDesignClicked(design)}>

        <div className="gradient_preview" style={{background: `linear-gradient(${design.body.deg}deg, ${design.body.color1} 0%, ${design.body.color2} 100%)`}}></div>

          <Box p="6">
            <Box d="flex" alignItems="baseline">
              <Badge rounded="full" px="2" variantColor="purple">
                Gradient
              </Badge>
            </Box>

            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              design
            </Box>

            <Box as="span" color="gray.600" fontSize="sm">
              {design.createdAt}
            </Box>
          </Box>

      </div>
      </Box>);
      }
      else if (design.designType == "radialGradient") {

        other_design_elems.push(   <Box
          maxW="sm"
          borderWidth="1px"
          rounded="lg"
          overflow="hidden"
          className="design"
          data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
          key = {design.id}

        >

      <div align="end" justify="end" className="flex_menu">

        <Menu justifyContent="right">
              <MenuButton as={Button} leftIcon="drag-handle">
              </MenuButton>
              <MenuList>
                <MenuItem>Download</MenuItem>
                <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem>
                <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
              </MenuList>
        </Menu>

      </div>
    
      <div onClick={() => this.onDesignClicked(design)}>

        <div className="gradient_preview" style={{background: `linear-gradient(45deg, ${design.body.color1} 0%, hsla(352, 96%, 45%, 0) 70%),
        linear-gradient(135deg, ${design.body.color2} 10%, hsla(253, 95%, 42%, 0) 80%),
        linear-gradient(225deg, ${design.body.color3} 10%, hsla(179, 91%, 45%, 0) 80%),
        linear-gradient(315deg, ${design.body.color4} 100%, hsla(119, 96%, 49%, 0) 70%)`}}></div>

          <Box p="6">
            <Box d="flex" alignItems="baseline">
              <Badge rounded="full" px="2" variantColor="purple">
                Radial Gradient
              </Badge>
            </Box>

            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              design
            </Box>

            <Box as="span" color="gray.600" fontSize="sm">
              {design.createdAt}
            </Box>
          </Box>

      </div>
      </Box>);
      }
      else if (design.designType == "animated_gradient") {
        other_design_elems.push(<Box
        maxW="sm"
        borderWidth="1px"
        rounded="lg"
        overflow="hidden"
        className="design"
        data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
        key = {design.id}
      >

      <div align="end" justify="end" className="flex_menu">

          <Menu justifyContent="right">
                <MenuButton as={Button} leftIcon="drag-handle">
                </MenuButton>
                <MenuList>
                  <MenuItem>Download</MenuItem>
                  <MenuItem onClick={() => this.deleteDesign(design, i)}>Delete</MenuItem>
                  <MenuItem onClick={() => this.createCopy(design)}>Create a Copy</MenuItem>
                </MenuList>
          </Menu>

        </div>
      
        <div onClick={() => this.onDesignClicked(design)}>

          <div className="gradient_preview animated_gradient" style={{background: `linear-gradient(${design.body.deg}deg, ${design.body.color1} 0%, ${design.body.color2} 100%)`}}></div>

            <Box p="6">
              <Box d="flex" alignItems="baseline">
                <Badge rounded="full" px="2" variantColor="blue">
                  Animated Gradient
                </Badge>
              </Box>

              <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
              >
                design
              </Box>

              <Box as="span" color="gray.600" fontSize="sm">
                {design.createdAt}
              </Box>
            </Box>

        </div>
    </Box>)
      }
    })


    return (
      <div>
        <div className="designs_container">

        <div className={this.state.emptyState == true ? "hidden" : ""}>
          <h1>Your Designs</h1>
            <h2>Welcome back!</h2>


              <Button variant="solid" variantColor="purple" onClick={() => this.createNewDesign()}>
                Create New Design
              </Button>



          <div className={this.state.emptyState ? "" : "visible"} id="designs">
            {triangle_elems}
            {animated_elems} 
            {other_design_elems}
            {svgwave_elems}
            {svgwaves_animated_elems}
            {this.state.design_elems}
          </div>

          <div className="designs" id={this.state.isLoading ? "" : "nodisplay"}>
            {skeleton_boxes}

          </div>
        </div>

          <div className="designsEmptyState">
            <section className={this.state.emptyState === true ? "" : "hidden"}>
              <h1>No Designs</h1>
              <p className="mb-4">Ready to Get Started on a New Design?</p>

              <Link to="/get-started"> 
                <Button variant="solid" variantColor="purple">
                  Create New Design
                </Button>
              </Link> 

              <Link to="/dashboard/templates">
                <Button variant="solid" variantColor="purple">
                  View Templates
                </Button>
              </Link>
            </section>
          </div>

        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToast(withRouter(Designs)));