import  React , {useState} from 'react';
import styles from './NavigationBar.css';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {Button} from "@chakra-ui/core";

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }

  render() {
    return(
      <nav className="home_nav">
        <Link to="/" className="logo">
          <h3>SVG Background Maker</h3>
        </Link>
  
        <ul>
          
          <section class="btns">
            <Link to="/signup">
              <Button variantColor="pink" variant="solid">
                Sign Up
              </Button>
            </Link>
            
            <Link to="/login/">
              <Button variantColor="pink" variant="outline">
                Login
              </Button>
            </Link>
          </section>


          <li>Pricing</li>
          <li>Contact</li>
        </ul>
    </nav>
    )
  }
}

export default NavigationBar