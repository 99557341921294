import  React , {useState} from 'react';
import Sidebar from 'components/Sidebar/Sidebar';
import Canvas from 'components/Canvas/Canvas';
import EditorHeaderNav from 'components/EditorHeaderNav/EditorHeaderNav';
import { withRouter } from 'react-router-dom'

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        gradients: []
    };
  }

  componentDidMount() {

  }

  render() {

    return(
        <div>

            <EditorHeaderNav></EditorHeaderNav>
            <div class="left_margin">
              <Canvas></Canvas>
            </div>
            <Sidebar></Sidebar>
    
        </div>
    )
  }
}

export default withRouter(Editor);