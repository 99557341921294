import  React , {useState, useContext} from 'react';
import { connect } from "react-redux";
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import { setLoggedOutAction } from "actions/setLoggedOutAction";
import { setUserAction } from "actions/setUserAction";
import Dashboard from "components/Dashboard/Dashboard";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import Landing from "pages/Landing";
import { useToasts } from 'react-toast-notifications'
import {Auth} from "aws-amplify";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import queryString from "query-string";

export function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  startAction: () => dispatch(startAction),
  stopAction: () => dispatch(stopAction),  
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setLoggedOutAction: (payload) => dispatch(setLoggedOutAction(payload)),
  setUserAction: (payload) => dispatch(setUserAction(payload)),

});

class AuthScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gradient: null,
      draw: null,
      gradient_color: "#897",
      selectedElement: null,
      selectedElements: [],
      colors: ["#f06", "#f03", "#f09"],
      loggedIn: false,
      isAuth: false,
        authType: false,
    };
  }
  componentDidMount() {
    var app = this;
    if (this.props.location && this.props.location.search){
        const qs = queryString.parse(this.props.location.search);
        const authType = qs.authType;
        this.setState({authType: authType})
    }
    else {
        this.setState({authType: "login"})
    }

  }

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {
        // this.props.history.push("/login")
    });
  }

  render() {

    let renderedComponents;
    // if (this.state.loggedIn) {
    //   renderedComponents = <Dashboard></Dashboard>
    // }
    // else {
      if (this.state.authType == "signup") {
        renderedComponents = <SignUp></SignUp>
      }
      else if (this.state.authType == "login") {
        renderedComponents = <SignIn></SignIn>

      }
      else {
          renderedComponents = <SignIn></SignIn>
      }
  //       renderedComponents = <div class="home">
  //       <NavigationBar></NavigationBar>

  //       <div id="container">
  //         <div id="svg_home_animation">

  //         </div>            
  //         <div id="text">

  //           <section class="landing_hero_left">
  //             <h1>Create SVG backgrounds in seconds</h1>
  //               <p>kroma makes it easy to create backgrounds and SVG elements. 
  //                 Try it now for free!
  //               </p>          

  //               <section class="btns">
  //                   <Link to="/app/">
  //                   <Button variantColor="pink" variant="solid">
  //                     Get Started
  //                   </Button>
  //                   </Link>

  //                   <Button variantColor="pink" variant="solid">
  //                     Learn More 👀 
  //                   </Button>
                    
  //               </section>  
  //           </section>

  //         </div>
  //       </div>

  //       <section>
  //         <h1 className="centre">Make SVG backgrounds like these 👀 </h1>
          
  //         <div class="gradients">

  //         </div>
  //       </section>

  //         <section class="hero">
  //           <section class="hero_lhs">
  //             <h1>Create SVG Blobs, Waves, and More</h1>
  //             <p>Create and export a huge selection of elements from waves to blobs. Add gradients and patterns to your elements, 
  //               and export them individually as SVGs or include them in your backgrounds.
  //             </p>
  //           </section>
  //           <section class="hero_rhs">
  //           <p></p>
  //           </section>
  //         </section>

  //         <section class="hero">
  //           <section class="hero_lhs">
  //             <h1>Export Individual SVG Elements</h1>
  //             <p>Create randomized blobs using the built-in blob generator.</p>
  //           </section>

  //           <section class="hero_rhs">
  //             <p>rhs</p>

  //           </section>
  //         </section>

          
  //         <section class="hero">
  //           <section class="hero_lhs">
  //             <h1>Over 50 Templates</h1>
  //             <p>If you need inspiration, you can also choose from a selection of templates.</p>
  //           </section>

  //           <section class="hero_rhs">
  //             <p>rhs</p>
  //           </section>

  //         </section>

  //   <section class="pricing">
  //     <h1>Pricing</h1>
  //     <section>

  //     </section>
  //   </section>


  // </div>

    //}
    return(
      <div>
      {renderedComponents}

      </div>
    )
  }
}

export default withToast(connect(mapStateToProps, mapDispatchToProps)(AuthScreen));