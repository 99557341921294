import React, {useEffect, useState, useCallback} from 'react';
import './App.css';
import './css/style.scss';
import { motion } from 'framer-motion'
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { BrowserRouter, Route, Switch, Link, useLocation, Redirect } from 'react-router-dom';
import Signup from 'pages/SignUp';
import Signin from "pages/SignIn";
import AuthScreen from "pages/AuthScreen";
import ViewTemplates from 'components/ViewTemplates/ViewTemplates';
import Dashboard from 'components/Dashboard/Dashboard';
import GradientTemplates from 'components/GradientTemplates/GradientTemplates';
import Home from 'components/Home/Home';
import { ThemeProvider, CSSReset, useColorMode, DarkMode, LightMode } from '@chakra-ui/core'
import customTheme from "./theme"
import AOS from 'aos';
import { ToastProvider, useToasts } from 'react-toast-notifications'
import ResetPassword from "pages/ResetPassword"
import SignIn2 from "pages/SignIn2"
import ResetPasswordConfirm from "pages/ResetPasswordConfirm"
import SignIn from "pages/SignIn"
import GetStarted from "components/GetStarted/GetStarted"
import Editor from "components/Editor/Editor"
import FullscreenBackground from "components/FullscreenBackground/FullscreenBackground"
import SignUp from "pages/SignUp"
import Tutorials from "pages/Tutorials"
import Sticky from 'sticky-js';
import 'aos/dist/aos.css';
import {setUserAction} from "actions/setUserAction";
import './css/style.scss';
import { connect } from "react-redux";
import AnimatedBackgrounds from 'pages/AnimatedBackgrounds';
import TriangleBackgrounds from 'pages/TriangleBackgrounds';
import GradientBackgrounds from 'pages/GradientBackgrounds';
import GradientCollection from 'pages/GradientCollection';
import DemoPreview from "components/DemoPreview/DemoPreview"
import GradientDemoPreview from "components/GradientDemoPreview/GradientDemoPreview"
import AmplifyLogin from "pages/AmplifyLogin"
import ConfirmEmail from "pages/ConfirmEmail"
import Amplify, { Auth, API } from 'aws-amplify';
import {PageView, initGA} from './components/Tracking';

// import '@stripe/stripe-js'
import awsConfig from './aws-exports';

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
var [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

var [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

productionRedirectSignIn = productionRedirectSignIn.trim();
productionRedirectSignOut = productionRedirectSignOut.trim();
console.log(`production redirect${productionRedirectSignIn}`);

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure({
  ...updatedAwsConfig,
  Analytics: {
    disabled: true,
  },
});

API.configure(updatedAwsConfig);

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setUserAction: (payload) => dispatch(setUserAction(payload)),
});

function App() {
  const location = useLocation();

  useEffect(() => {
    PageView();

    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
  
  });

  useEffect(() => {
    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG);

  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // triggered on route change
  
  return (
    <div className="App">

      <AnimateSharedLayout type='crossfade'>
      <LightMode>

      <ToastProvider autoDismiss={true} autoDismissTimeout={3000}>

        <ThemeProvider theme={customTheme}>
            <CSSReset />
            <BrowserRouter>
              <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/login" component={SignIn} /> 
                  <Route path="/signup" component={SignUp} /> 
                  <Route path="/dashboard" component={Dashboard} /> 
                  <Route path="/get-started" component={GetStarted} /> 

                  {/* <Route path="/upload" component={ImageUploader} /> */}

                  {/* new design */}
                  <Route path="/app" component={Editor} exact />

                  {/* update design */}
                  <Route path="/app/:id?" component={Editor} />  

                  {/* <Route path="/pricing" component={Pricing} /> */}
                  {/* <Route path="/about" component={About} /> */}
                  {/* <Route path="/fullscreen/:id?" component={Canvas} /> */}
                  {/* <Route path="/home" component={Landing} /> */}
                  <Route path="/gradient-templates" component={GradientTemplates} />

                  <Route path="/reset-password" component={ResetPassword} /> 
                  <Route path="/auth" component={AuthScreen} /> 

                  <Route path="/reset-password-confirm" component={ResetPasswordConfirm} /> 

                  {/* <Route path="/blog" component={Blog} />
                  <Route path="/blog-post" component={BlogPost} /> */}
                  {/* <Route path="/bug-reports" component={BugReports} /> */}

                  {/* <Route path="/support" component={Support} /> */}
                  {/* <Route path="/documentation" component={Documentation} /> */}
                  {/* <Route path="/live-chat" component={LiveChat} /> */}
                  <Route path="/animated-backgrounds" component={AnimatedBackgrounds} />
                  {/* <Route path="/feedback" component={FeedbackForm} /> */}
                  <Route path="/gradient-backgrounds" component={GradientBackgrounds} />
                  <Route path="/triangle-backgrounds" component={TriangleBackgrounds} />
                  <Route path="/verify-email" component={ConfirmEmail} />

                  <Route path="/tutorials" component={Tutorials} />
                  <Route path="/fullscreen-background/:id?" component={FullscreenBackground} /> 

                  {/* <Route path="/join-waitlist" component={JoinWaitlist} /> */}

                  <Route path="/gradient-collection" component={GradientCollection} />
                  <Route path="/demo-preview" component={DemoPreview} />
                  <Route path="/gradient-demo-preview" component={GradientDemoPreview} />

                  {/* <Route
                    path={['/gallery']}
                    component={Gallery1}
                    render={(props) => <Gallery1 images={images} {...props} />}
                    exact
                  />
                  <Route path='/lbox/:id' component={GalleryAndLightbox} /> */}

                </Switch>
            </BrowserRouter>

          </ThemeProvider>


      </ToastProvider>
</LightMode>
      </AnimateSharedLayout>
    </div>

  );
}


// const images = [
//   { title: 'Photon1', src: 'https://source.unsplash.com/WLUHO9A_xik/600x400' },
//   {
//     title: 'Inversion1',
//     src: 'https://source.unsplash.com/qwRfzgi7b8g/600x400'
//   },
//   { title: 'Legion1', src: 'https://source.unsplash.com/phIFdC6lA4E/600x400' },
//   { title: 'Lokti1', src: 'https://source.unsplash.com/600x400/?pastel' },
//   {
//     title: 'Twenty Neue1',
//     src: 'https://source.unsplash.com/600x400/?cyberpunk+city'
//   },
//   {
//     title: 'Geometrik1',
//     src: 'https://source.unsplash.com/600x400/?noir+city'
//   },
//   {
//     title: 'Achtung1',
//     src: 'https://source.unsplash.com/600x400/?pastel+clouds'
//   }
// ]

// export function Gallery1({ match }) {
//   let { id } = match.params
//   const imageLoaded = true

//   return (
//     <div>
//       <LightboxList selectedId={id} images={images} />
//       <AnimatePresence>
//         {id && imageLoaded && <Lightbox id={id} key='item' />}
//       </AnimatePresence>
//     </div>
//   )
// }

// export function GalleryAndLightbox({ match }) {
//   let { id } = match.params
//   const imageLoaded = true

//   return (
//     <div>
//       <AnimatePresence>
//         {id && imageLoaded && <Lightbox id={id} key='item' />}
//       </AnimatePresence>
//     </div>
//   )
// }

// function ImageCard({ title, src }) {
//   return (
//     <li>
//       <div>
//         <motion.div layoutId={`lcard-container-${title}`}>
//           <motion.div layoutId={`lcard-image-container-${title}`}>
//             <img src={src} />
//           </motion.div>
//           {/* <motion.div layoutId={`ltitle-container-${title}`}>
//             <h2>{title}</h2>
//           </motion.div> */}
//         </motion.div>
//       </div>

//     </li>
//   )
// }

// export function LightboxList({ selectedId, images }) {
//   return (
//     <ul className="imageGrid">
//       {images.map((image) => (
//         <Link to={'lbox/' + image.title} className="imageGridItem">
//           <ImageCard
//             key={image.title}
//             {...image}
//             isSelected={image.title === selectedId}
//             className="imageCard"
//           />
//         </Link>
//       ))}
//     </ul>
//   )
// }

// export function Lightbox({ id }) {
//   const image = images.find((item) => item.title === id)

//   return (

//     <div className="lightbox">
//           <motion.div layoutId={`lcard-container-${id}`}>
//             <motion.div layoutId={`lcard-image-container-${id}`}
//             initial="hidden"
//             variants={{
//               visible: { transition: {duration: 0.4, delay: 0.25} },
  
//             }}
//             whileHover={{scale: 1}}
//             transition={{ duration: 0.2, delay: 0.15 }}
//             className="fullHeight"
//             >
//                 <Link to='/gallery'>Go back</Link>
//                 <img src={image.src} alt='' />
//             </motion.div>
//           </motion.div>
//         </div>

//   // </motion.div>
//   )
// }

export default connect(mapStateToProps, mapDispatchToProps)(App);