import React from 'react';

import Header from '../partials/Header';
import Stats from '../partials/Stats';
import Process from '../partials/Process';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import { Link } from 'react-router-dom';
import { setBgAction } from "actions/setBgAction";
import { setElementAction } from "actions/setElementAction";
import { connect } from "react-redux";
import TeamImages from 'partials/TeamImages';
import gradientData from "../components/Gradients/gradient_collection_resource.json";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setElementAction: (payload) => dispatch(setElementAction(payload)),
});

class GradientBackgrounds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  createGradientElems() {
    let gradients = this.state.gradients.slice(2, 8);

    var gradient_elems = gradients.map((gradient, i) => {
      return (
          <article className="flex flex-col h-full" data-aos="zoom-y-out">
          <header>
            <Link to="/app?designType=svgOverlay" className="block mb-6">
              <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">

                <div
                className="gradient absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                // onClick={() => this.handleClick(gradient)}
                style={{
                width: "352",
                height: "198",
                backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.color1}, ${gradient.color2})`,
                }}
                />
              </figure>
            </Link>
            <div className="mb-3">

            </div>
            <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
              <Link to="/app?designType=svgOverlay" className="hover:underline"></Link>
            </h3>
          </header>

        </article>




      );
    });
    return gradient_elems;
  }


  render() {
    this.state.gradients = gradientData.gradients;

    let gradient_elems = this.createGradientElems();

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

        {/*  Site header */}
        <Header />
  
        {/*  Page content */}
        <main className="flex-grow">
  
          {/*  Page sections */}
          <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-32 pb-6 md:pt-40 md:pb-14">

                {/* Section header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="zoom-y-out" >Gradient Backgrounds</h1>
                  <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                    Choose from our range of over 100 carefully chosen gradient blends and 
                    create backgrounds from them for your next project.
                  </p>
                </div>

              </div>
            </div>
          </section>

          
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pb-8 md:pb-16">

            {/* Gradients list */}
            <div className="max-w-sm mx-auto md:max-w-none">
            <h3 className="h3 mb-4 text-center" data-aos="zoom-y-out" data-aos-delay="200">Sample Gradients</h3>

              {/* Gradients container */}
              <div className="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">


              {gradient_elems}
              </div>

              <div>... and in-app you'll gain access to many more!</div>

            </div>

          </div>
        </div>


          <Cta />
  
        </main>
  
        {/*  Site footer */}
        <Footer />
  
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}
}

export default connect(mapStateToProps, mapDispatchToProps)(GradientBackgrounds);
