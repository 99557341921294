import React, { useState } from "react";
import styles from "./Account.css";
import { connect } from "react-redux";
import SVG from "svg.js";
import { motion } from "framer-motion";
import { Input, Stack, Button, useColorMode, LightMode, DarkMode } from "@chakra-ui/core";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  withRouter,
} from "react-router-dom";
import axios from "axios";
import { Auth, API }from "aws-amplify";
import { graphqlOperation } from 'aws-amplify';
import { listTodos } from 'graphql/queries';
import CheckoutButton from "components/CheckoutButton";

function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <div>
      <Button size="sm" colorScheme="blue" onClick={toggleColorMode}>
        Toggle Mode
      </Button>
    </div>

  )
}
class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      email: "null",
      designsRemaining: null,
      TOTAL_DESIGNS_FREE_PLAN: 30,
    };
  }

  componentDidMount() {
    this.getUser().then(userData => {

        if (userData) {
          // the array of groups that the user belongs to
          let groups = userData.signInUserSession.accessToken.payload["cognito:groups"];
          this.setState({userGroups: groups, email: userData.attributes.email  })
        }

        this.setState({userData: userData})
        this.initDesignsRemaining();
    });

    const script = document.createElement('script');
  
    script.src = "https://checkoutlib.billsby.com/checkout.min.js";
    script.setAttribute(
      "data-billsby-company",
      "inixia"
    );
    script.async = true;
    script.setAttribute(
      "async",
      "true"
    );
    document.body.appendChild(script);

  }

  async initDesignsRemaining() {
    const list_response = await API.graphql(graphqlOperation(listTodos));
    if (list_response && list_response.data) {
      let num_designs_remaining = this.state.TOTAL_DESIGNS_FREE_PLAN - list_response.data.listTodos.items.length;
      this.setState({designsRemaining: num_designs_remaining});
    }
    else {
      this.setState({designsRemaining: this.state.TOTAL_DESIGNS_FREE_PLAN})
    }

  }

  async subscribePremium() {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
        'custom:pro_plan': "true",
    });

  }

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {console.log('Not signed in')});
  }

  handleChange = (event) => {

  };


  

  render() {

    return (
      <div>

          <h1>Your Account</h1>
          {/* <button className="btn text-white bg-blue-600 hover:bg-blue-700 button" 
           onClick={event => {this.subscribePremium(event)}} data-billsby-type="checkout">Go Premium</button>
          <CheckoutButton /> */}
          <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
            <div class="px-4 py-5 sm:px-6">

              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Account Details
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Your account details and plan type.
              </p>


            </div>
            <div class="border-t border-gray-200">
              <dl>
              <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Email address
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.email}
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Plan
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    Free
                  </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    Free Designs Remaining
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.designsRemaining}
                  </dd>
                </div>

                {/* <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-500">
                    About
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                  </dd>
                </div> */}

              </dl>
            </div>
          </div>
      </div>
    );
  }
}



export default withRouter(Account);
