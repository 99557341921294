import  React , {useState} from 'react';
import styles from './Templates.css';
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import {setAnimatedBackgroundAction} from "actions/setAnimatedBackgroundAction";
import { connect } from "react-redux";
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import {setSVGBackgroundTemplateAction} from "actions/setSVGBackgroundTemplateAction";
import {setTemplatesAction} from "actions/setTemplatesAction";
import axios from "axios";
import { motion } from "framer-motion"
import {API, Storage} from "aws-amplify";
import { graphqlOperation } from 'aws-amplify';
import { listTemplates } from 'graphql/queries';
import {Button, Menu, MenuButton, Box, Flex, Badge, MenuList, MenuItem, Skeleton, Image, Heading,
  Link as ChakraLink,

} from "@chakra-ui/core";

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  startAction: () => dispatch(startAction),
  stopAction: () => dispatch(stopAction),
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setAnimatedBackgroundAction: (payload) => dispatch(setAnimatedBackgroundAction(payload)),
  setSVGBackgroundTemplateAction: (payload) => dispatch(setSVGBackgroundTemplateAction(payload)),
  setTemplatesAction: (payload) => dispatch(setTemplatesAction(payload)),
});

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        svgHeight: 1100,
        svgWidth: 1200,
        animated_image_urls: [],
        width: 60,
        rect: null,
        gradient: null,
        templates: [],
        draw: null,
        gradient_color: "#897",
        template_elems: [],
        emptyState: false,
        animated_background_template_elems: [],
        triangle_template_elems: [],
        header_template_elems: [], 
        animated_wave_template_elems: [],
        animated_gradient_template_elems: [],
        gradient_template_elems: [],
        templates: [],
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.scrollToTop();
    if (this.props.templates) {
      this.createTemplateElems();
    }
    else {
      this.fetchTemplates();
    }
  }

  scrollToTop = () => window.scrollTo(0, this.ref.current.offsetTop)   

  handleClick(gradient) {
    this.applyGradient(gradient);
  }

  async fetchTemplates() {

    const designs_response = await API.graphql({
      query: listTemplates,
      authMode: 'API_KEY'
    });
    let templates = designs_response.data.listTemplates.items;
    if (designs_response && designs_response.data) {
      this.setState({isLoading: false, templates: templates});
      this.props.setTemplatesAction(templates);
      
      for (var i = 0; i < templates.length; i++) {
        let design = templates[i];
        if (design.designType == "animated") {

          // let body = JSON.parse(design.body);
          //body.interactivity.events.onClick.enable = "false";
          //body.interactivity.events.onHover.enable = "false";
          // design.body = body;
          // if (design.body.backgroundMode) {
          //   design.body.backgroundMode.enable = false;
          // }
          // design.parsedBody = body;
  
          let img_name = "template_" + design.id + ".png";
          var app = this;

          let animated_image_urls = this.state.animated_image_urls;
            let url = await Storage.get(img_name, {level: "public"}).then(url =>
              {
                animated_image_urls.push(url);
                design.url = url;
    
                app.setState({animated_image_urls: animated_image_urls});
      
              })
          

      }
    }

      this.createTemplateElems();
    }


    // axios
    //   .get(
    //     "https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/templates"
    //   )
    //   .then((response) => {
    //     let templates = response.data;
    //     if (templates.length == 0) {
    //       this.setState({ emptyState: true });
    //     } else {          
          
    //       for (var i = 0; i < templates.length; i++) {
    //         var template = templates[i];
    //         let image_src = template.image;
    //         let body = JSON.parse(template.body);
    //         let background_image;
    //         if (body.background) {
    //           if (body.background.image == "") {
    //             background_image = body.background.color.value;
    //           }
    //           else {
    //             background_image = body.background.image;
    //           }
    //           template.background_image = background_image;
    //         }


    //       }

    //     this.props.setTemplatesAction(templates);
    //     this.createTemplateElems();
    // }
    // })
    //   .catch((error) => {
    //     this.handleError(error);
    //   });
  }

  createTemplateElems() {

    let template_elems = this.props.templates.map((template, i) => {
      let template_elem;

      if (template.designType == "animated") {
        template_elem = 
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="template_parent"
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <Box
              onClick={() => this.updateAnimatedBackground(template)}
              maxW="sm"
              borderWidth="1px"
              rounded="lg"
              overflow="hidden"
              className="template"
            >
                {/* <h2>{template.designType}</h2> */}
                <img src={template.url} />

          </Box>
        </motion.div>

        this.state.template_elems.push(template_elem);
      }

      // else if (template.designType == "svg") {

      // }

      else if (template.designType == "gradient") {
        let template_body = JSON.parse(template.body);
        template.parsedBody = template_body;
        template_elem = 
        <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="template_parent"
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
         }}
      >
         <Box
            onClick={() => this.updateAnimatedBackground(template)}
            maxW="sm"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            className="template"
          >


             <div className="gradient_thumbnail" style={{background: `linear-gradient(${template.parsedBody.deg}deg, ${template.parsedBody.color1} 0%, ${template.parsedBody.color2} 100%)`}}></div>


         </Box>
       </motion.div>
          this.state.template_elems.push(template_elem);

      }

      else if (template.designType == "radialGradient") {
        let template_body = JSON.parse(template.body);
        template.parsedBody = template_body;
        template_elem = 
        <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="template_parent"
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
         }}
      >
         <Box
            onClick={() => this.updateAnimatedBackground(template)}
            maxW="sm"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            className="template"
          >

      <div className="gradient_thumbnail" style={{background: `linear-gradient(45deg, ${template.parsedBody.color1} 0%, hsla(352, 96%, 45%, 0) 70%),
        linear-gradient(135deg, ${template.parsedBody.color2} 10%, hsla(253, 95%, 42%, 0) 80%),
        linear-gradient(225deg, ${template.parsedBody.color3} 10%, hsla(179, 91%, 45%, 0) 80%),
        linear-gradient(315deg, ${template.parsedBody.color4} 100%, hsla(119, 96%, 49%, 0) 70%)`}}></div>


         </Box>
       </motion.div>
          this.state.template_elems.push(template_elem);

      }

      
      else if (template.designType == "animated_gradient") {
        let template_body = JSON.parse(template.body);
        template.parsedBody = template_body;
        template_elem = 
        <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="template_parent"
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
         <Box
             onClick={() => this.updateAnimatedBackground(template)}
             maxW="sm"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            className="template"
          >

            <div className="gradient_thumbnail" style={{background: `linear-gradient(${template.parsedBody.degree}deg, ${template.parsedBody.color1} 0%, ${template.parsedBody.color2} 100%)`}}></div>


        </Box>
      </motion.div>
        this.state.template_elems.push(template_elem);
      }

      return template_elem;
    });
    this.setState({"template_elems": template_elems})

  }

  handleError(error) {
    
    if (error.response) {
      
      if (error.response.status === 403) {
        this.props.history.push("/login");
      }

    } else if (error.request) {
      // Request made, no response from server was received
    } else {
    }


  }
  
  applyGradient(gradient) {

    this.props.setBgAction(gradient);
  }

  updateAnimatedBackground(template) {
    this.props.setAnimatedBackgroundAction(template);
  }

  updateSVGBackgroundTemplate(num) {
    this.props.setSVGBackgroundTemplateAction(num);
  }

  render() {
    let animated_elems = [];
    let animated_designs = this.state.templates.filter(design => design.designType == "animated");
    animated_designs.forEach((design, i) => {

      animated_elems.push(
              <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="template_parent"
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <div
                  onClick={() => this.updateAnimatedBackground(design)}
                  maxW="sm"
                  borderWidth="1px"
                  rounded="lg"
                  overflow="hidden"
                  className="template"
                >
                    <img src={this.state.animated_image_urls[i]} key={Date.now()} />
    
              </div>
            </motion.div>);
    })
    return(
        <div>
         
          <h1 ref={this.ref}>Templates</h1>

          <div className="templates">
            {this.state.template_elems}
            {/* {animated_elems} */}
            <Box>
                   <ChakraLink color="teal.500" href="https://www.isotopeui.com/?ref=wbmapp">
                     <Heading fontSize="lg" marginTop="0.1em" marginBottom={"0.5em"}>Get 100+ Premium Backgrounds Here</Heading>                 
                   </ChakraLink> 

                  </Box>
          </div>

          {/* <h2>Animated Backgrounds</h2>
          <div className="templates">
            {this.state.animated_background_template_elems}
          </div> 
          <h2>Triangle Backgrounds</h2>
          <div className="templates">
            {this.state.triangle_template_elems}
          </div> 
          <h2>Animated Wave Backgrounds</h2>
          <div className="templates">
            {this.state.animated_wave_template_elems}
          </div> 
          <h2>Non-Rectangular Headers</h2>
          <div className="templates">
            {this.state.header_template_elems}
          </div> 
          <h2>Gradient Backgrounds</h2>
          <div className="templates">
            {this.state.gradient_template_elems}
          </div> 
          <h2>Animated Gradient Backgrounds</h2>
          <div className="templates">
            {this.state.animated_gradient_template_elems}
          </div>         */}

    </div>
    )
  }

  // render() {

  //   return(<div>
        
  //         <h1 ref={this.ref}>Templates</h1>
          
  //         <section>

  //           <h2>Animated Backgrounds</h2>
  //           <p onClick={() => this.updateAnimatedBackground(0)}>Constellations</p>
  //           <p onClick={() => this.updateAnimatedBackground(1)}>Animated bubbles</p>
  //           <p onClick={() => this.updateAnimatedBackground(2)}>Animated bubbles</p>
  //           <p onClick={() => this.updateAnimatedBackground(3)}>Animated bubbles</p>
  //           <p onClick={() => this.updateAnimatedBackground(4)}>Animated bubbles</p>
  //           <p onClick={() => this.updateAnimatedBackground(5)}>Animated bubbles</p>

  //           <h2>Randomly Generate Designs</h2>
  //           <h1 onClick={() => this.updateSVGBackgroundTemplate(0)}>Bokeh Gradients</h1>
  //           <h1 onClick={() => this.updateSVGBackgroundTemplate(1)}>Bokeh Solid</h1>
  //           <p>Watercolors</p>
  //           <p>Green on Black constellations</p>

            

  //         </section>
  //     </div>
      
  //   )
  // }

  
  uploadTemplates() {
    let templates = [
      {
        "background": {
          "color": {
            "value": "#000000"
          },
          "image": "",
          "position": "50% 50%",
          "repeat": "no-repeat",
          "size": "cover",
          "opacity": 1
        },
        "backgroundMask": {
          "cover": {
            "color": {
              "value": "#fff"
            },
            "opacity": 1
          },
          "enable": false
        },
        "detectRetina": true,
        "fpsLimit": 60,
        "infection": {
          "cure": false,
          "delay": 0,
          "enable": false,
          "infections": 0,
          "stages": []
        },
        "interactivity": {
          "detectsOn": "canvas",
          "events": {
            "onClick": {
              "enable": true,
              "mode": "push"
            },
            "onDiv": {
              "ids": [],
              "enable": false,
              "mode": [],
              "type": "circle"
            },
            "onHover": {
              "enable": true,
              "mode": "repulse",
              "parallax": {
                "enable": false,
                "force": 2,
                "smooth": 10
              }
            },
            "resize": true
          },
          "modes": {
            "attract": {
              "distance": 200,
              "duration": 0.4,
              "speed": 1
            },
            "bubble": {
              "distance": 400,
              "duration": 2,
              "opacity": 0.8,
              "size": 40
            },
            "connect": {
              "distance": 80,
              "links": {
                "opacity": 0.5
              },
              "radius": 60
            },
            "grab": {
              "distance": 400,
              "links": {
                "opacity": 1
              }
            },
            "push": {
              "quantity": 4
            },
            "remove": {
              "quantity": 2
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4,
              "speed": 1
            },
            "slow": {
              "factor": 3,
              "radius": 200
            },
            "trail": {
              "delay": 1,
              "quantity": 1
            }
          }
        },
        "particles": {
          "collisions": {
            "enable": false,
            "mode": "bounce"
          },
          "color": {
            "value": "#ff0000",
            "animation": {
              "enable": true,
              "speed": 20,
              "sync": true
            }
          },
          "links": {
            "blink": false,
            "color": {
              "value": "#ffffff"
            },
            "consent": false,
            "distance": 100,
            "enable": true,
            "opacity": 0.4,
            "shadow": {
              "blur": 5,
              "color": {
                "value": "#00ff00"
              },
              "enable": false
            },
            "triangles": {
              "enable": false
            },
            "width": 1,
            "warp": false
          },
          "move": {
            "angle": 90,
            "attract": {
              "enable": false,
              "rotate": {
                "x": 600,
                "y": 1200
              }
            },
            "direction": "none",
            "enable": true,
            "noise": {
              "delay": {
                "random": {
                  "enable": false,
                  "minimumValue": 0
                },
                "value": 0
              },
              "enable": false
            },
            "outMode": "out",
            "random": false,
            "speed": 6,
            "straight": false,
            "trail": {
              "enable": false,
              "length": 10,
              "fillColor": {
                "value": "#000000"
              }
            },
            "vibrate": false,
            "warp": false
          },
          "number": {
            "density": {
              "enable": true,
              "area": 800,
              "factor": 1000
            },
            "limit": 0,
            "value": 80
          },
          "opacity": {
            "animation": {
              "enable": false,
              "minimumValue": 0.1,
              "speed": 3,
              "sync": false
            },
            "random": {
              "enable": false,
              "minimumValue": 1
            },
            "value": 0.5
          },
          "rotate": {
            "animation": {
              "enable": false,
              "speed": 0,
              "sync": false
            },
            "direction": "clockwise",
            "path": false,
            "random": false,
            "value": 0
          },
          "shadow": {
            "blur": 0,
            "color": {
              "value": "#000000"
            },
            "enable": false,
            "offset": {
              "x": 0,
              "y": 0
            }
          },
          "shape": {
            "options": {
              "polygon": {
                "nb_sides": 5
              },
              "star": {
                "nb_sides": 5
              },
              "image": {
                "src": "https://cdn.matteobruni.it/images/particles/github.svg",
                "width": 100,
                "height": 100
              },
              "images": {
                "src": "https://cdn.matteobruni.it/images/particles/github.svg",
                "width": 100,
                "height": 100
              }
            },
            "type": "circle"
          },
          "size": {
            "animation": {
              "destroy": "none",
              "enable": false,
              "minimumValue": 0.1,
              "speed": 20,
              "startValue": "max",
              "sync": false
            },
            "random": {
              "enable": true,
              "minimumValue": 1
            },
            "value": 3
          },
          "stroke": {
            "width": 0
          },
          "twinkle": {
            "lines": {
              "enable": false,
              "frequency": 0.05,
              "opacity": 1
            },
            "particles": {
              "enable": false,
              "frequency": 0.05,
              "opacity": 1
            }
          }
        },
        "pauseOnBlur": true
      },
      {
        "background": {
          "color": {
            "value": "#fff"
          },
          "image": "",
          "position": "50% 50%",
          "repeat": "no-repeat",
          "size": "cover",
          "opacity": 1
        },
        "backgroundMask": {
          "cover": {
            "color": {
              "value": "#fff"
            },
            "opacity": 1
          },
          "enable": false
        },
        "detectRetina": true,
        "fpsLimit": 60,
        "infection": {
          "cure": false,
          "delay": 0,
          "enable": false,
          "infections": 0,
          "stages": []
        },
        "interactivity": {
          "detectsOn": "canvas",
          "events": {
            "onClick": {
              "enable": false,
              "mode": "push"
            },
            "onDiv": {
              "ids": [],
              "enable": false,
              "mode": [],
              "type": "circle"
            },
            "onHover": {
              "enable": false,
              "mode": "repulse",
              "parallax": {
                "enable": false,
                "force": 60,
                "smooth": 10
              }
            },
            "resize": true
          },
          "modes": {
            "attract": {
              "distance": 200,
              "duration": 0.4,
              "speed": 1
            },
            "bubble": {
              "distance": 400,
              "duration": 2,
              "opacity": 0.8,
              "size": 40
            },
            "connect": {
              "distance": 80,
              "links": {
                "opacity": 0.5
              },
              "radius": 60
            },
            "grab": {
              "distance": 400,
              "links": {
                "opacity": 1
              }
            },
            "push": {
              "quantity": 4
            },
            "remove": {
              "quantity": 2
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4,
              "speed": 1
            },
            "slow": {
              "factor": 3,
              "radius": 200
            },
            "trail": {
              "delay": 1,
              "quantity": 1
            }
          }
        },
        "particles": {
          "collisions": {
            "enable": false,
            "mode": "bounce"
          },
          "color": {
            "value": "#000",
            "animation": {
              "enable": false,
              "speed": 1,
              "sync": true
            }
          },
          "links": {
            "blink": false,
            "color": {
              "value": "#000"
            },
            "consent": false,
            "distance": 150,
            "enable": true,
            "opacity": 0.4,
            "shadow": {
              "blur": 5,
              "color": {
                "value": "#00ff00"
              },
              "enable": false
            },
            "triangles": {
              "enable": false
            },
            "width": 1,
            "warp": false
          },
          "move": {
            "angle": 90,
            "attract": {
              "enable": false,
              "rotate": {
                "x": 600,
                "y": 1200
              }
            },
            "direction": "none",
            "enable": true,
            "noise": {
              "delay": {
                "random": {
                  "enable": false,
                  "minimumValue": 0
                },
                "value": 0
              },
              "enable": false
            },
            "outMode": "out",
            "random": false,
            "speed": 2,
            "straight": false,
            "trail": {
              "enable": false,
              "length": 10,
              "fillColor": {
                "value": "#000000"
              }
            },
            "vibrate": false,
            "warp": false
          },
          "number": {
            "density": {
              "enable": false,
              "area": 800,
              "factor": 1000
            },
            "limit": 0,
            "value": 0
          },
          "opacity": {
            "animation": {
              "enable": false,
              "minimumValue": 0.1,
              "speed": 1,
              "sync": false
            },
            "random": {
              "enable": false,
              "minimumValue": 1
            },
            "value": 0.5
          },
          "rotate": {
            "animation": {
              "enable": false,
              "speed": 0,
              "sync": false
            },
            "direction": "clockwise",
            "path": false,
            "random": false,
            "value": 0
          },
          "shadow": {
            "blur": 0,
            "color": {
              "value": "#000000"
            },
            "enable": false,
            "offset": {
              "x": 0,
              "y": 0
            }
          },
          "shape": {
            "options": {
              "polygon": {
                "nb_sides": 5
              },
              "star": {
                "nb_sides": 5
              },
              "image": {
                "src": "https://cdn.matteobruni.it/images/particles/github.svg",
                "width": 100,
                "height": 100
              },
              "images": {
                "src": "https://cdn.matteobruni.it/images/particles/github.svg",
                "width": 100,
                "height": 100
              }
            },
            "type": "circle"
          },
          "size": {
            "animation": {
              "destroy": "none",
              "enable": false,
              "minimumValue": 0.1,
              "speed": 40,
              "startValue": "max",
              "sync": false
            },
            "random": {
              "enable": true,
              "minimumValue": 1
            },
            "value": 5
          },
          "stroke": {
            "width": 0,
            "color": {
              "value": "#000000",
              "animation": {
                "enable": false,
                "speed": 1,
                "sync": true
              }
            }
          },
          "twinkle": {
            "lines": {
              "enable": false,
              "frequency": 0.05,
              "opacity": 1
            },
            "particles": {
              "enable": false,
              "frequency": 0.05,
              "opacity": 1
            }
          }
        },
        "pauseOnBlur": true,
        "absorbers": {
          "color": {
            "value": "#000000"
          },
          "draggable": false,
          "opacity": 1,
          "destroy": true,
          "orbits": false,
          "size": {
            "density": 20,
            "random": {
              "enable": true,
              "minimumValue": 30
            },
            "value": 50,
            "limit": 100
          },
          "position": {
            "x": 50,
            "y": 50
          }
        },
        "emitters": [
          {
            "direction": "top-right",
            "life": {},
            "rate": {
              "quantity": 1,
              "delay": 0.1
            },
            "particles": {
              "shape": {
                "type": "circle"
              },
              "color": {
                "value": "random"
              },
              "lineLinked": {
                "enable": false
              },
              "opacity": {
                "value": 0.3
              },
              "rotate": {
                "value": 0,
                "random": true,
                "direction": "counter-clockwise",
                "animation": {
                  "enable": true,
                  "speed": 15,
                  "sync": false
                }
              },
              "size": {
                "value": 10,
                "random": {
                  "enable": true,
                  "minimumValue": 5
                }
              },
              "move": {
                "speed": 5,
                "random": false,
                "outMode": "bounce"
              }
            },
            "position": {
              "x": 0,
              "y": 100
            }
          }
        ]
      }
      ,
      {
        background: {
          color: {
            value: "",
          },
          image: "",
          position: "50% 50%",
          repeat: "no-repeat",
          size: "cover",
          opacity: 1,
        },
        backgroundMask: {
          cover: {
            color: {
              value: "#fff",
            },
            opacity: 1,
          },
          enable: false,
        },
        detectRetina: true,
        fpsLimit: 60,
        infection: {
          cure: false,
          delay: 0,
          enable: false,
          infections: 0,
          stages: [],
        },
        interactivity: {
          detectsOn: "canvas",
          events: {
            onClick: {
              enable: false,
              mode: "push",
            },
            onDiv: {
              ids: [],
              enable: false,
              mode: [],
              type: "circle",
            },
            onHover: {
              enable: false,
              mode: "repulse",
              parallax: {
                enable: false,
                force: 2,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            attract: {
              distance: 200,
              duration: 0.4,
              speed: 1,
            },
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            connect: {
              distance: 80,
              links: {
                opacity: 0.5,
              },
              radius: 60,
            },
            grab: {
              distance: 400,
              links: {
                opacity: 1,
              },
            },
            push: {
              quantity: 4,
            },
            remove: {
              quantity: 2,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
              speed: 1,
            },
            slow: {
              factor: 3,
              radius: 200,
            },
            trail: {
              delay: 1,
              quantity: 1,
            },
          },
        },
        particles: {
          collisions: {
            enable: false,
            mode: "bounce",
          },
          color: {
            value: "#ffffff",
            animation: {
              enable: false,
              speed: 1,
              sync: true,
            },
          },
          links: {
            blink: false,
            color: {
              value: "#ffffff",
            },
            consent: false,
            distance: 150,
            enable: false,
            opacity: 0.4,
            shadow: {
              blur: 5,
              color: {
                value: "#00ff00",
              },
              enable: false,
            },
            triangles: {
              enable: false,
            },
            width: 1,
            warp: false,
          },
          move: {
            angle: 90,
            attract: {
              enable: false,
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            direction: "none",
            enable: true,
            noise: {
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
              },
              enable: false,
            },
            outMode: "destroy",
            random: false,
            speed: 5,
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fillColor: {
                value: "#000000",
              },
            },
            vibrate: false,
            warp: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
              factor: 1000,
            },
            limit: 0,
            value: 0,
          },
          opacity: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 3,
              sync: false,
            },
            random: {
              enable: false,
              minimumValue: 1,
            },
            value: 1,
          },
          rotate: {
            animation: {
              enable: false,
              speed: 0,
              sync: false,
            },
            direction: "clockwise",
            path: false,
            random: false,
            value: 0,
          },
          shadow: {
            blur: 0,
            color: {
              value: "#000000",
            },
            enable: false,
            offset: {
              x: 0,
              y: 0,
            },
          },
          shape: {
            options: {
              polygon: {
                nb_sides: 5,
              },
              star: {
                nb_sides: 5,
              },
              image: {
                src: "https://cdn.matteobruni.it/images/particles/github.svg",
                width: 100,
                height: 100,
              },
              images: {
                src: "https://cdn.matteobruni.it/images/particles/github.svg",
                width: 100,
                height: 100,
              },
            },
            type: "circle",
          },
          size: {
            animation: {
              destroy: "max",
              enable: true,
              minimumValue: 0.1,
              speed: 5,
              startValue: "min",
              sync: true,
            },
            random: {
              enable: false,
              minimumValue: 1,
            },
            value: 20,
          },
          stroke: {
            width: 0,
            color: {
              value: "#000000",
              animation: {
                enable: false,
                speed: 1,
                sync: true,
              },
            },
          },
          twinkle: {
            lines: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
            particles: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
          },
        },
        pauseOnBlur: true,
        emitters: {
          direction: "top",
          life: {},
          rate: {
            quantity: 2,
            delay: 0.1,
          },
          size: {
            mode: "percent",
            height: 0,
            width: 100,
          },
          position: {
            x: 50,
            y: 100,
          },
        },
      },

      {
        background: {
          color: {
            value: "#567698",
          },
          image: "",
          position: "",
          repeat: "",
          size: "",
          opacity: 1,
        },
        backgroundMask: {
          cover: {
            color: {
              value: "#fff",
            },
            opacity: 1,
          },
          enable: false,
        },
        detectRetina: true,
        fpsLimit: 30,
        infection: {
          cure: false,
          delay: 0,
          enable: false,
          infections: 0,
          stages: [],
        },
        interactivity: {
          detectsOn: "canvas",
          events: {
            onClick: {
              enable: true,
              mode: "repulse",
            },
            onDiv: {
              ids: [],
              enable: false,
              mode: [],
              type: "circle",
            },
            onHover: {
              enable: true,
              mode: "bubble",
              parallax: {
                enable: false,
                force: 2,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            attract: {
              distance: 200,
              duration: 0.4,
              speed: 1,
            },
            bubble: {
              distance: 250,
              duration: 2,
              opacity: 0,
              size: 0,
            },
            connect: {
              distance: 80,
              links: {
                opacity: 0.5,
              },
              radius: 60,
            },
            grab: {
              distance: 400,
              links: {
                opacity: 1,
              },
            },
            push: {
              quantity: 4,
            },
            remove: {
              quantity: 2,
            },
            repulse: {
              distance: 400,
              duration: 0.4,
              speed: 1,
            },
            slow: {
              factor: 3,
              radius: 200,
            },
            trail: {
              delay: 1,
              quantity: 1,
            },
          },
        },
        particles: {
          collisions: {
            enable: false,
            mode: "bounce",
          },
          color: {
            value: "#ffffff",
            animation: {
              enable: false,
              speed: 1,
              sync: true,
            },
          },
          links: {
            blink: false,
            color: {
              value: "#ffffff",
            },
            consent: false,
            distance: 150,
            enable: false,
            opacity: 0.4,
            shadow: {
              blur: 5,
              color: {
                value: "#00ff00",
              },
              enable: false,
            },
            triangles: {
              enable: false,
            },
            width: 1,
            warp: false,
          },
          move: {
            angle: 90,
            attract: {
              enable: false,
              rotate: {
                x: 600,
                y: 600,
              },
            },
            direction: "none",
            enable: true,
            noise: {
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
              },
              enable: false,
            },
            outMode: "out",
            random: true,
            speed: 1,
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fillColor: {
                value: "#000000",
              },
            },
            vibrate: false,
            warp: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
              factor: 1000,
            },
            limit: 0,
            value: 160,
          },
          opacity: {
            animation: {
              enable: true,
              minimumValue: 0,
              speed: 1,
              sync: false,
            },
            random: {
              enable: true,
              minimumValue: 1,
            },
            value: 1,
          },
          rotate: {
            animation: {
              enable: false,
              speed: 0,
              sync: false,
            },
            direction: "clockwise",
            path: false,
            random: false,
            value: 0,
          },
          shadow: {
            blur: 0,
            color: {
              value: "#000000",
            },
            enable: false,
            offset: {
              x: 0,
              y: 0,
            },
          },
          shape: {
            options: {
              polygon: {
                nb_sides: 5,
              },
              star: {
                nb_sides: 5,
              },
              image: {
                src: "https://cdn.matteobruni.it/images/particles/github.svg",
                width: 100,
                height: 100,
              },
              images: {
                src: "https://cdn.matteobruni.it/images/particles/github.svg",
                width: 100,
                height: 100,
              },
            },
            type: "circle",
          },
          size: {
            animation: {
              destroy: "none",
              enable: false,
              minimumValue: 0.3,
              speed: 4,
              startValue: "max",
              sync: false,
            },
            random: {
              enable: true,
              minimumValue: 1,
            },
            value: 3,
          },
          stroke: {
            width: 0,
            color: {
              value: "#000000",
              animation: {
                enable: false,
                speed: 1,
                sync: true,
              },
            },
          },
          twinkle: {
            lines: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
            particles: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
          },
        },
        pauseOnBlur: true,
      },
      {
        background: {
          color: {
            value: "#0d47a1",
          },
        },
        fpsLimit: 60,
        interactivity: {
          detectsOn: "canvas",
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 6,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "square",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      },

      {
        background: {
          color: {
            value: "#0d47a1",
          },
          image: "",
          position: "50% 50%",
          repeat: "no-repeat",
          size: "cover",
          opacity: 1,
        },
        backgroundMask: {
          cover: {
            color: {
              value: "#fff",
            },
            opacity: 1,
          },
          enable: false,
        },
        detectRetina: true,
        fpsLimit: 30,
        infection: {
          cure: false,
          delay: 0,
          enable: false,
          infections: 0,
          stages: [],
        },
        interactivity: {
          detectsOn: "canvas",
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onDiv: {
              ids: [],
              enable: false,
              mode: [],
              type: "circle",
            },
            onHover: {
              enable: false,
              mode: "repulse",
              parallax: {
                enable: false,
                force: 60,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            attract: {
              distance: 200,
              duration: 0.4,
              speed: 1,
            },
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            connect: {
              distance: 80,
              links: {
                opacity: 0.5,
              },
              radius: 60,
            },
            grab: {
              distance: 400,
              links: {
                opacity: 1,
              },
            },
            push: {
              quantity: 4,
            },
            remove: {
              quantity: 2,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
              speed: 1,
            },
            slow: {
              factor: 3,
              radius: 200,
            },
            trail: {
              delay: 1,
              quantity: 1,
            },
          },
        },
        particles: {
          collisions: {
            enable: true,
            mode: "bounce",
          },
          color: {
            value: "#ffffff",
            animation: {
              enable: false,
              speed: 1,
              sync: true,
            },
          },
          links: {
            blink: false,
            color: {
              value: "#ffffff",
            },
            consent: false,
            distance: 150,
            enable: false,
            opacity: 0.4,
            shadow: {
              blur: 5,
              color: {
                value: "#00ff00",
              },
              enable: false,
            },
            triangles: {
              enable: false,
            },
            width: 1,
            warp: false,
          },
          move: {
            angle: 90,
            attract: {
              enable: false,
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            direction: "none",
            enable: true,
            noise: {
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
              },
              enable: false,
            },
            outMode: "bounce",
            random: false,
            speed: 5,
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fillColor: {
                value: "#000000",
              },
            },
            vibrate: false,
            warp: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
              factor: 1000,
            },
            limit: 0,
            value: 80,
          },
          opacity: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 1,
              sync: false,
            },
            random: {
              enable: false,
              minimumValue: 1,
            },
            value: 0.5,
          },
          rotate: {
            animation: {
              enable: false,
              speed: 0,
              sync: false,
            },
            direction: "clockwise",
            path: false,
            random: false,
            value: 0,
          },
          shadow: {
            blur: 0,
            color: {
              value: "#000000",
            },
            enable: false,
            offset: {
              x: 0,
              y: 0,
            },
          },
          shape: {
            options: {
              polygon: {
                sides: 5,
              },
              star: {
                sides: 5,
              },
              image: {
                src: "https://cdn.matteobruni.it/images/particles/github.svg",
                width: 100,
                height: 100,
              },
              images: {
                src: "https://cdn.matteobruni.it/images/particles/github.svg",
                width: 100,
                height: 100,
              },
            },
            type: "circle",
          },
          size: {
            animation: {
              destroy: "none",
              enable: false,
              minimumValue: 0.1,
              speed: 40,
              startValue: "max",
              sync: false,
            },
            random: {
              enable: true,
              minimumValue: 10,
            },
            value: 15,
          },
          stroke: {
            width: 0,
            color: {
              value: "#000000",
              animation: {
                enable: false,
                speed: 1,
                sync: true,
              },
            },
          },
          twinkle: {
            lines: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
            particles: {
              enable: false,
              frequency: 0.05,
              opacity: 1,
            },
          },
        },
        pauseOnBlur: true,
      },
    ];
    
    const authToken = localStorage.getItem("AuthToken");

    axios.defaults.headers.common = { Authorization: `${authToken}` };

    for (let i = 0; i < templates.length; i++) {
      let template = templates[i];
      let template_str = JSON.stringify(template);
      
      const templateData = {
        title: "Template1",
        body: template_str,
      };
  
      axios
        .post(
          `https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/template/`, templateData
        )
        .then((response) => {
          
        })
        .catch((error) => {
          if (error) {
            this.handleError(error);
          }
        });
    }
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);