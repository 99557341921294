import  React , {useState} from 'react';
import {  Link, useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import styles from "./BrowseBackgrounds.css";
import gradientData from '../GradientPacks/gradient_packs.json';
import {  AnimatePresence } from 'framer-motion'

import {
    Input,
    Stack,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Box,
    Badge,
    MenuGroup,
    Skeleton,
    MenuDivider,
    MenuOptionGroup,
    MenuItemOption,
  } from "@chakra-ui/core";
import Canvas from 'components/Canvas/Canvas';
import { connect } from "react-redux";
import Elements from 'components/Elements/Elements';
import Templates from 'components/Templates/Templates';
import EditorHeaderNav from 'components/EditorHeaderNav/EditorHeaderNav';
import { withRouter } from 'react-router-dom'
import axios from "axios";

class BrowseBackgrounds extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
        gradients: []
    };
  }

  componentDidMount() {

  }

  render() {
    this.state.gradients = gradientData.gradients;
            
        var gradient_elems = this.state.gradients.map( (gradient, i) => {
        return(
            <div>
                <div className="browse_list">

                    <Link to={"/fullscreen-background/" + gradient.name}>
                        <motion.div
                        // variants={gradientVariants}
                        initial="hidden"
                        animate="visible"
                        transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        }}
                        >
                            <div className="browse_container"  data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50}>
                            <div className="gradient_preview rounded_border" style={{background: `linear-gradient(90deg, ${gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}}></div>
                            <section>
                                
                            </section>
                                <Box as="span" color="gray.600" fontSize="sm">
                                </Box>
                            </div>


                        </motion.div>
                    </Link>
                
                </div>
                
        
            </div>
        )
                    })

             
    return(
        <div>
            <h1>Backgrounds</h1>
            <h2>Gradient Backgrounds</h2>

            <section className="gradients">
                  {gradient_elems}
            </section>
            <Gallery1 images={images} id="" />
        </div>
        
      )       
                    
  }
}



const images = [
    { title: 'Photon1', src: 'https://source.unsplash.com/WLUHO9A_xik/600x400' },
    {
      title: 'Inversion1',
      src: 'https://source.unsplash.com/qwRfzgi7b8g/600x400'
    },
    { title: 'Legion1', src: 'https://source.unsplash.com/phIFdC6lA4E/600x400' },
    { title: 'Lokti1', src: 'https://source.unsplash.com/600x400/?pastel' },
    {
      title: 'Twenty Neue1',
      src: 'https://source.unsplash.com/600x400/?cyberpunk+city'
    },
    {
      title: 'Geometrik1',
      src: 'https://source.unsplash.com/600x400/?noir+city'
    },
    {
      title: 'Achtung1',
      src: 'https://source.unsplash.com/600x400/?pastel+clouds'
    }
  ]
  
  export function Gallery1({ id }) {

    const imageLoaded = true
  
    return (
      <div>
        <LightboxList selectedId={id} images={images} />
        <AnimatePresence>
          {id && imageLoaded && <Lightbox id={id} key='item' />}
        </AnimatePresence>
      </div>
    )
  }
  
  export function GalleryAndLightbox({ match }) {
    let { id } = match.params
    const imageLoaded = true
  
    return (
      <div>
        <AnimatePresence>
          {id && imageLoaded && <Lightbox id={id} key='item' />}
        </AnimatePresence>
      </div>
    )
  }
  
  function ImageCard({ title, src }) {
    return (
      <li>
        <div>
          <motion.div layoutId={`lcard-container-${title}`}>
            <motion.div layoutId={`lcard-image-container-${title}`}>
              <img src={src} />
            </motion.div>
            {/* <motion.div layoutId={`ltitle-container-${title}`}>
              <h2>{title}</h2>
            </motion.div> */}
          </motion.div>
        </div>
  
      </li>
    )
  }
  
  export function LightboxList({ selectedId, images }) {
    return (
      <ul className="imageGrid">
        {images.map((image) => (
          <Link to={'lbox/' + image.title} className="imageGridItem">
            <ImageCard
              key={image.title}
              {...image}
              isSelected={image.title === selectedId}
              className="imageCard"
            />
          </Link>
        ))}
      </ul>
    )
  }
  
  export function Lightbox({ id }) {
    const image = images.find((item) => item.title === id)
  
    return (
  
      <div className="lightbox">
            <motion.div layoutId={`lcard-container-${id}`}>
              <motion.div layoutId={`lcard-image-container-${id}`}
              initial="hidden"
              variants={{
                visible: { transition: {duration: 0.4, delay: 0.25} },
    
              }}
              whileHover={{scale: 1}}
              transition={{ duration: 0.2, delay: 0.15 }}
              className="fullHeight"
              >
                  <img src={image.src} alt='' />
              </motion.div>
            </motion.div>
          </div>
  
    // </motion.div>
    )
  }

export default withRouter(BrowseBackgrounds);