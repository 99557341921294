import React, { useState } from "react";
import { setBgAction } from "actions/setBgAction";
import { setSVGBackgroundColorAction } from "actions/setSVGBackgroundColorAction";
import { connect } from "react-redux";
import "svg.draggable.js";
import "svg.select.js";
import "svg.resize.js";
import { motion } from "framer-motion";
import { ChromePicker } from "react-color";
import {ChevronLeft} from "react-feather";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setSVGBackgroundColorAction: (payload) =>
    dispatch(setSVGBackgroundColorAction(payload)),
});

class SolidElementColors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gradient_color: "#897",
      isVisible: 1,
      colors: [
        "#ff70a6",
        "#ff9770",
        "#ffd670",
        "#e9ff70",
        "#70d6ff",
        "#ff0054",
      ],
      activeColor: "#fff",
    };
  }
  componentDidMount() {
    if (this.props.selectedElement != null) {
      let selected_elem_color = this.props.selectedElement.attr().fill;
      this.setState({ activeColor: selected_elem_color });
    }
  }

  renderComponent(component_type) {
    switch (component_type) {
      case "back":
        // move back
        this.props.history.goBack();
        break;

      case "solid":
        break;
    }
  }

  handleClick(color) {
    this.applyColor(color);
  }

  applyColor(color) {
    this.setState({ activeColor: color });
    
    if (this.props.selectedElement) {
        this.props.selectedElement.fill(color);
    }
  }

  handleChange = (color) => {
    this.applyColor(color.hex);
  };

  render() {
    var color_elems = this.state.colors.map((color, i) => {
      return (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <div
            className="square"
            onClick={() => this.handleClick(color)}
            style={{
              width: "10vh",
              height: "10vh",
              backgroundColor: `${color}`,
            }}
          />
        </motion.div>
      );
    });

    return (
      <div>
        <article className="heading">
          <h1>Solid Element Colors</h1>
          <ChevronLeft
            onClick={() => this.renderComponent("back")}
          />
        </article>

        <section className="gradients">{color_elems}</section>

        <ChromePicker
          color={this.state.activeColor}
          onChange={this.handleChange}
        />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SolidElementColors);
