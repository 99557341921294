import React, { useState } from "react";
import styles from "./Gradients.css";
import { setBgAction } from "actions/setBgAction";
import { connect } from "react-redux";
import SVG from "svg.js";
import "svg.draggable.js";
import "svg.select.js";
import "svg.resize.js";
import gradientData from "./gradients.json";
import { motion } from "framer-motion";
import { ChromePicker } from "react-color";
import { ChevronLeft } from 'react-feather';
import { setSVGGradientBackgroundColorAction } from "actions/setSVGGradientBackgroundColorAction";
import triangleGradientData from '../Gradients/triangle_gradients.json';
import radialGradientData from '../Gradients/radial_gradients.json';

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setSVGGradientBackgroundColorAction: (payload) => dispatch(setSVGGradientBackgroundColorAction(payload)),

});

class Gradients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svgHeight: 1100,
      svgWidth: 1200,
      width: 60,
      rect: null,
      gradient: null,
      draw: null,
      gradient_color: "#897",
      isVisible: 1,
    };
  }
  componentDidMount() {}

  renderComponent(component_type) {
    switch (component_type) {
      case "back":
        // move back
        this.props.history.goBack();

        break;

      case "gradient-packs":
        this.props.history.push("/app/gradient-packs");
        break;
    }
  }

  handleClick(gradient) {
    this.applyGradient(gradient);
  }

  applyGradient(gradient) {
    this.props.setSVGGradientBackgroundColorAction(gradient);
  }

  createGradientElems() {
    var gradient_elems = this.state.gradients.map((gradient, i) => {
      return (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <div
            className="gradient"
            onClick={() => this.handleClick(gradient)}
            style={{
              width: "10vh",
              height: "10vh",
              backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.color1}, ${gradient.color2})`,
            }}
          />
        </motion.div>
      );
    });
    return gradient_elems;
  }

  render() {
    if (this.props.design.designType == "triangles") {
      this.state.gradients = triangleGradientData.gradients;
    }
    else if (this.props.design.designType == "radialGradient") {
      this.state.gradients = radialGradientData.gradients;
    }
    else {
      this.state.gradients = gradientData.gradients;
    }

    let gradient_elems = this.createGradientElems();

    return (
      <div>
        <article className="heading">
          <h1>Gradients</h1>
          <ChevronLeft className="icon" onClick={() => this.renderComponent("back")} />
        </article>

        <section className="gradients">{gradient_elems}</section>
{/* 
        <ChromePicker /> */}

        {/* <section className="heading">
          <h2>Gradient Packs</h2>
          <ChevronLeft className="icon" onClick={() => this.renderComponent("gradient-packs")} />
        </section> */}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Gradients);
