import React, {useState, useEffect} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from "@chakra-ui/core";
import Header from '../partials/Header';
import axios from "axios";
import {generateUserDocument, auth} from "firebase.js";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import { Auth } from 'aws-amplify';

function ConfirmEmail() {
  const [isLoading, setLoading] = useState(false);
  const [error_msg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [verification_code, setVerificationCode] = useState("");
  const [username, setUsername] = useState("");
  const [isMounted, setMounted] = useState(true);
  const [welcomeMsg, setWelcomeMsg] = useState("Enter your email below so we can send you a verification code.");
  const [user, setUser] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);

  const [email, setEmail] = useState("");
  let history = useHistory();

  let query = new URLSearchParams(useLocation().search);
  const username_qs = query.get('username')

  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    document.addEventListener("keydown", onKeyPressed.bind());
  
    // returned function will be called on component unmount 
    return () => {
      setMounted(false);
      document.removeEventListener("keydown", onKeyPressed);
    }
  }, [])

  async function sendCode(event, email) {
    event.preventDefault();

    try {
      setLoading(true);

      await Auth.resendSignUp(email);
      setLoading(false);
      setIsConfirm(true);
      setWelcomeMsg("Enter the code sent to your email below so we can verify your account.")
      } catch (err) {
          setLoading(false);

      }
  }

  async function verifyCode(event, verification_code) {
    event.preventDefault();
    try {
      setLoading(true);

        const { user } = await Auth.confirmSignUp(
            email,
            verification_code,
        );
  
        
        history.push("/dashboard");
        
        setLoading(false);
        setIsConfirm(true);
    } catch (error) {
        setLoading(false);
    }
  }

  function onKeyPressed(e) {
    if (e.keyCode == "13") {
      e.preventDefault();
      // submit login form
    }
  }

  function handleChange(event){
    const { name, value } = event.currentTarget;

    if (name === "verification_code") {
      setVerificationCode(value);
    }
    else if (name === "email") {
      setEmail(value);
    }
  };




  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-9">
                <h1 className="h1" data-aos="zoom-y-out">Confirm Email</h1>
                <p className="text-gray-600 mt-5" data-aos="zoom-y-out" data-aos-delay="100">{welcomeMsg}</p>
                <p className="error_msg">{error_msg}</p>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto" data-aos="zoom-y-out" data-aos-delay="150">

                <form>

                <div className={isConfirm ? "hidden" : "flex flex-wrap -mx-3 mb-4"}>
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                      <input id="email" type="email" className="form-input w-full text-gray-800" onChange={event => handleChange(event)}
                      name="email" placeholder="Enter your email" required />
                    </div>
                  </div>

                  <div className={isConfirm ? "flex flex-wrap -mx-3 mb-4" : "hidden"}>
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="verification_code">Code <span className="text-red-600">*</span></label>
                      <input id="verification_code" type="verification_code" className="form-input w-full text-gray-800" onChange={event => handleChange(event)}
                      name="verification_code" placeholder="Enter your verification code" required />
                    </div>
                  </div>


                  <div className={isConfirm ? "hidden" : "flex flex-wrap -mx-3 mt-6"}>
                    <div className="w-full px-3">
                    <Button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full button" type="button"
                      variant="purple" loadingText="Sending code"
                      onClick={event => {
                        sendCode(event, email);
                      }}
                      isLoading={isLoading}>Send Code</Button>
                    </div>
                  </div>

                  <div className={isConfirm ? "flex flex-wrap -mx-3 mt-6" : "hidden"}>
                    <div className="w-full px-3">
                    <Button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full button" type="button"
                      variant="purple" loadingText="Confirming"
                      onClick={event => {
                        verifyCode(event, verification_code);
                      }}
                      isLoading={isLoading}>Confirm</Button>
                    </div>
                  </div>
                </form>

              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default ConfirmEmail;