import  React , {useState} from 'react';
import { setBgAction } from "actions/setBgAction";
import { setElementAction } from "actions/setElementAction";
import { BrowserRouter, Route, Switch, Link, useParams } from 'react-router-dom';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import Sidebar from 'components/Sidebar/Sidebar';
import { motion } from "framer-motion";
import styles from "./styles.scss";
import gradientData from '../GradientPacks/gradient_packs.json';
import {Heart} from "react-feather";

import {
    Input,
    Stack,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Box,
    Badge,
    MenuGroup,
    Skeleton,
    MenuDivider,
    MenuOptionGroup,
    MenuItemOption,
    useColorMode,
    LightMode,
    DarkMode,

  } from "@chakra-ui/core";
import Canvas from 'components/Canvas/Canvas';
import { connect } from "react-redux";
import Elements from 'components/Elements/Elements';
import Templates from 'components/Templates/Templates';
import EditorHeaderNav from 'components/EditorHeaderNav/EditorHeaderNav';
import { withRouter } from 'react-router-dom'
import axios from "axios";

class BrowseGradients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        gradients: []
    };
  }

  onClick = (e) => {
    e.preventDefault();
    let elem = e.target;
    elem.style.stroke = "black";
    let className = elem.className;
        let newClassName = className + " visible_icon"
        elem.setAttribute("class", newClassName );
}

  componentDidMount() {
  }

  render() {
    this.state.gradients = gradientData.gradients;
            
        var gradient_elems = this.state.gradients.map( (gradient, i) => {
        return(
            <div>
                <div className="browse_list">

                <Link to={"/dashboard/gradient-preview/" + gradient.name}>
                    <motion.div
                    // variants={gradientVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                    }}
                    >
                        <div className="browse_container">
                        {/* <div className="gradient_preview rounded_border" style={{background: `linear-gradient(90deg,  ${gradient.color1} 0%, hsla(186, 100%, 69%, 1) 100%)`}}></div> */}
                        <div className="gradient_preview rounded_border" style={{background: `linear-gradient(90deg, ${gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}}></div>

                            <Heart className="icon heart" onClick={(e) => this.onClick(e)} />

                            <Box as="span" color="gray.600" fontSize="sm">
                            </Box>
                        </div>



                    </motion.div>
                </Link>

                </div>
                
        
            </div>
        )
                    })

             
    return(
        <div>
            <h1>Gradients</h1>
            <section className="gradients">
                  {gradient_elems}
            </section>
  
        </div>
        
      )       
                    
  }
}


function ToggleDarkMode() {
    const { colorMode, toggleColorMode } = useColorMode()
  
    return (
      <div>
        <LightMode>
          <Button size="sm" colorScheme="blue">
            Light Mode Always
          </Button>
        </LightMode>
  
        <DarkMode>
          <Button size="sm" colorScheme="blue">
            Dark Mode Always
          </Button>
        </DarkMode>
  
        <Button size="sm" colorScheme="blue" onClick={toggleColorMode}>
          Toggle Mode
        </Button>
      </div>
  
    )
  }

export default withRouter(BrowseGradients);