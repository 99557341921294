import React, {useState, useEffect} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from "@chakra-ui/core";
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import axios from "axios";
import { Auth, API, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

function SignUp() {
  const [isLoading, setLoading] = useState(false);
  const [error_msg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [isMounted, setMounted] = useState(true);
  const [welcomeMsg, setWelcomeMsg] = useState("Welcome! To get started, just sign up for an account below.");
  const [user, setUser] = useState("");
  const [design, setDesign] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [code, setCode] = useState(false);
  const [email, setEmail] = useState("");

  let history = useHistory();

  let query = new URLSearchParams(useLocation().search);
  const saveNewDesign = query.get('saveNewDesign')

  async function amplifySignUp(event, email, password, ) {
    try {
      setLoading(true)

        const { user } = await Auth.signUp({
            username: email,
            password: password,
            attributes: {
                "email": email, 
                "custom:subscribed" : "false"
            }
        });
        setLoading(false);
        setIsConfirm(true);
    } catch (error) {
        if (error.name == "UsernameExistsException") {
          setErrorMsg("A user with that email already exists. Were you trying to login instead?")
        }
        setLoading(false);
    }
}

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then(userData => userData)
    .catch(() => {console.log('Not signed in')});
}

async function signUpToNewsletter(email) {
  const apiName = 'inixiawbmrestapi'
  const apiEndpoint = '/subscriber'
  const body = {
      email:   email
  }
  const session = await API.post(apiName, apiEndpoint, { body });

    const user = await Auth.signIn(email, password);
    setLoading(false);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
          'custom:subscribed': 'true'
      });
    } catch (err) {
    }
    history.push("/dashboard");
}


  async function confirmEmail(event, email, code, ) {
    event.preventDefault();
    setLoading(true);
    
    try {


        const { user } = await Auth.confirmSignUp(
            email,
            code,
        );
        
        signUpToNewsletter(email);

        
    } catch (error) {
        setLoading(false);
    }
  }

    
  const signUpWithGoogle = (event) => {
    event.preventDefault();
    Auth.federatedSignIn({provider: 'Google'});
    
  }

  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    document.addEventListener("keydown", onKeyPressed.bind());

    if (saveNewDesign == "true"){
      setWelcomeMsg("Welcome! To save your design, just sign up below.");

      let saveNewDesignLocalStorage = localStorage.getItem("saveNewDesignInixia"); 

      if (saveNewDesignLocalStorage == true) {
        let design = localStorage.getItem("design");
        setDesign(design)
        
      }

    }
  
    // returned function will be called on component unmount 
    return () => {
      setMounted(false);
      document.removeEventListener("keydown", onKeyPressed);
    }
  }, [])


  function onKeyPressed(e) {
    if (e.keyCode == "13") {
      e.preventDefault();
      // submit login form
      handleSubmit();
    }
  }

  function handleChange(event){
    const { name, value } = event.currentTarget;

    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } 
    else if (name === "username") {
      setUsername(value);
    }
  };

  function handleSubmit(event) {
    setLoading( true );

    const userData = {
      email: email,
      password: password,
    };

  };
  


  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-9">
                <h1 className="h1" data-aos="zoom-y-out">Sign up</h1>
                <p className="text-gray-600 mt-5" data-aos="zoom-y-out" data-aos-delay="100">{welcomeMsg}</p>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto" data-aos="zoom-y-out" data-aos-delay="150">
              <form>

                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                      <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center"
                         onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})} type="button">
                        <svg className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                        </svg>
                        <span className="flex-auto pl-16 pr-8 -ml-16 button">Continue with Google</span>
                      </button>
                    </div>
                  </div>

                  {/* <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                      <button className="btn px-0 text-white bg-blue-700 hover:bg-blue-800 w-full relative flex items-center"
                      onClick={event => {Auth.federatedSignIn({provider: "Facebook"})}} type="button">
                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                        </svg>
                        <span className="flex-auto pl-16 pr-8 -ml-16 button">Continue with Facebook</span>
                      </button>
                    </div>
                  </div> */}
                </form>

                <div className="flex items-center my-6">
                  <div className="border-t border-gray-300 flex-grow mr-3" aria-hidden="true"></div>
                  <div className="text-gray-600 italic">Or</div>
                  <div className="border-t border-gray-300 flex-grow ml-3" aria-hidden="true"></div>
                </div>
              <div className={isConfirm ? "hidden" : ""}> 
                <form>
                {/* <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="username">Username <span className="text-red-600">*</span></label>
                      <input id="username" type="username" className="form-input w-full text-gray-800" onChange={event => handleChange(event)}
                      name="username" placeholder="Enter your username" required />
                    </div>
                  </div> */}

                  <div className="flex flex-wrap -mx-3 mb-4">

                    <div className="w-full px-3">
                      <p className="error_msg">{error_msg}</p>
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                      <input id="email" type="email" className="form-input w-full text-gray-800" onChange={event => handleChange(event)}
                      name="email" placeholder="Enter your email address" required />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password" name="password" onChange={() => handleChange}>Password <span className="text-red-600">*</span></label>
                      <input id="password" type="password" className="form-input w-full text-gray-800" placeholder="Enter your password" 
                      name="password" onChange={event => handleChange(event)} required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                    <Button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full button" type="button"
                      variant="purple" loadingText="Signing you up"
                      onClick={event => {
                        amplifySignUp(event, email, password);
                      }}
                      isLoading={isLoading}>Sign Up</Button>
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 text-center mt-3">
                    By creating an account, you agree to the <a className="underline" href="https://websitebackgroundmaker/about/terms">terms & conditions</a>,
                     and our <a className="underline" href="https://websitebackgroundmaker.com/about/privacy">privacy policy</a>.
                                </div>
                </form>

                <div className="text-gray-600 text-center mt-6">
                  Already have an account? <Link to="/login" className="text-blue-600 hover:underline transition duration-150 ease-in-out">Sign in</Link>
                </div>


              </div>

              <div className={isConfirm ? "" : "hidden"}>

            <div className="max-w-sm mx-auto">
              <form>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="code">Confirmation Code <span className="text-red-600">*</span></label>
                    <input id="code" type="code" className="form-input w-full text-gray-800" 
                    placeholder="Enter code sent to your email" required 
                    onChange={e => setCode(e.target.value)} />
                  </div>
                </div>
                
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <Button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full button" type="button"
                      variant="purple" loadingText="Confirming email"
                      onClick={event => {
                        confirmEmail(event, email, code);
                      }}
                      isLoading={isLoading}>Confirm Email</Button>
                    </div>
                </div>
              </form>
            </div>
            </div>
              </div>

            </div>
          </div>
        </section>
      <Footer />
      </main>

    </div>
  );
}

export default SignUp;