import  React , {useState} from 'react';
import styles from './Sidebar.css';
import { setBgAction } from "actions/setBgAction";
import {  Route, Switch, Link } from 'react-router-dom';
import Gradients from 'components/Gradients/Gradients';
import SolidBackgroundColors from 'components/SolidBackgroundColors/SolidBackgroundColors';
import Elements from 'components/Elements/Elements';
import Templates from 'components/Templates/Templates';
import Customize from 'components/Customize/Customize';
import Element from 'components/Element/Element';
import Backgrounds from 'components/Backgrounds/Backgrounds';
import GradientPacks from 'components/GradientPacks/GradientPacks';
import BlobGenerator from 'components/BlobGenerator/BlobGenerator';
import PatternBackgrounds from 'components/PatternBackgrounds/PatternBackgrounds';
import SolidElementColors from 'components/SolidElementColors/SolidElementColors';
import { Grid, Image, Layers } from 'react-feather';
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
    ...state,
  });
  

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        gradients: [],
        isSVGDesign: false,
        activeBtn: "templates",
        links: [
            {id: "customize",
            name: "Customize",
            icon: <Layers/>,
        },
            {id: "templates",
            name: "Templates",
            icon: <Grid/>,
        },
            {
                id: "elements",
                name: "Elements",
                icon: <Grid/>,
            },
            {
                id: "backgrounds",
                name: "Backgrounds",
                icon: <Image/>,
            }
        ]
        
    };
    this.myRef = React.createRef()  
  }
  componentDidMount() {
    this.updateActiveBtn(this.state.links[0])
  }

  updateActiveBtn(link) {
    this.setState({activeBtn: link.id});
  }

  render() {
    const qs = queryString.parse(this.props.location.search);
    const design_type = qs.designType;
    if (design_type == "svg" || design_type == "svgOverlay") {
        this.setState({isSVGDesign: true})
    }

    return(
        <div className="sidebar" ref={this.myRef}>
        <div className="sidebar_content">
            
                <div className="sidebar_left_bar">
                <ul>
                    {this.state.links.map(link => {
                    return (
                        <div key={link.id} className={(link.id === "elements" && this.state.isSVGDesign == false ? " hidden" : "")}>

                            <li
                            className={
                                link.id +
                                (link.id === this.state.activeBtn ? " active_btn" : "")
                            }
                            >
                            {/* {link.name} {link.id === activeLink && "active!"} */}
                            <Link to={"/app/" + link.id}
                                onClick={() => this.updateActiveBtn(link)}>
                                {link.icon}
                                {link.name}
                            </Link>
                            </li>
                        </div>
                    );
                    })}
            </ul>
            </div>

        <div className="sidebar_right_bar" > 
            <Switch>
                <Route path="/app/" component={Customize} exact />
                <Route path="/app/gradients" component={Gradients} />
                <Route path="/app/elements" component={Elements} /> 
                <Route path="/app/templates" component={Templates} /> 
                <Route path="/app/customize" component={Customize} /> 
                <Route path="/app/element" component={Element} /> 
                <Route path="/app/background-colors" component={SolidBackgroundColors} /> 
                <Route path="/app/backgrounds" component={Backgrounds} /> 
                <Route path="/app/gradient-packs" component={GradientPacks} /> 
                <Route path="/app/blob-generator" component={BlobGenerator} /> 
                <Route path="/app/pattern-backgrounds" component={PatternBackgrounds} /> 
                <Route path="/app/element-colors" component={SolidElementColors} /> 

            </Switch>

        </div>

    </div>
    </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(Sidebar));