import React, { useState } from "react";
import styles from "./ViewTemplates.css";
import axios from 'axios';
import { Box, Image, Badge} from "@chakra-ui/core";

class ViewTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: false,
      errors: [],
      error_msg: "",

        imageUrl: "https://bit.ly/2Z4KKcF",
        imageAlt: "Design",
        beds: 3,
        baths: 2,
        title: "Blue bubbles",
        formattedPrice: "Last week",
        reviewCount: 34,
        rating: 4,

    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    // there were errors,so set state.
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {

    this.setState({ isLoading: true });
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    let api_url = "https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/login";

    axios
      .post("https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/login", userData)
      .then((response) => {
        if (response.status == 200) {
          // user logged-in successfully
          localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
          this.setState({
            isLoading: false,
          });
          this.props.history.push("/app");
        }

      })
      .catch((error) => {
        if (error.response.status == 403) {
          // wrong credentials provided 
          this.state.error_msg = "Wrong login details provided; could you try signing in again?";
        }

        this.setState({
          errors: error.response.data,
          isLoading: false,
        });
      });
  };

  

  render() {
    return (
        <div>
            <div className="designs_container">
                <h1>Available Templates</h1>
                
                <section className="designs">
                    <Box maxW="sm" borderWidth="1px" rounded="lg" overflow="hidden" className="design">
                        <Image src={require('bubbles3.PNG')}  alt={this.state.imageAlt} />

                        <Box p="6">
                            <Box d="flex" alignItems="baseline">
                            <Badge rounded="full" px="2" variantColor="teal">
                                New
                            </Badge>

                            </Box>

                            <Box
                            mt="1"
                            fontWeight="semibold"
                            as="h4"
                            lineHeight="tight"
                            isTruncated
                            >
                            {this.state.title}
                            </Box>

                            <Box as="span" color="gray.600" fontSize="sm">
                                {this.state.formattedPrice}        
                            </Box>

                        </Box>
                        </Box>

                    <section className="designs">

                    </section>

                </section>
            </div>
        </div>


    );
  }
}

export default ViewTemplates;
