import  React , {useState} from 'react';
import styles from './Backgrounds.css';
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import {setOpacityAction} from "actions/setOpacityAction";
import { connect } from "react-redux";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import gradientData from '../Gradients/gradient_collection_resource.json';
import triangleGradientData from '../Gradients/triangle_gradients.json';
import radialGradientData from '../Gradients/radial_gradients.json';
import { setBackgroundColorAction } from "actions/setBackgroundColorAction";
import { downloadSVGElementAction } from "actions/downloadSVGElementAction";
import { deleteSVGElementAction } from "actions/deleteSVGElementAction";
import { setSelectedElementAction } from "actions/setSelectedElementAction";
import { setSelectedElementsAction } from "actions/setSelectedElementsAction";
import { setSVGBackgroundColorAction } from "actions/setSVGBackgroundColorAction";
import { setSVGGradientBackgroundColorAction } from "actions/setSVGGradientBackgroundColorAction";
import { motion } from "framer-motion"
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ChromePicker } from 'react-color'
import {Button} from "@chakra-ui/core";
import { ChevronRight } from 'react-feather';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setBackgroundColorAction: (payload) => dispatch(setBackgroundColorAction(payload)),
  setOpacityAction: (payload) => dispatch(setOpacityAction(payload)),
  deleteSVGElementAction: () => dispatch(deleteSVGElementAction),
  setSelectedElementAction: (payload) => dispatch(setSelectedElementAction(payload)),
  setSVGBackgroundColorAction: (payload) => dispatch(setSVGBackgroundColorAction(payload)),
  setSVGGradientBackgroundColorAction: (payload) => dispatch(setSVGGradientBackgroundColorAction(payload)),

});

class Backgrounds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        svgHeight: 1100,
        svgWidth: 1200,
        opacitySliderValue: 8,
        value: 7,
        gradient: null,
        opacitySliderValue: 30.0,
        draw: null,
        gradient_color: "#897",
        isVisible: 1,
        colors: ["#F06", "#F01", "#891", "#710"],
        svg_background: null,
        design: {},
    };
  }
  componentDidMount() {
    let svg_background = SVG.get("background");

    // check if background rect already exists
    if (svg_background != null) {
      this.setState({svg_background: svg_background});
      let opacity = svg_background.attr("opacity");
      this.setState({opacitySliderValue : opacity});

    }

  }

  handleClick(gradient) {
    this.applyGradient(gradient);
  }

  downloadSVGElement() {
      if (this.props.selectedElement != null) {
        let svg_elem = this.props.selectedElement.svg();
      }
  }

  deleteSVGElement() {
      if (this.props.selectedElement != null && this.props.selectedElements != null) {
        this.props.selectedElement.selectize(false);
        let index = this.props.selectedElements.indexOf(this.props.selectedElement);
        this.props.selectedElements.splice(index, 1);
        this.props.selectedElement.remove();

        this.props.selectedElements.forEach((elem, index) => {
            elem.id("Element_" + index);
          });
    }
  }

  clearBackground() {
    let background = SVG.get("background");
    if (background != null) {
      background.remove();
    }
  }

  applyGradient(gradient) {
    this.setState({opacitySliderValue: 100});
    this.props.setSVGGradientBackgroundColorAction(gradient);
  }
    
  changeBackgroundColor(color) {
    this.props.setSVGBackgroundColorAction(color);
  }

  renderComponent(component_type) {
      switch(component_type) {
          case "gradients":
              this.props.history.push('/app/gradients');
              break;

        case "solid":
            this.props.history.push('/app/background-colors');
            break;

        case "animated-backgrounds":
            this.props.history.push('/app/animated-backgrounds');
            break;
        case "pattern-backgrounds":
            this.props.history.push("/app/pattern-backgrounds");
      }
  }

  onSliderChange = value => {
    this.setState({opacitySliderValue : value});
    this.props.setOpacityAction(value / 100);
  };
  
  onSliderChange1 = value => {
    this.setState({
      value: value,
    });
  };

  render() {
    if (this.props.design.designType == "triangles") {
      this.state.gradients = triangleGradientData.gradients;
    }
    else if (this.props.design.designType == "radialGradient") {
      this.state.gradients = radialGradientData.gradients;
    }
    else {
      this.state.gradients = gradientData.gradients;
    }

    var gradient_elems = this.state.gradients.map( (gradient, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="square" onClick={() => this.handleClick(gradient)} key={gradient.name}
        style={{width : "10vh", height: "10vh", backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.color1}, ${gradient.color2})` }} />
        
      </motion.div>

    });

    var color_elems = this.state.colors.map( (color, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="square" onClick={() => this.changeBackgroundColor(color)} 
        style={{width : "6vh", height: "6vh", backgroundColor: `${color}` }} />
        
      </motion.div>

    });

    return(
      <div>
        <h1>Backgrounds</h1>
        {/* <div className="btns">
          <Button variantColor="purple" variant="solid" onClick={() => this.downloadSVGElement()}>Download</Button>

          <Button variantColor="pink" onClick={() => this.clearBackground()}>
              <ion-icon name="trash" size="small"></ion-icon>
              Clear
          </Button>

        </div> */}

        {/* <section className="slider">
            <h3>Opacity</h3>
            <article>
                <Slider onChange={this.onSliderChange} value={this.state.opacitySliderValue} />
            </article>
        </section> */}

        <ChromePicker className={this.state.svg_background ? '' : 'hidden'}/>

        <div>
          <div className="heading">
                <h2>Gradient Backgrounds</h2>
                <ChevronRight className="icon" onClick={() => this.renderComponent("gradients")} />
          </div>
          
          <section className="gradients">
                {gradient_elems}
          </section>
        </div>

          
          {/* <div className="heading">
              <h2>Patterns</h2>
              <ChevronRight className="icon" onClick={() => this.renderComponent("pattern-backgrounds")} />
          </div> */}
          <section className="gradients">

          </section>

          {/* <div className="heading">
              <h2>Solid Backgrounds</h2>
              <ChevronRight className="icon" onClick={() => this.renderComponent("solid")} />
          </div> */}
          {/* <section className="gradients">
                {color_elems}
          </section> */}


          {/* <div className="heading">
              <h2>Animated Backgrounds</h2>
              <ChevronRight className="icon" onClick={() => this.renderComponent("animated-backgrounds")} />
          </div> */}
          <section className="gradients">

          </section>

      </div>
      
    )
  }

   componentDidUpdate(prevProps, prevState, snapshot) {
     if (this.state.background) {
        if (prevProps.selectedElement !== this.props.selectedElement) {
          this.state.opacitySliderValue = this.props.selectedElement.attr().opacity * 100;
      }
       
     }

     if (prevProps.design !== this.props.design) {
      this.setState({design: this.props.design});
    }

   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Backgrounds);