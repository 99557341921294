import  React , {useState} from 'react';
import styles from './Elements.css';
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import { connect } from "react-redux";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import { setElementAction } from "actions/setElementAction";
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import {Button} from "@chakra-ui/core";
import { ChevronRight } from 'react-feather';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  startAction: () => dispatch(startAction),
  stopAction: () => dispatch(stopAction),
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setElementAction: (payload) => dispatch(setElementAction(payload)),
});


class Elements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        svgHeight: 1100,
        svgWidth: 1200,
        width: 60,
        rect: null,
        gradient: null,
        draw: null,
        gradient_color: "#897"
    };
  }
  componentDidMount() {
    this.appendSVGElements();
  }

  appendSVGElements() {
    let elem_sections = document.getElementsByClassName("elements");

    for (let k = 0; k < elem_sections.length; k++) {
      let elem = elem_sections[k];

      switch(elem.id) {
        case "rects":
          this.initElements(elem);
          break;
        case "circles":
            this.initElements(elem);
            break;
        case "waves":
          this.initElements(elem);
        case "blobs":
          this.initBlobs(elem);
          break;
      }
    }

  }

  initElements(elem) {
    let fill_colors = ["#FF70A6", "#FFD670", "#FF9701", "#FF0054", "#70D6FF", "#E9FF70"];
    
    for (let k = 0; k < fill_colors.length; k++) {
      let fill_color = fill_colors[k];
      let string_id = String(elem.id + k);
      let svg_container = document.createElement("div");
      svg_container.id = string_id;
      svg_container.className = "element_container";

      elem.appendChild(svg_container);

      let draw = SVG(string_id).size('100%', '100%');
      this.addSidebarElement(draw, fill_color, elem);
    }
  }

  addSidebarElement(draw, fill, elem) {
    draw.viewbox(0, 0, 97, 110);
    let element = this.getElement(draw, fill, elem);

      var app = this;
      var click = function() {
        app.drawElementOnCanvas(fill, elem);
      }
      
      element.on('click', click);
  }

  drawElementOnCanvas(fill, elem) {
    let element = this.getElement(this.props.draw, fill, elem);
    this.props.setElementAction(element);
  }

  getElement(draw, fill, elem) {
    var element;
    switch(elem.id) {
      case "rects":
        element =  draw.rect(80, 80);
        break;
      case "circles":
        element = draw.circle(80, 80);
        break;

        case "waves":
          element = draw.path("M56.5,-22.4C63.4,2.6,52.2,29.5,32.7,43.2C13.3,56.8,-14.3,57.3,-31,44.7C-47.7,32,-53.5,6.4,-46.5,-18.9C-39.5,-44.2,-19.8,-69.1,2.5,-69.9C24.9,-70.8,49.7,-47.5,56.5,-22.4Z");
          break;
        }

    element.fill(fill);
    return element;
  }

  initBlobs(elem) {

  }

  renderComponent(component_type) {
    switch (component_type) {
      case "blobs":
        // move back
        this.props.history.push("/app/blobs");
        break;

      case "waves":
        this.props.history.push("/app/waves");
        
      case "circles":
        this.props.history.push("/app/circles");
        break;
    }
  }

  handleClick(element) {
    this.props.setElementAction(element);
  }
  
  addElement(element) {
    this.props.addElement(element);
  }

  render() {
    // this.state.gradients = gradientData.gradients;
    // console.log("gradients", this.state.gradients);

            
    // var gradient_elems = this.state.gradients.map( (gradient, i) => {
    //     return <div className="gradient" onClick={() => this.handleClick(gradient)} 
    //     style={{width : "10vh", height: "10vh", backgroundImage: `linear-gradient(${gradient.deg}, ${gradient.color1}, ${gradient.color2})` }} />;
    // });


    return(
      <div>
          <h1>Elements</h1>

          <section>
                <section>
                  <section class="heading">
                    <h2>Blobs</h2>
                    <ChevronRight className="icon" onClick={() => this.renderComponent("blobs")} />

                    <Link to="/app/blob-generator">
                      <Button variantColor="pink" variant="solid">
                        Blob Generator
                      </Button>
                      </Link>
                  </section>

                    {/* <article className="element_svg" onClick={() => this.handleClick("blob")}>
                      <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#FF0066" d="M56.5,-22.4C63.4,2.6,52.2,29.5,32.7,43.2C13.3,56.8,-14.3,57.3,-31,44.7C-47.7,32,-53.5,6.4,-46.5,-18.9C-39.5,-44.2,-19.8,-69.1,2.5,-69.9C24.9,-70.8,49.7,-47.5,56.5,-22.4Z" transform="translate(100 100)" />
                      </svg>
                    </article> */}
                </section>

                <section>

                  <section class="heading">
                      <h2>Circles</h2>
                      <ChevronRight className="icon" onClick={() => this.renderComponent("circles")} />
                  </section>                    
                
                  <section className="elements" id="circles">
                      
                  </section>    

                </section>

                <section>
                  <section class="heading">
                        <h2>Waves</h2>
                        <ChevronRight className="icon" onClick={() => this.renderComponent("waves")} />
                        <section className="elements" id="waves">
                      
                      </section>    
                      
                    </section>                    
                    
                </section>

                <section>
                  <section class="heading">
                        <h2>Other</h2>
                        <ChevronRight className="icon" onClick={() => this.renderComponent("circles")} />
                    </section>   

                    <section className="elements" id="rects">
                      
                    </section>                 
                  
                    <article className="element_svg" onClick={() => this.handleClick("rect")}>
                    <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg">
                      <rect width="100" height="100" fill="rgb(0,0,255)"  />
                    </svg> 
                    </article>
                </section>
          </section>
      </div>
      
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

}
}

export default connect(mapStateToProps, mapDispatchToProps)(Elements);