import  React , {useState} from 'react';
import { setBgAction } from "actions/setBgAction";
import { setElementAction } from "actions/setElementAction";
import { BrowserRouter, Route, Switch, Link, useParams } from 'react-router-dom';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import Sidebar from 'components/Sidebar/Sidebar';
import { motion } from "framer-motion";
import styles from "./FullscreenBackground.css";
import gradientData from '../GradientPacks/gradient_packs.json';

import {
    Input,
    Stack,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Box,
    Badge,
    MenuGroup,
    Skeleton,
    MenuDivider,
    MenuOptionGroup,
    MenuItemOption,
  } from "@chakra-ui/core";
import Canvas from 'components/Canvas/Canvas';
import { connect } from "react-redux";
import Elements from 'components/Elements/Elements';
import Templates from 'components/Templates/Templates';
import EditorHeaderNav from 'components/EditorHeaderNav/EditorHeaderNav';
import { withRouter } from 'react-router-dom'
import axios from "axios";

class FullscreenBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        gradients: [],
        gradient: {},
    };
  }

  componentDidMount() {
      this.state.gradients = gradientData.gradients;

      const gradient_id = this.props.match.params.id;

      let gradient = gradientData.gradients.filter(gradient => gradient.name === gradient_id)[0];
      this.setState({gradient: gradient});
  }

  render() {
    this.state.gradients = gradientData.gradients;
             
    return(
        <div>
             <div className="fullscreen" style={{background: `linear-gradient(90deg, ${this.state.gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}}></div>

  
        </div>
        
      )       
                    
  }
}

export default withRouter(FullscreenBackground);