import  React , {useState} from 'react';
import styles from './Element.css';
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import {setOpacityAction} from "actions/setOpacityAction";
import { connect } from "react-redux";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import gradientData from '../Gradients/gradients.json';
import { setBackgroundColorAction } from "actions/setBackgroundColorAction";
import { downloadSVGElementAction } from "actions/downloadSVGElementAction";
import { deleteSVGElementAction } from "actions/deleteSVGElementAction";
import { setSelectedElementAction } from "actions/setSelectedElementAction";
import { setSelectedElementsAction } from "actions/setSelectedElementsAction";
import { motion } from "framer-motion"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {Button} from "@chakra-ui/core";
import { ChevronRight } from 'react-feather';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setBackgroundColorAction: (payload) => dispatch(setBackgroundColorAction(payload)),
  downloadSVGElementAction: () => dispatch(downloadSVGElementAction),
  setOpacityAction: (payload) => dispatch(setOpacityAction(payload)),
  deleteSVGElementAction: () => dispatch(deleteSVGElementAction),
  setSelectedElementAction: (payload) => dispatch(setSelectedElementAction(payload)),
  setSelectedElementsAction: (payload) => dispatch(setSelectedElementsAction(payload)),
});

class Element extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        svgHeight: 1100,
        svgWidth: 1200,
        width: 60,
        rect: null,
        opacitySliderValue: 8,
        value: 7,
        gradient: null,
        opacitySliderValue: 30.0,
        draw: null,
        gradient_color: "#897",
        isVisible: 1,
        colors: ["#F06", "#F01", "#891", "#710", "#A2F", "#40B"]
    };
  }
  componentDidMount() {
      if (this.props.selectedElement != null) {
          if (this.props.selectedElement.attr().opacity != null) {
            this.state.opacitySliderValue = this.props.selectedElement.attr().opacity * 100.0;

          }
      }
      else {
        // selected elem is null

      }

  }

  handleClick(gradient) {
    this.applyGradient(gradient);
  }

  downloadSVGElement() {
      if (this.props.selectedElement != null) {
        let svg_elem = this.props.selectedElement.svg();
      }

  }

  deleteSVGElement() {
      if (this.props.selectedElement != null && this.props.selectedElements != null) {
        this.props.selectedElement.selectize(false);
        let index = this.props.selectedElements.indexOf(this.props.selectedElement);
        this.props.selectedElements.splice(index, 1);
        this.props.selectedElement.remove();

        this.props.selectedElements.forEach((elem, index) => {
            elem.id("Element_" + index);
        });
        
    }

  }

  applyGradient(gradient_obj) {
    let gradient = this.props.draw.gradient('linear', (add) => {
      add.at(0, gradient_obj.color1);
      add.at(1, gradient_obj.color2);
    });
    this.props.selectedElement.fill(gradient);

  }
    
  changeBackgroundColor(color) {
    this.props.selectedElement.fill(color);
  }

  renderComponent(component_type) {
      switch(component_type) {
          case "gradients":
              this.props.history.push('/app/gradients');
              break;

        case "solid":
            this.props.history.push('/app/element-colors');
            break;
      }
  }

  onSliderChange = value => {
    this.setState({opacitySliderValue : value});

    if (this.props.selectedElement != null) {
        let opacity_val = value / 100;
        this.props.selectedElement.opacity(opacity_val)
    }
  };

  
  onSliderChange1 = value => {
    this.setState({
      value: value,
    });
  };
  

  render() {
    this.state.gradients = gradientData.gradients;

    var gradient_elems = this.state.gradients.map( (gradient, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="square" onClick={() => this.handleClick(gradient)} key={gradient.name}
        style={{width : "10vh", height: "10vh", backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.color1}, ${gradient.color2})` }} 
        
        />
        
      </motion.div>

    });

    var color_elems = this.state.colors.map( (color, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="square" onClick={() => this.changeBackgroundColor(color)} 
        style={{width : "10vh", height: "10vh", backgroundColor: `${color}` }} />
        
      </motion.div>

    });

    return(
      <div className="element">
        <h1>Element</h1>

        <div className={this.props.selectedElement ? '' : 'hidden'}>

          <div class="btns">
            <Button onClick={() => this.downloadSVGElement()} variant="solid" variantColor="purple">
              Download
            </Button>

            <Button onClick={() => this.deleteSVGElement()} variant="solid" variantColor="pink">
                <ion-icon name="trash" size="small"></ion-icon>
                Delete
            </Button>
          </div>

          <section className="slider">
              <h3>Opacity</h3>
              <article >
                  <Slider onChange={this.onSliderChange} value={this.state.opacitySliderValue} />
              </article>

          </section>

            <div className="heading">
                <h2>Gradients</h2>
                <ChevronRight className="icon" onClick={() => this.renderComponent("gradients")} />
            </div>
            
            <section className="gradients">
                  {gradient_elems}
            </section>

            <div className="heading">
                <h2>Solid</h2>
                <ChevronRight className="icon" onClick={() => this.renderComponent("solid")} />
            </div>
            <section className="gradients">
                  {color_elems}
            </section>

            <div id="gradient_svg"></div>
          
        </div>

        
        <div className={this.props.selectedElement ? 'hidden' : ''}>
          <p>Select an element to get started!</p>
        </div>

      </div>
      
    )
  }

   componentDidUpdate(prevProps, prevState, snapshot) {
     if (prevProps.selectedElement !== this.props.selectedElement) {
        this.state.opacitySliderValue = this.props.selectedElement.attr().opacity * 100;
     }
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Element);