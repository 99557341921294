import  React , {useState} from 'react';
import styles from './GradientPacks.css';
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import { connect } from "react-redux";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import gradientData from './gradient_packs.json';
import { motion } from "framer-motion"
import { ChromePicker } from 'react-color'

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  startAction: () => dispatch(startAction),
  stopAction: () => dispatch(stopAction),
  setBgAction: (payload) => dispatch(setBgAction(payload)),

});

class GradientPacks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        svgHeight: 1100,
        svgWidth: 1200,
        width: 60,
        rect: null,
        gradient: null,
        draw: null,
        gradient_color: "#897",
        isVisible: 1,
    };
  }
  componentDidMount() {

  }

  renderComponent(component_type) {
    switch(component_type) {
        case "back":
            // move back
            this.props.history.goBack();

            break;

      case "solid":
          break;
    }
}
  
  handleClick(gradient) {
    this.applyGradient(gradient);


  }
  
  applyGradient(gradient) {
    this.props.setBgAction(gradient);
  }


  render() {
    this.state.gradients = gradientData.gradients;
            
    var gradient_elems = this.state.gradients.map( (gradient, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="gradient" onClick={() => this.handleClick(gradient)} 
        style={{width : "10vh", height: "10vh", backgroundImage: `linear-gradient(${gradient.deg}, ${gradient.color1}, ${gradient.color2})` }} />
        
      </motion.div>

    });


    return(
      <div>
        <article className="heading">
          <h1>Gradient Packs</h1>
          <ion-icon name="chevron-back" size="large" onClick={() => this.renderComponent("back")}></ion-icon>
        </article>

          <section className="gradients">
                {gradient_elems}
          </section>

      </div>
      
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

}
}

export default connect(mapStateToProps, mapDispatchToProps)(GradientPacks);