import  React , {useState} from 'react';
import styles from './PatternBackgrounds.css';
import { setBgAction } from "actions/setBgAction";
import { setSVGBackgroundPatternAction } from "actions/setSVGBackgroundPatternAction";
import { ChevronLeft } from 'react-feather';
import { connect } from "react-redux";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setSVGBackgroundPatternAction: (payload) => dispatch(setSVGBackgroundPatternAction(payload)),

});

class PatternBackgrounds extends React.Component {
  constructor(props) {
    super(props);
    this.patternRef = React.createRef();
    this.state = {
        svgHeight: 1100,
        svgWidth: 1200,
        width: 60,
        svg_background: null,
    };
  }
  componentDidMount() {
    this.initPatternPreviews();


  }



  handleClick(gradient) {
    this.applyGradient(gradient);
  }
  
  applyGradient(gradient) {
    this.props.setBgAction(gradient);
  }

  initPatternPreviews() {
    let pattern_names = ["Escher Tiles", "Stripes", "Dots", "Checkerboard", "Diagonal Stripes Left", 
  "Diagonal Stripes Right"];
    
    for (let k = 0; k < pattern_names.length; k++) {
      let pattern_name = pattern_names[k];
      let string_id = String(k);
      let svg_container = document.createElement("div");
      svg_container.id = string_id;
      svg_container.className = "element_container";

      this.patternRef.current.appendChild(svg_container);

      let draw = SVG(string_id).size('100%', '100%');
      this.addSidebarElement(draw, pattern_name, this.patternRef.current);
    }
  }
  
  addSidebarElement(draw, pattern_name, elem) {
    draw.viewbox(0, 0, 97, 110);
    let element = draw.rect(80, 80).attr({id : pattern_name });
    
    var app = this;
    var click = function() {
        app.props.setSVGBackgroundPatternAction(pattern_name);
    }
      
    element.on('click', click);
  }



  render() {
    return(
      <div>
        <article className="heading">
          <h1>Pattern Backgrounds</h1>
          <ChevronLeft onClick={() => this.props.history.goBack()} />
        </article>

        <div class="grid" ref={this.patternRef}>
        </div>

      </div>
      
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

}
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternBackgrounds);