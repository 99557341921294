import React, {useState, useEffect} from 'react';

import Header from '../partials/Header';
import { Auth } from 'aws-amplify';
import { useHistory} from 'react-router-dom';

function ResetPassword() {
  const [isLoading, setLoading] = useState(false);
  const [error_msg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [code, setCode] = useState("");
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const sendResetEmail = event => {
    event.preventDefault();

    Auth.forgotPasswordSubmit(email, code, password)
    .then(data => {
    })
    .catch(err => console.log("error"));
    
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-2xl mx-auto text-center pb-8 md:pb-12 text-center">
                <h1 className="h1 mb-4">Reset your password</h1>
                <p className="text-xl text-gray-600 max-w-2xl">We've sent a code to your email, input the code below and your new password.</p>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="code">Confirmation Code <span className="text-red-600">*</span></label>
                      <input id="code" type="code" className="form-input w-full text-gray-800" 
                      placeholder="Enter code sent to your email" required 
                      onChange={e => setCode(e.target.value)} />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">Password <span className="text-red-600">*</span></label>
                      <input id="password" type="password" className="form-input w-full text-gray-800" 
                      placeholder="Enter your new password" required 
                      onChange={e => setPassword(e.target.value)} />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full button" onClick={event => {sendResetEmail(event)}}>Send reset link</button>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default ResetPassword;