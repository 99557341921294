import React, {useState, useEffect} from 'react';
import { useHistory} from 'react-router-dom';
import Header from '../partials/Header';
import { Auth } from 'aws-amplify';

function ResetPassword() {
  const [isLoading, setLoading] = useState(false);
  const [error_msg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [instruction_msg, setInstructionMsg] = useState("Enter the email address you used when you signed up for your account, and we’ll email you a code to reset your password.");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const sendResetEmail = event => {
    event.preventDefault();

    Auth.forgotPassword(email)
    .then(data => {
      setInstructionMsg("We've sent a code to your email, input the code below and your new password.");
      setIsConfirm(true)
      
    })
    .catch(err => console.log("error"));
    
  };

  const resetPassword = event => {
    event.preventDefault();
    
    Auth.forgotPasswordSubmit(email, code, password)
    .then(data => {
      history.push("/login");
    })
    .catch(err => console.log("error"));
    
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-2xl mx-auto text-center pb-8 md:pb-12 text-center">
                <h1 className="h1 mb-4">Reset your password</h1>
                <p className="text-xl text-gray-600 max-w-2xl">{instruction_msg}</p>
              </div>

              {/* Form */}
              <div className={isConfirm ? "hidden" : ""}>
                <div className="max-w-sm mx-auto">
                  <form>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                        <input id="email" type="email" className="form-input w-full text-gray-800" 
                        placeholder="Enter your email address" required 
                        onChange={e => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full button" onClick={event => {sendResetEmail(event)}}>Send reset code</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className={isConfirm ? "" : "hidden"}>

                <div className="max-w-sm mx-auto">
                  <form>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="code">Confirmation Code <span className="text-red-600">*</span></label>
                        <input id="code" type="code" className="form-input w-full text-gray-800" 
                        placeholder="Enter code sent to your email" required 
                        onChange={e => setCode(e.target.value)} />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">Password <span className="text-red-600">*</span></label>
                        <input id="password" type="password" className="form-input w-full text-gray-800" 
                        placeholder="Enter your new password" required 
                        onChange={e => setPassword(e.target.value)} />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">

                      <button className="btn w-full px-3 text-white bg-blue-600 hover:bg-blue-700 w-full button" onClick={event => {resetPassword(event)}}>Reset password</button>

                    </div>
                  </form>
                </div>
              </div>


            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default ResetPassword;