import  React , {useState} from 'react';
import styles from './Customize.css';
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import {setOpacityAction} from "actions/setOpacityAction";
import { connect } from "react-redux";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import gradientData from '../Gradients/gradient_collection_resource.json';
import triangleGradientData from '../Gradients/triangle_gradients.json';
import radialGradientData from '../Gradients/radial_gradients.json';
import { setBackgroundColorAction } from "actions/setBackgroundColorAction";
import { downloadSVGElementAction } from "actions/downloadSVGElementAction";
import { deleteSVGElementAction } from "actions/deleteSVGElementAction";
import { setSelectedElementAction } from "actions/setSelectedElementAction";
import { setSelectedElementsAction } from "actions/setSelectedElementsAction";
import { setSVGBackgroundColorAction } from "actions/setSVGBackgroundColorAction";
import { setSVGGradientBackgroundColorAction } from "actions/setSVGGradientBackgroundColorAction";
import { motion } from "framer-motion"
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ChromePicker } from 'react-color'
import {Button} from "@chakra-ui/core";
import { ChevronRight } from 'react-feather';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setBackgroundColorAction: (payload) => dispatch(setBackgroundColorAction(payload)),
  setOpacityAction: (payload) => dispatch(setOpacityAction(payload)),
  deleteSVGElementAction: () => dispatch(deleteSVGElementAction),
  setSelectedElementAction: (payload) => dispatch(setSelectedElementAction(payload)),
  setSVGBackgroundColorAction: (payload) => dispatch(setSVGBackgroundColorAction(payload)),
  setSVGGradientBackgroundColorAction: (payload) => dispatch(setSVGGradientBackgroundColorAction(payload)),

});

class Customize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        svgHeight: 1100,
        svgWidth: 1200,
        opacitySliderValue: 8,
        triangleRandomizationValue: 1,
        value: 7,
        gradient: null,
        opacitySliderValue: 30.0,
        draw: null,
        gradient_color: "#897",
        isVisible: 1,
        colors: ["#F06", "#F01", "#891", "#710"],
        svg_background: null,
        design: {},
        triangle_randomization_nums: [4, 12, 32, 62, 120],
        triangle_randomization_num: 12,
    };
  }
  componentDidMount() {
    let svg_background = SVG.get("background");

    // check if background rect already exists
    if (svg_background != null) {
      this.setState({svg_background: svg_background});
      let opacity = svg_background.attr("opacity");
      this.setState({opacitySliderValue : opacity});

    }



  }

  handleClick(gradient) {
    this.applyGradient(gradient);
  }

  downloadSVGElement() {
      if (this.props.selectedElement != null) {
        let svg_elem = this.props.selectedElement.svg();
      }
  }

  deleteSVGElement() {
      if (this.props.selectedElement != null && this.props.selectedElements != null) {
        this.props.selectedElement.selectize(false);
        let index = this.props.selectedElements.indexOf(this.props.selectedElement);
        this.props.selectedElements.splice(index, 1);
        this.props.selectedElement.remove();

        this.props.selectedElements.forEach((elem, index) => {
            elem.id("Element_" + index);
          });
    }
  }

  clearBackground() {
    let background = SVG.get("background");
    if (background != null) {
      background.remove();
    }
  }

  applyGradient(gradient) {

    this.setState({opacitySliderValue: 100, gradient: gradient});
    gradient.randomColorNum = this.state.triangle_randomization_num;
    this.props.setSVGGradientBackgroundColorAction(gradient);
  }
    
  changeBackgroundColor(color) {
    this.props.setSVGBackgroundColorAction(color);
  }

  renderComponent(component_type) {
      switch(component_type) {
          case "gradients":
              this.props.history.push('/app/gradients');
              break;

        case "solid":
            this.props.history.push('/app/background-colors');
            break;

        case "animated-backgrounds":
            this.props.history.push('/app/animated-backgrounds');
            break;
        case "pattern-backgrounds":
            this.props.history.push("/app/pattern-backgrounds");
      }
  }

  onSliderChange = value => {
    let marks_to_vals = {"1": 4, "2": 12, "3": 32, "4": 63, "5": 120};
    
    let gradient;
    if (this.state.gradient == null) {
      gradient = triangleGradientData.gradients[0];
      gradient.randomColorNum = marks_to_vals[value];
    }
    else {
      gradient = {color1: this.state.gradient.color1, color2: this.state.gradient.color2, randomColorNum: marks_to_vals[value], alteredColor: this.state.gradient.alteredColor, 
      codes: this.state.gradient.codes, deg: this.state.gradient.deg, name: this.state.gradient.name, };
    }

    this.setState({triangle_randomization_num: marks_to_vals[value], triangleRandomizationValue: value, gradient: gradient});

    this.props.setSVGGradientBackgroundColorAction(gradient);
  };
  
  onSliderChange1 = value => {
    this.setState({
      value: value,
    });
  };

  render() {
    if (this.props.design.designType == "triangles") {
      this.state.gradients = triangleGradientData.gradients;

      // var triangle_randomization_btns = this.state.triangle_randomization_nums.map( (num, i) => {
      //     return <div onClick={() => this.setState({triangle_randomization_num: num})} >
      //       <p>{num}</p>
      //     </div>
              
      // });
      
    }
    else if (this.props.design.designType == "radialGradient") {
      this.state.gradients = radialGradientData.gradients;
    }
    else {
      this.state.gradients = gradientData.gradients;
    }

    let gradient_elems;

    if (this.props.design.designType == "radialGradient") {
      gradient_elems = this.state.gradients.map( (gradient, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="square" onClick={() => this.handleClick(gradient)} key={gradient.name}
        style={{width : "10vh", height: "10vh", backgroundImage: `linear-gradient(45deg, ${gradient.color1} 0%, hsla(352, 96%, 45%, 0) 70%),
        linear-gradient(135deg, ${gradient.color2} 10%, hsla(253, 95%, 42%, 0) 80%),
        linear-gradient(225deg, ${gradient.color3} 10%, hsla(179, 91%, 45%, 0) 80%),
        linear-gradient(315deg, ${gradient.color4} 100%, hsla(119, 96%, 49%, 0) 70%)` }} />
        
      </motion.div>

    });
    }
    else {
      gradient_elems = this.state.gradients.map( (gradient, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="square" onClick={() => this.handleClick(gradient)} key={gradient.name}
        style={{width : "10vh", height: "10vh", backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.color1}, ${gradient.color2})` }} />
        
      </motion.div>

    });
    }


    var color_elems = this.state.colors.map( (color, i) => {
        return <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
              
      <div className="square" onClick={() => this.changeBackgroundColor(color)} 
        style={{width : "6vh", height: "6vh", backgroundColor: `${color}` }} />
        
      </motion.div>

    });

    return(
      <div>
        <h1>Customize</h1>
        <div className="btns">

        </div>

        {/* <section className="slider">
            <h3>Opacity</h3>
            <article>
               
            </article>
        </section> */}

        <ChromePicker className={this.state.svg_background ? '' : 'hidden'}/>

        <div className={this.state.design.designType == "triangles" ? 'mt-4' : 'hidden'}>
          <span className="block text-sm font-medium text-gray-700">Randomization Level</span>

          <Slider className="mb-8" style={{width: "92%", marginLeft:"0.2em"}} onChange={this.onSliderChange} value={this.state.triangleRandomizationValue} min={1} defaultValue={1} max={5} marks={{ 1: 1, 2: 2, 3:3, 4: 4, 5:5 }} step={null}/>

        </div>
        <div className="mt-4">
          <div className="heading">
                <h2>Gradient Backgrounds</h2>
                {/* <ChevronRight className="icon" onClick={() => this.renderComponent("gradients")} /> */}
          </div>
          
          <section className="gradients">
                {gradient_elems}
          </section>
        </div>

          
          {/* <div className="heading">
              <h2>Patterns</h2>
              <ChevronRight className="icon" onClick={() => this.renderComponent("pattern-backgrounds")} />
          </div> */}

          {/* <div className="heading">
              <h2>Solid Backgrounds</h2>
              <ChevronRight className="icon" onClick={() => this.renderComponent("solid")} />
          </div>
          <section className="gradients">
                {color_elems}
          </section>


          <div className="heading">
              <h2>Animated Backgrounds</h2>
              <ChevronRight className="icon" onClick={() => this.renderComponent("animated-backgrounds")} />
          </div>
          <section className="gradients">

          </section> */}

      </div>
      
    )
  }

   componentDidUpdate(prevProps, prevState, snapshot) {
     if (this.state.background) {
        if (prevProps.selectedElement !== this.props.selectedElement) {
          this.state.opacitySliderValue = this.props.selectedElement.attr().opacity * 100;
      }
       
     }

     if (prevProps.design !== this.props.design) {
      this.setState({design: this.props.design});
    }

   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customize);