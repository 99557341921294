import React, { useState } from "react";
import styles from "./TemplateDirectory.css";
import { connect } from "react-redux";
import {setDirectoryTemplatesAction} from "actions/setDirectoryTemplatesAction";
import {setOpacityAction} from "actions/setOpacityAction";
import {setUserAction} from "actions/setUserAction";
import {
  Link,
  withRouter,
} from "react-router-dom";
import axios from "axios";
import Particles from "react-tsparticles";
import {API} from "aws-amplify";
import { graphqlOperation } from 'aws-amplify';
import { listTemplates } from 'graphql/queries';
import canvg from 'canvg';
import {Button, Menu, MenuButton, Box, Flex, Badge, MenuList, MenuItem, Skeleton, Image, Heading,
  Link as ChakraLink,

} from "@chakra-ui/core";
import { Auth } from 'aws-amplify';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setDirectoryTemplatesAction: (payload) => dispatch(setDirectoryTemplatesAction(payload)),
  setOpacityAction: (payload) => dispatch(setOpacityAction(payload)),
  setUserAction: (payload) => dispatch(setUserAction(payload)),

});

class TemplateDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      animated_containers: [],
    };
  }
  
  componentDidMount() {
    if (this.props.directoryTemplates) {
      this.createTemplateElems(this.props.directoryTemplates);
    }
    else {
      this.getTemplates();
    }
    
  }


  resizeTemplates() {
    setTimeout(
      ()=>{window.dispatchEvent(new Event('resize'));},
      0.15
      );
  }

  
  async getTemplates() {
    const designs_response = await API.graphql({
      query: listTemplates,
      authMode: 'API_KEY'
    });
    let designs = designs_response.data.listTemplates.items;
    if (designs_response && designs_response.data) {
      this.setState({isLoading: false, templates: designs});
      this.initTemplateElems();
      this.props.setDirectoryTemplatesAction(designs);

    }
  }

  initTemplateElems() {
    let designs = this.state.templates;
    for (var i = 0; i < designs.length; i++) {
      var design = designs[i];
      let string_id = String(design.id + i);
      let designs_cont = document.getElementById("designs");

      if (design.designType == "svg") {

        let svg_container = document.createElement("div");

        svg_container.id = string_id;
        svg_container.className = "svg_container";

        // let designs_cont = document.getElementById("designs");

        // var draw = SVG(string_id).size("100%", "100%");
        // draw.viewbox(0, 0, 497, 480);
        // draw.svg(design.body);
        // designs_cont.appendChild(svg_container);

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let design_str = JSON.parse(design.body);


        let canvas_str = `<svg width='1000' height='800'>${design_str.defs} ${design_str.svg_str}</svg>`;

        let v = canvg.fromString(ctx, canvas_str);
        v.start();
        var img = canvas.toDataURL("image/png");
        let image_elem = document.createElement("img");

        image_elem.src = img;
        design.image_src = img;

        this.state.images.push(image_elem);
      }
      else if (design.designType == "animated") {

        let body = JSON.parse(design.body);
        //body.interactivity.events.onClick.enable = "false";
        //body.interactivity.events.onHover.enable = "false";
        design.body = body;
        if (design.body.backgroundMode) {
          design.body.backgroundMode.enable = false;
        }
        design.parsedBody = body;
        let new_container = {};
        design.container = new_container
      }

      else if (design.designType == "gradient" || design.designType == "animated_gradient") {
        let body = JSON.parse(design.body);
        design.body = body;
      }

    }
    let elems = this.createTemplateElems(designs);
    this.setState({design_elems: elems });
  }



  fetchTemplates() {
    var app = this;
    axios
      .get(
        "https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/templates"
      )
      .then((response) => {
        let templates = response.data;
        if (templates.length == 0) {
          this.setState({ emptyState: true, isLoading: false });
        } else {
          for (var i = 0; i < templates.length; i++) {
            var template = templates[i];
            let image_src = template.image;

            let body = JSON.parse(template.body);
            template.body = body;
            if (template.body.backgroundMode) {
              template.body.backgroundMode.enable = false;
            }
          }

          this.setState({template_designs: templates});
          this.props.setDirectoryTemplatesAction(templates);
          this.createTemplateElems(templates);
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  createTemplateElems(templates) {
    

    let template_elems = templates.map((design, i) => {
      let element;      
      this.resizeTemplates();

      const variants = {
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0,  scale: 0 },
      }


      if (design.designType == "animated") {
        let new_container = {};
        this.state.animated_containers.push(new_container);

        element = <Box
                    maxW="sm"
                    borderWidth="1px"
                    rounded="lg"
                    overflow="hidden"
                    className="design"
                    data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
                    >

                    <Flex align="end" justify="end">

                    <Menu justifyContent="right">
                        <MenuButton as={Button} leftIcon="drag-handle">
                        </MenuButton>
                        <MenuList>
                        <Link to={"/app?template=" + design.id}><MenuItem>Create Design</MenuItem></Link>
                          {/* <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem> */}
                          {/* <MenuItem>Create a Copy</MenuItem> */}
                        </MenuList>
                    </Menu>

                    </Flex>
                    <Link to={"/app?template=" + design.id}>

                      <div>

                      <Particles className="gradient_preview" options={design.parsedBody} resize={true} container={design.container} />

                      <Box p="6">
                        <Box d="flex" alignItems="baseline">
                          <Badge rounded="full" px="2" variantColor="purple">
                            Animated
                          </Badge>
                        </Box>

                        <Box
                          mt="1"
                          fontWeight="semibold"
                          as="h4"
                          lineHeight="tight"
                          isTruncated
                        >
                          design
                        </Box>

                        <Box as="span" color="gray.600" fontSize="sm">
                          {design.createdAt}
                        </Box>
                      </Box>

                      </div>
                    </Link>
                    </Box>
      }
      
      else if (design.designType == "svg") {

        element = 
            <Box
                maxW="sm"
                borderWidth="1px"
                rounded="lg"
                overflow="hidden"
                className="design"
              >
  
            <div>
            <Flex align="end" justify="end">
  
              <Menu justifyContent="right">
                    <MenuButton as={Button} leftIcon="drag-handle">
                    </MenuButton>
                    <MenuList>
                        <Link to={"/app?template=" + design.id}><MenuItem>Create Design</MenuItem></Link>
                          {/* <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem> */}
                          {/* <MenuItem>Create a Copy</MenuItem> */}
                  </MenuList>
              </Menu>
  
            </Flex>
            
            <Link to={"/app?template=" + design.id}>
  
                <Image src={design.image_src} alt={this.state.imageAlt} />
                <Box p="6">
                  <Box d="flex" alignItems="baseline">
                    <Badge rounded="full" px="2" variantColor="teal">
                      SVG
                    </Badge>
                  </Box>
  
                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                  >
                    {design.title}
                  </Box>
  
                  <Box as="span" color="gray.600" fontSize="sm">
                    {design.createdAt}
                  </Box>
                </Box>
            </Link>
            </div>
            </Box>
  
      }

      
      else if (design.designType == "svg_overlay") {

        element = 
            <Box
                maxW="sm"
                borderWidth="1px"
                rounded="lg"
                overflow="hidden"
                className="design"
              >
  
            <div>
            <Flex align="end" justify="end">
  
              <Menu justifyContent="right">
                    <MenuButton as={Button} leftIcon="drag-handle">
                    </MenuButton>
                    <MenuList>
                        <Link to={"/app?template=" + design.id}><MenuItem>Create Design</MenuItem></Link>
                          {/* <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem> */}
                          {/* <MenuItem>Create a Copy</MenuItem> */}
                        </MenuList>
              </Menu>
  
            </Flex>
            
            <Link to={"/app?template=" + design.id}>
  
                <Image src={design.image_src} alt={this.state.imageAlt} />
                <Box p="6">
                  <Box d="flex" alignItems="baseline">
                    <Badge rounded="full" px="2" variantColor="teal">
                      SVG
                    </Badge>
                  </Box>
  
                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                  >
                    {design.title}
                  </Box>
  
                  <Box as="span" color="gray.600" fontSize="sm">
                    {design.createdAt}
                  </Box>
                </Box>
            </Link>
            </div>
            </Box>
  
      }

      else if (design.designType == "gradient") {
        element = 
            <Box
                  maxW="sm"
                  borderWidth="1px"
                  rounded="lg"
                  overflow="hidden"
                  className="design"
                  data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
                >
    
              <Flex align="end" justify="end">
    
                <Menu justifyContent="right">
                      <MenuButton as={Button} leftIcon="drag-handle">
                      </MenuButton>
                      <MenuList>
                        <Link to={"/app?template=" + design.id}><MenuItem>Create Design</MenuItem></Link>
                          {/* <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem> */}
                          {/* <MenuItem>Create a Copy</MenuItem> */}
                        </MenuList>
                </Menu>
    
              </Flex>
            
              <div>
              <Link to={"/app?template=" + design.id}>

                <div className="gradient_preview" style={{background: `linear-gradient(${design.body.deg}deg, ${design.body.color1} 0%, ${design.body.color2} 100%)`}}></div>

                  <Box p="6">
                    <Box d="flex" alignItems="baseline">
                      <Badge rounded="full" px="2" variantColor="purple">
                        Gradient
                      </Badge>
                    </Box>
    
                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      isTruncated
                    >
                      design
                    </Box>
    
                    <Box as="span" color="gray.600" fontSize="sm">
                      {design.createdAt}
                    </Box>
                  </Box>

              </Link>
              </div>
            
              </Box>
      }

      
      else if (design.designType == "animated_gradient") {
        element = 
            <Box
                  maxW="sm"
                  borderWidth="1px"
                  rounded="lg"
                  overflow="hidden"
                  className="design"
                  data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
                >
    
              <Flex align="end" justify="end">
    
                <Menu justifyContent="right">
                      <MenuButton as={Button} leftIcon="drag-handle">
                      </MenuButton>
                      <MenuList>
                        <Link to={"/app?template=" + design.id}><MenuItem>Create Design</MenuItem></Link>
                          {/* <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem> */}
                          {/* <MenuItem>Create a Copy</MenuItem> */}
                        </MenuList>
                </Menu>
    
              </Flex>
              <Link to={"/app?template=" + design.id}>

              <div>

                <div className="gradient_preview animated_gradient" style={{background: `linear-gradient(${design.body.deg}, ${design.body.color1} 0%, ${design.body.color2} 100%)`}}></div>

                  <Box p="6">
                    <Box d="flex" alignItems="baseline">
                      <Badge rounded="full" px="2" variantColor="blue">
                        Animated Gradient
                      </Badge>
                    </Box>
    
                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      isTruncated
                    >
                      design
                    </Box>
    
                    <Box as="span" color="gray.600" fontSize="sm">
                      {design.createdAt}
                    </Box>
                  </Box>

              </div>
              </Link>
              </Box>
      }

      else if (design.designType == "triangles") {
        element = 
            <Box
                  maxW="sm"
                  borderWidth="1px"
                  rounded="lg"
                  overflow="hidden"
                  className="design"
                  data-aos="zoom-y-out" data-aos-delay={(i % 3) * 50} data-aos-anchor-placement="top-bottom"
                >
    
              <Flex align="end" justify="end">
    
                <Menu justifyContent="right">
                      <MenuButton as={Button} leftIcon="drag-handle">
                      </MenuButton>
                      <MenuList>
                        <Link to={"/app?template=" + design.id}><MenuItem>Create Design</MenuItem></Link>
                          {/* <MenuItem onClick={() => this.deleteDesign(design, i)}> Delete</MenuItem> */}
                          {/* <MenuItem>Create a Copy</MenuItem> */}
                        </MenuList>
                </Menu>
    
              </Flex>
            
              <div>
                <Link to={"/app?design=" + design.id}>

                  <Box p="6">
                    <Box d="flex" alignItems="baseline">
                      <Badge rounded="full" px="2" variantColor="red">
                        Triangles
                      </Badge>
                    </Box>
    
                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      isTruncated
                    >
                      design
                    </Box>
    
                    <Box as="span" color="gray.600" fontSize="sm">
                      {design.createdAt}
                    </Box>
                  </Box>
              </Link>
              </div>
              </Box>
      }

      return element;
    });

    let anim_designs = templates.filter(design => design.designType == "animated");
    for (let j = 0; j < anim_designs.length; j++) {
        let container = anim_designs[j].container;
        if (container.current) {
          container.current.refresh();
        }
    }
    window.dispatchEvent(new Event('resize'));

    this.setState({ template_elems: template_elems, isLoading: false });
    
    return template_elems;
  }

  handleError(error) {
    
    if (error.response) {
      
      if (error.response.status === 403) {
        this.props.history.push("/login");
      }

    } else if (error.request) {
      // Request made, no response from server was received
    } else {
    }


  }

  handleChange = (event) => {};

  render() {
    var skeleton_boxes = [];
    for (var i = 0; i < 10; i++) {
      skeleton_boxes.push(<Box
        maxW="sm"
        rounded="lg"
        overflow="hidden"
        className="design"
      >

    <div>

    
    <Skeleton key={i} isLoaded={!this.state.isLoading} className="skeleton" />

    </div>
    </Box>);
    }

    return (
      <div>
        <h1>Templates</h1>
        <p className="text-xl text-gray-600">
          Click on a template to get started.                    
        </p>

        <div className="templates">

          <div className="designs" id={this.state.isLoading ? "" : "nodisplay"}>
            {skeleton_boxes}
          </div>

          {this.state.template_elems}
          <Box>
                    <Heading fontSize="xl" marginTop="1em">Get 100+ Premium Backgrounds</Heading>
                    <p>Want even more backgrounds? Discover <ChakraLink color="teal.500" href="https://www.isotopeui.com/?ref=wbmapp"> 
                    more backgrounds here</ChakraLink>, perfect for commercial and personal projects
                     
                    </p>

                  </Box>

        </div>        

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TemplateDirectory));