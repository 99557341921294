/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:072e91c2-09d7-47c6-8893-faf6e4484be5",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_lWFpxyGjv",
    "aws_user_pools_web_client_id": "6hdngli26fd7a6v7int2vtuquj",
    "oauth": {
        "domain": "inixiawbm42173fd8-42173fd8-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/dashboard/,https://app.websitebackgroundmaker.com/dashboard/",
        "redirectSignOut": "http://localhost:3000/,https://app.websitebackgroundmaker.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://eibafznwp5bdtiois7blxikj3q.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-p65tn2evwfa7ngmvmonlwltzfe",
    "aws_cloud_logic_custom": [
        {
            "name": "inixiawbmrestapi",
            "endpoint": "https://8x1thzeuth.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "inixiawbm1b91875a26524342af79cb0e031efdc585630-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
