import React, { useState } from "react";
import styles from "./Dashboard.css";
import SVG from "svg.js";
import { motion } from "framer-motion";
import {
  Route,
  Switch,
  Link,
  withRouter,
} from "react-router-dom";
import DashboardHeaderNav from "components/DashboardHeaderNav/DashboardHeaderNav";
import Designs from "components/Designs/Designs";
import DashboardSidebar from "components/DashboardSidebar/DashboardSidebar";
import Account from "components/Account/Account";
import BrowseGradients from "components/BrowseGradients/BrowseGradients";
import GradientPreview from "components/GradientPreview/GradientPreview";
import TemplateDirectory from "components/TemplateDirectory/TemplateDirectory";
import BrowseBackgrounds from "components/BrowseBackgrounds/BrowseBackgrounds";
import {GalleryAndLightbox} from "components/BrowseBackgrounds/BrowseBackgrounds"
import {Hub, Auth} from "aws-amplify";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    var app = this;
    // Hub.listen('auth', ({ payload: { event, data } }) => {
    //   switch (event) {
    //     case 'signIn':
    //       console.log("HUB sign in event fired hub")
    //       break;
    //     case 'cognitoHostedUI':
    //       console.log("HUB cognito ui event fired hub")
    //       break;
    //     case 'signOut':
    //       console.log("HUB sign out event fired hub")

    //       this.setState({user: null});
    //       break;
    //     case 'signIn_failure':
    //       console.log("HUB sign in failure fired hub")
    //       break;
    //     case 'cognitoHostedUI_failure':
    //       console.log('HUB Sign in failure', data);
    //       break;
    //   }
    // });
    if (!this.props.user) {
      this.getUser().then(userData => {
       //  this.setState({userData: userData}); 
    //   //  this.props.setUserAction(userData);
  
    

    // //   app.fetchDesigns();

   }).catch(() => {});
  
  }
  }

    getUser() {
      return Auth.currentAuthenticatedUser()
        .then(userData => userData)
        .catch(() => {
          this.props.history.push("/get-started")
      });
    }


  handleChange = (event) => {

  };

  render() {

    return (
      <div>
          <DashboardHeaderNav></DashboardHeaderNav>
            <DashboardSidebar></DashboardSidebar>

            <div className="dashboard_right"> 
                <Switch>
                    <Route path="/" component={Designs} exact />
                    <Route path="/dashboard" component={Designs} exact />
                    <Route path="/dashboard/designs" component={Designs} />
                    <Route path="/dashboard/templates" component={TemplateDirectory} />
                    <Route path="/dashboard/account" component={Account} /> 
                    <Route path="/dashboard/browse-gradients" component={BrowseGradients} />
                    <Route path="/dashboard/gradient-preview/:id" component={GradientPreview} />
                    <Route path="/dashboard/browse-backgrounds/" component={BrowseBackgrounds} />
                    <Route path='/dashboard/lbox/:id' component={GalleryAndLightbox} />

                </Switch>

            </div>

      </div>

    );
  }
}

export default withRouter(Dashboard);
