import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

function GetStarted() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pb-20">

          {/* Page header */}
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1">Get Started</h1>
            <h2 className="h2 text-gray-800">Choose a background type to begin.</h2>
          </div>

          {/* Section tags */}
          {/* <div className="border-b border-gray-300 pb-4 mb-12">
            <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
              <li className="mx-5 my-1">
                <a className="text-blue-600" href="#0">All</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Tutorials</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Tips & Tricks</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Templates</a>
              </li>
              <li className="mx-5 my-1">
                <a className="text-gray-800 hover:underline" href="#0">Guides</a>
              </li>
            </ul>
          </div> */}

          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none">

            {/* Articles container */}
            <div className="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">

              {/* 1st article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out">
                <header>
                  <Link to="/app?designType=svgWavesAnimated" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../images/svg_waves.PNG')} width="352" height="198" alt="An SVG Overlay background, containing a wave SVG on an
                      animated particle background" />
                    </figure>
                  </Link>
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app?designType=svgWavesAnimated" className="hover:underline">SVG Wave Background</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Waves on a background of your choice, great for landings!</p>

              </article>

              {/* 2nd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
                <header>
                  <Link to="/app?designType=triangle" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../images/triangle_background.PNG')} width="352" height="198" alt="Triangle pattern background" />
                    </figure>
                  </Link>
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app/?designType=triangle" className="hover:underline">Triangle Background</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A popular option, consisting of a triangle background, displayed as an SVG graphic.</p>
              </article>

              {/* 3rd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
                <header>
                  <Link to="/app?template=3d31ff20-569c-40f3-ab43-fda25b3bf700" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../images/particle_background.PNG')} width="352" height="198" alt="Particles on a gradient background" 
                      />
                    </figure>
                  </Link>
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app?template=3d31ff20-569c-40f3-ab43-fda25b3bf700" className="hover:underline">Animated Particles</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Animated particles on gradient backgrounds, with over 50 templates and counting!</p>

              </article>

              {/* 4th article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-anchor-placement="top-bottom">
                <header>
                  <Link to="/gradient-templates" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <div className="absolute inset-0 w-full h-full object-cover transform scale-105 preview_gradient hover:-translate-y-1 transition duration-700 ease-out"
                      width="352" height="198" />
                    </figure>
                  </Link>
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/gradient-templates" className="hover:underline">Gradient</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A CSS gradient, with over 60 to choose from!</p>

              </article>

              {/* 5th article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150" data-aos-anchor-placement="top-bottom">
                <header>
                  <Link to="/app?template=939915de-e7b7-4e6d-8920-9fabb96bce93" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../images/constellations.PNG')} width="352" height="198" alt="A group of constellations on a dark background" />
                    </figure>
                  </Link>
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app?template=939915de-e7b7-4e6d-8920-9fabb96bce93" className="hover:underline">Constellations</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Animated constellations on a background of your choice</p>
              </article>

              {/* 6th article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300" data-aos-anchor-placement="top-bottom">
                <header>
                  <Link to="/app?designType=animatedGradient" className="block mb-6">
                  <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <div className="absolute inset-0 w-full h-full object-cover transform scale-105 gradient_two hover:-translate-y-1 transition duration-700 ease-out"
                      width="352" height="198" />
                    </figure>
                  </Link>
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app?designType=animatedGradient" className="hover:underline">Animated Gradient</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A slight variant on the Gradient, adding animated functionality via CSS.</p>

              </article>


                <article className="flex flex-col h-full" data-aos="zoom-y-out">
                <header>
                  <Link to="/app?designType=svgWaves" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../images/svg_gradient_wave.PNG')} width="352" height="198" alt="An SVG Overlay background, containing a wave SVG on a
                      gradient background" />
                    </figure>
                  </Link>
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app?designType=svgWaves" className="hover:underline">SVG Wave + Gradient</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Waves on a gradient background of your choice</p>

              </article>



              {/* 8th article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
                <header>
                  <Link to="/app?designType=radialGradient" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 
                      transition duration-700 ease-out" src={require('../../images/radialgradient.jpg')} width="352" height="198" alt="News 08" />
                    </figure>
                  </Link>
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app?designType=radialGradient" className="hover:underline">Radial Gradients</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A blurred gradient effect using radial gradients.</p>

              </article>

              {/* 7th article */}
              {/* <article className="flex flex-col h-full" data-aos="zoom-y-out">
                <header>
                  <Link to="/app?designType=nonRectangularHeaders" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={require('../../images/tutorial-07.jpg')} width="352" height="198" alt="Non-rectangular header background" />
                    </figure>
                  </Link>
                  <div className="mb-3">
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app?designType=nonRectangularHeaders" className="hover:underline">Non-Rectangular Headers</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">Non-rectangular headers with CSS.</p>

              </article>
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
                <header>
                  <Link to="/app" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" src={require('../../images/tutorial-08.jpg')} width="352" height="198" alt="News 08" />
                    </figure>
                  </Link>
                  <div className="mb-3">

                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
                    <Link to="/app" className="hover:underline">Duotone Images</Link>
                  </h3>
                </header>
                <p className="text-gray-600 flex-grow">A duotone landing image, using a cool duotone effect.</p>

              </article> */}
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default GetStarted;