export default (state, action) => {
  switch (action.type) {
    case "rotate":
      return {
        rotating: action.payload,
      };
    case "bgChange":
      return {
        ...state,
        bgColor: action.payload,
      };
    case "addElement":
      return {
        ...state,
        element: action.payload,
      };
    case "backgroundColorChange":
      return {
        ...state,
        backgroundColor: action.payload,
      };
    case "downloadSVGElement":
      return {
        ...state,
        downloadSVGElement: true,
      };
    case "deleteSVGElement":
      return {
        ...state,
        deleteSVGElement: true,
      };
    case "setSelectedElement":
      return {
        ...state,
        selectedElement: action.payload,
      };

    case "setSelectedElements":
      return {
        ...state,
        selectedElements: action.payload,
      };
    case "setOpacity":
      return {
        ...state,
        opacity: action.payload,
      };

    case "setSVGBackgroundColor":
      return {
        ...state,
        svgBackgroundColor: action.payload,
      };

    case "setSVGGradientBackgroundColor":
      return {
        ...state,
        svgGradientBackgroundColor: action.payload,
      };
    case "setSVGBackgroundPattern":
      return {
        ...state,
        backgroundPatternName: action.payload,
      };
    case "setDrawSVG":
      return {
        ...state,
        draw: action.payload,
      };
    case "svgGroupChange":
      return {
        ...state,
        group: action.payload,
      };

    case "animatedBackgroundChange":
      return {
        ...state,
        animatedBackground: action.payload,
      };

    case "designChange":
      return {
          ...state, 
          design: action.payload,
        }
    case "svgBackgroundTemplateChange":
      return {
        ...state, 
        svgBackgroundTemplate: action.payload,
    }
    case "loggedOutChange":
      return {
          ...state, 
          loggedOut: action.payload,
    }
    case "setUser":
        return {
          ...state, 
          user: action.payload,
    }
    case "templatesChange":
        return {
          ...state, 
          templates: action.payload,
        }
    case "setDirectoryTemplates":
        return {
          ...state, 
          directoryTemplates: action.payload,
    }
    case "setDesigns":
        return {
          ...state, 
          designs: action.payload,
    }
    default:
      return state;
  }
};
