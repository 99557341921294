import  React , {useState, useContext} from 'react';
import styles from './Home.css';
import { connect } from "react-redux";
import { startAction } from "actions/startAction";
import { stopAction } from "actions/stopAction";
import { setBgAction } from "actions/setBgAction";
import { setLoggedOutAction } from "actions/setLoggedOutAction";
import { setUserAction } from "actions/setUserAction";
import Dashboard from "components/Dashboard/Dashboard";
import  SVG  from 'svg.js'
import 'svg.draggable.js'
import 'svg.select.js'
import 'svg.resize.js'
import Landing from "pages/Landing";
import { useToasts } from 'react-toast-notifications'
import {Auth} from "aws-amplify";
import SignIn from "pages/SignIn";

export function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  startAction: () => dispatch(startAction),
  stopAction: () => dispatch(stopAction),  
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setLoggedOutAction: (payload) => dispatch(setLoggedOutAction(payload)),
  setUserAction: (payload) => dispatch(setUserAction(payload)),

});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gradient: null,
      draw: null,
      gradient_color: "#897",
      selectedElement: null,
      selectedElements: [],
      colors: ["#f06", "#f03", "#f09"],
      loggedIn: false,
      isAuth: false,

    };
  }
  componentDidMount() {
    var app = this;
    
    let isLoggedInLocalStorage = localStorage.getItem("inixiaLoggedIn");


    if (this.props.loggedOut == true) {
        this.props.addToast('Logged out successfully', { appearance: 'success' });
        this.props.setLoggedOutAction(false);
    }

    this.getUser().then(userData => {
      if (userData) {
        this.setState({loggedIn: true, userData: userData}); 
        this.props.setUserAction(userData);
      }

     }).catch(() => {

     });

  }

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {
        this.props.history.push("/get-started")
    });
  }

  drawLandingSVGGraphics() {
    this.state.draw = SVG("svg_home_animation").size('100%', '100%');

    let gradient = this.state.draw.gradient('linear', (add) => {
      add.at(0, "#F06")
      add.at(1, "#A3F");
    });

    let gradient2 = this.state.draw.gradient('linear', (add) => {
      add.at(0, "#A06")
      add.at(1, "#B1F");
    });

    let path = this.state.draw.path('M0,256L48,229.3C96,203,192,149,288,154.7C384,160,480,224,576,218.7C672,213,768,139,864,128C960,117,1056,171,1152,197.3C1248,224,1344,224,1392,224L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z')
    .fill(gradient).draggable().selectize().resize();

    this.state.draw.path('M47.5,-8.7C56.9,13.4,56.6,45.5,41.5,56.2C26.4,66.9,-3.4,56.4,-26.7,39C-50,21.5,-66.7,-2.8,-61.2,-19.6C-55.6,-36.5,-27.8,-45.9,-4.4,-44.5C19.1,-43,38.1,-30.8,47.5,-8.7Z')
    .fill(gradient).opacity(1.0).draggable();
    
  }

  render() {

    // var color_elems = this.state.colors.map( (color, i) => {
    //   return <motion.div
    //   initial={{ scale: 0 }}
    //   animate={{ scale: 1 }}
    //   transition={{
    //     type: "spring",
    //     stiffness: 260,
    //     damping: 20
    //   }}
    // >
            
    // <div className="square" onClick={() => this.changeBackgroundColor(color)} 
    //   style={{width : "10vh", height: "10vh", backgroundColor: `${color}` }} />
      
    // </motion.div>

    // });
    let renderedComponents;
    // if (this.state.loggedIn) {
    //   renderedComponents = <Dashboard></Dashboard>
    // }
    // else {
      if (this.state.loggedIn) {
        renderedComponents = <Dashboard></Dashboard>
      }
      else {
        renderedComponents = <SignIn></SignIn>

      }
  //       renderedComponents = <div class="home">
  //       <NavigationBar></NavigationBar>

  //       <div id="container">
  //         <div id="svg_home_animation">

  //         </div>            
  //         <div id="text">

  //           <section class="landing_hero_left">
  //             <h1>Create SVG backgrounds in seconds</h1>
  //               <p>kroma makes it easy to create backgrounds and SVG elements. 
  //                 Try it now for free!
  //               </p>          

  //               <section class="btns">
  //                   <Link to="/app/">
  //                   <Button variantColor="pink" variant="solid">
  //                     Get Started
  //                   </Button>
  //                   </Link>

  //                   <Button variantColor="pink" variant="solid">
  //                     Learn More 👀 
  //                   </Button>
                    
  //               </section>  
  //           </section>

  //         </div>
  //       </div>

  //       <section>
  //         <h1 className="centre">Make SVG backgrounds like these 👀 </h1>
          
  //         <div class="gradients">

  //         </div>
  //       </section>

  //         <section class="hero">
  //           <section class="hero_lhs">
  //             <h1>Create SVG Blobs, Waves, and More</h1>
  //             <p>Create and export a huge selection of elements from waves to blobs. Add gradients and patterns to your elements, 
  //               and export them individually as SVGs or include them in your backgrounds.
  //             </p>
  //           </section>
  //           <section class="hero_rhs">
  //           <p></p>
  //           </section>
  //         </section>

  //         <section class="hero">
  //           <section class="hero_lhs">
  //             <h1>Export Individual SVG Elements</h1>
  //             <p>Create randomized blobs using the built-in blob generator.</p>
  //           </section>

  //           <section class="hero_rhs">
  //             <p>rhs</p>

  //           </section>
  //         </section>

          
  //         <section class="hero">
  //           <section class="hero_lhs">
  //             <h1>Over 50 Templates</h1>
  //             <p>If you need inspiration, you can also choose from a selection of templates.</p>
  //           </section>

  //           <section class="hero_rhs">
  //             <p>rhs</p>
  //           </section>

  //         </section>

  //   <section class="pricing">
  //     <h1>Pricing</h1>
  //     <section>

  //     </section>
  //   </section>


  // </div>

    //}
    return(
      <div>
      {renderedComponents}

      </div>
    )
  }
}

export default withToast(connect(mapStateToProps, mapDispatchToProps)(Home));