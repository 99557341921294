import  React , {useState} from 'react';
import styles from './EditorHeaderNav.css';
import { connect } from "react-redux";
import { setBgAction } from "actions/setBgAction";
import { BrowserRouter, Route, Switch, Link, withRouter} from 'react-router-dom';
import { deleteSVGElementAction } from "actions/deleteSVGElementAction";
import {downloadSVGElementAction} from "actions/downloadSVGElementAction";
import {setUserAction} from "actions/setUserAction";
import {Button, Box, Input} from "@chakra-ui/core";
import { IoMdTrash } from "react-icons/io";
import {authMiddleware} from "utils/authentication";
import firebase from "firebase";
import {withToast} from "components/Home/Home"
import {ExportDrawer} from "components/ExportDrawer/ExportDrawer";
import {Maximize2} from "react-feather";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";
import axios from 'axios';
import queryString from 'query-string'
import Amplify, { Auth, API, Storage } from 'aws-amplify';
import { createTodo, createTemplate, updateTemplate, deleteTemplate } from 'graphql/mutations';
import { updateTodo } from 'graphql/mutations';
import { graphqlOperation } from 'aws-amplify';
import { listTodos } from 'graphql/queries';
import { v4 as uuidv4 } from 'uuid';
import Canvg from "canvg";
import SVG from "svg.js";

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  deleteSVGElementAction: () => dispatch(deleteSVGElementAction),
  downloadSVGElementAction: (payload) => dispatch(downloadSVGElementAction(payload)),
  setUserAction: (payload) => dispatch(setUserAction(payload)),

});

class EditorHeaderNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewDesign: true,
      design: {designType: "animated"},
      designId: null,
      isSaving: false,
      signedIn: false,
      user: {},
      userSub: null,
      templateId: null,
      userGroups: [],
      isSavingNewTemplate: false,
      isSavingTemplate: false,
    };
  }

  deleteSVGElement() {
    if (this.props.selectedElement != null && this.props.selectedElements != null) {
      this.props.selectedElement.selectize(false);
      let index = this.props.selectedElements.indexOf(this.props.selectedElement);
      this.props.selectedElements.splice(index, 1);
      this.props.selectedElement.remove();

      this.props.selectedElements.forEach((elem, index) => {
          elem.id("Element_" + index);
        });
    }

  }

  downloadSVG() {
    //this.props.downloadSVGElementAction(true);
    // console.log("downloading element");
    this.deselectAllElements();

    // SVG DRAWING
    let svg_str = this.props.draw.svg();

    // EXPORT GROUP
    // let group = this.props.draw.group("main");
    // console.log("group ", group);

    const a = document.createElement('a');
    const e = new MouseEvent('click');

  
    a.download = 'download.svg';
    a.href = 'data:text/html;base64,' + svg_str;
    a.dispatchEvent(e);
  }

  onClick() {
    if (this.state.signedIn) {
      
      let design = this.props.design;
      this.setState({isSaving: true});
      if (design.designType == "svg") {
        this.saveSVG();
      }
      else if (design.designType == "svg_overlay") {
        this.saveSVGOverlay();
      }
      else {
        this.saveDesign(design.designType);
      }
    }
    else {
      // save design in localstorage
      localStorage.setItem("design", JSON.stringify(this.props.design));
      this.props.history.push("/signup?saveNewDesign=true");
    }

  }

  async postData() { 
    const apiName = 'websitebackgroundmakerapi';
    const path = '/upload-design';
    const myInit = { 
      headers: { 
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
}


  async saveUserDesign() {
    if (this.state.userData) {

      
      this.setState({isSaving: true});

      
      // check user limit 
      if (this.props.designs) {
        if (this.props.designs.length >= 10) {

          this.props.addToast('Design limit exceeded, upgrade to paid plan.', { appearance: 'warning', autoDismiss: true, autoDismissTimeout: 2500 });
          this.setState({isSaving: false});
          return;
        }
        
      }
      else {
        // designs prop doesn't exist, get length
      }

      let design = this.props.design;
      let body_str = JSON.stringify(design.body);
      let design_data;

      let response;
      if (this.state.designId) {
        // update design
        design_data = {name: design.title, id: this.state.designId, body: body_str, designType: design.designType };
        response = await API.graphql(graphqlOperation(updateTodo, { input: design_data }))
        .catch((error) => 
           {
            this.props.addToast('An error occurred. Want to try again?', { appearance: 'warning', autoDismiss: true, autoDismissTimeout: 2500 });
          this.setState({isSaving: false}
          
          );
          return 0;
        })
      }
      else {
        // add new design
        design_data = {name: design.title, id: uuidv4(), body: body_str, designType: design.designType };
        response = await API.graphql(graphqlOperation(createTodo, { input: design_data }))

        .catch((error) => 
          {
          this.props.addToast('An error occurred. Want to try again?', { appearance: 'warning', autoDismiss: true, autoDismissTimeout: 2500 });
          this.setState({isSaving: false});
        
          return 0;
        
        })
      }

      if (design.designType == "triangles") {
        this.saveTriangleDesign(design_data.id, "private");
      }
      else if (design.designType == "animated" || design.designType == "svgWavesAnimated") {
        this.saveAnimatedDesign(design_data.id);

      }
      else if (design.designType == "svgWaves") {
        this.saveSVGWavesDesign(design_data.id);
      }
      else {
        // if successful 
        if (response && response.data) {
          this.setState({designId: design_data.id});
          this.displaySaved();
        }
      }
      
    }
    else {
      // not signed in
      localStorage.setItem("design", JSON.stringify(this.props.design));
      localStorage.setItem("saveNewDesignInixia", true);

      this.props.history.push("/signup?saveNewDesign=true");
    }


  }

  async saveNewTemplate() {
    if (this.state.userData) {
      this.setState({isSavingNewTemplate: true});

      let design = this.props.design;
      let body_str = JSON.stringify(design.body);


      let response;

      let design_data = {name: design.title, id: uuidv4(), body: body_str, designType: design.designType };

        response = await API.graphql(graphqlOperation(createTemplate, { input: design_data }))

        .catch((error) => 
          {
          this.props.addToast('An error occurred. Want to try again?', { appearance: 'warning', autoDismiss: true, autoDismissTimeout: 2500 });
          this.setState({isSavingNewTemplate: false});
        
          return 0;
        
        })
      //}

      if (design.designType == "triangles") {
        this.saveTriangleDesign(design_data.id, "public");
      }
      else if (design.designType == "animated") {
        this.saveAnimatedDesign(design_data.id);

      }
      else {
        // if successful 
        if (response && response.data) {
          this.setState({designId: design_data.id, isSavingNewTemplate: false});
          this.props.addToast('Saved successfully', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 1500 });

        }
      }
      
    }

  }

  async deleteTemplateFromDirectory() {

    if (this.state.templateId) {
      let template_data = {id: this.state.templateId};

      const designs_response = await API.graphql(graphqlOperation(deleteTemplate, { input: template_data }))
  
      if (designs_response && designs_response.data) {

        this.props.addToast('Deleted successfully', { appearance: 'success', placement: 'top-center', autoDismiss: true, autoDismissTimeout: 1500 });
  
      }
      else {
        // there was an error 
        this.props.addToast('Error: couldn not delete design', { appearance: 'warning', placement: 'top-center', autoDismiss: true, autoDismissTimeout: 2500 });
      }
        
    }

          
  }

  async saveTemplate() {
    if (this.state.userData) {
      this.setState({isSavingTemplate: true});

      let design = this.props.design;
      let body_str = JSON.stringify(design.body);


        let design_data = {name: design.title, id: this.state.templateId, body: body_str, designType: design.designType };

        let response = await API.graphql(graphqlOperation(updateTemplate, { input: design_data }))

        .catch((error) => 
          {
          this.props.addToast('An error occurred. Want to try again?', { appearance: 'warning', autoDismiss: true, autoDismissTimeout: 2500 });
          this.setState({isSavingTemplate: false});
        
          return 0;
        
        })
      //}

      if (design.designType == "triangles") {
        this.saveTriangleDesign(design_data.id, "public");
      }
      else {
        // if successful 
        if (response && response.data) {
          this.setState({designId: design_data.id, isSavingTemplate: false});

          if (design.designType == "animated") {
            var canvas = document.querySelector(".tsparticles-canvas-el");
            var img    = canvas.toDataURL("image/png");
            var blobData = this.dataURItoBlob(img);
            var file = new File([blobData], "image.PNG");
            let img_name = design_data.id + ".png";
  
            Storage.put(img_name, file, {
              
              contentType: 'image/png'
            })
            .then (result => {
              this.props.addToast('Saved successfully', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 1500 });
  
            })
           .catch(err => console.log("error saving image"));
          }
          


        }
      }
      
    }
    else {
      // not signed in
      localStorage.setItem("design", JSON.stringify(this.props.design));
      localStorage.setItem("saveNewDesignInixia", true);

      this.props.history.push("/signup?saveNewDesign=true");
    }


  }

  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  }

  displaySaved() {
    this.props.addToast('Saved successfully', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 1500 });
    this.setState({isSaving: false});
  }

  saveSVGWavesDesign(design_id) {
    var app = this;

    let draw = SVG("svgwaves_elem").size("100%", "100%");
    let group = draw.group("main");
    let gradient_group = draw.group("gradient");

    draw.viewbox(0, 0, 1200, 900);

    let gradient = draw.gradient("linear", (add) => {
      add.at(0, this.props.design.body.gradient.color1);
      add.at(1, this.props.design.body.gradient.color2);
    });
    
    let svg_background = group
      .rect(1200, 1900).move(0, 0)
      .fill(gradient)
      .id("gradient_background")
      .opacity(1.0);

    group.path('M0 67 C 273,183 822,-41 1920.01,106 V 359 H 0 V 67 Z')
    .fill("white").move(0, 700);
    svg_background.back();
    let svg_string = draw.svg();
    //

    const canvas_svg = document.createElement('canvas');
    canvas_svg.width = 1200;
    canvas_svg.height = 900
    const ctx = canvas_svg.getContext('2d');
    
    let v = Canvg.fromString(ctx, svg_string);


    // // Start SVG rendering with animations and mouse handling.
    v.start();

    canvas_svg.toBlob(function(blob) {
      // var url  = window.URL.createObjectURL(blob);
      // window.location.assign(url);
      var file = new File([blob], "image.PNG");
       let img_name = design_id + ".png";
       Storage.put(img_name, file, {
        level: 'private',
         contentType: 'image/png'
       })
       .then (result => {
         app.displaySaved();
         app.setState({designId: design_id});
         document.getElementById("svgwaves_elem").style.display = "none";
       }
    
       )
      .catch(err => console.log("error saving image"));
    });
  }

  saveAnimatedDesign(design_id) {
    var app = this;

    var canvas = document.querySelector(".tsparticles-canvas-el");
    var img    = canvas.toDataURL("image/png");
    var blobData = this.dataURItoBlob(img);
    var file = new File([blobData], "image.PNG");
    let img_name = design_id + ".png";

    Storage.put(img_name, file, {
      level: 'private',
      contentType: 'image/png'
    })
    .then (result => {
      this.displaySaved();
    })
   .catch(err => console.log("error saving image"));
  }

  saveTriangleDesign(design_id, privacy_level) {
    var app = this;
    let elem = document.getElementById("triangles");
    let svg = elem.firstChild;
    //

    let svg_str = svg.innerHTML;


    let draw = SVG("triangles_elem").size("100%", "100%");
    let group = draw.group("main");
    let gradient_group = draw.group("gradient");

    draw.viewbox(0, 0, 1200, 900);

    let svg_elem_group = group.svg(svg_str);
    let gradient = draw.gradient("linear", (add) => {
      add.at(0, this.props.design.body.color1);
      add.at(1, this.props.design.body.color2);
    });
    
    let svg_background = group
      .rect(1200, 1900).move(0, 0)
      .fill(gradient)
      .id("gradient_background")
      .opacity(1.0);
    svg_background.back();
    let svg_string = draw.svg();
    //

    const canvas_svg = document.createElement('canvas');
    canvas_svg.width = 1200;
    canvas_svg.height = 900
    const ctx = canvas_svg.getContext('2d');
    
    let svg_elem = elem.firstChild;

    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svg_elem);

    let v = Canvg.fromString(ctx, svg_string);


    // // Start SVG rendering with animations and mouse handling.
    v.start();

    canvas_svg.toBlob(function(blob) {
      // var url  = window.URL.createObjectURL(blob);
      // window.location.assign(url);
      app.uploadTrianglesDesign(design_id, privacy_level, blob);
    });
  }

  uploadTrianglesDesign(design_id, privacy_level, blob) {
    var app = this;
    if (privacy_level == "private") {
      var file = new File([blob], "image.PNG");

      let img_name = design_id + ".png";
       Storage.put(img_name, file, {
         level: "private",
         contentType: 'image/png'
       })
       .then (result => {
         app.displaySaved();
         app.setState({designId: design_id});
       }
    
       )
      .catch(err => console.log("error saving image"));
    }
    else {
      var file = new File([blob], "image.PNG");
       let img_name = design_id + ".png";
       Storage.put(img_name, file, {
         contentType: 'image/png'
       })
       .then (result => {
         app.displaySaved();
         app.setState({designId: design_id});
       }
    
       )
      .catch(err => console.log("error saving image"));
    }
  }

  saveDesign(designType) {
  
    let design = this.props.design;
    let body_str = JSON.stringify(design.body);

    const designData = {
      title: "Design 1",
      body: body_str,
      designType: designType,
    };


    if (this.state.designId) {
      this.postUpdatedDesign(designData);
    }
    else {
      this.postNewDesign(designData);
    }
  }

  saveSVG() {
    this.deselectAllElements();
    
    let main_group = this.props.group;
    
    let svg_str = main_group.svg();

    // children
    let children = main_group.children();
    let group_children_svg_str = "";
    for (let k = 0; k < children.length; k++) {
      let childNode = children[k];

      if (childNode.type != "defs") {

        group_children_svg_str += childNode.svg();
      }

    }

    let defs = main_group.defs().svg();
    let body = {"svg_str": group_children_svg_str, defs: defs}
    let body_str = JSON.stringify(body);
    const designData = {
      title: "Design1",
      body: body_str,
      designType: "svg",
    };

    if (this.state.designId) {
      this.postUpdatedDesign(designData);
    }
    else {
      this.postNewDesign(designData);
    }
  }

  saveSVGOverlay() {
    this.deselectAllElements();
    
    let main_group = this.props.group;
    
    let svg_str = main_group.svg();

    // children
    let children = main_group.children();
    let group_children_svg_str = "";
    for (let k = 0; k < children.length; k++) {
      let childNode = children[k];

      if (childNode.type != "defs") {

        group_children_svg_str += childNode.svg();
      }

    }

    let defs = main_group.defs().svg();
    let body = {"svg_str": group_children_svg_str, defs: defs, particle_options: this.props.design.body.particle_options};
    let body_str = JSON.stringify(body);
    const designData = {
      title: "Design1",
      body: body_str,
      designType: "svg_overlay",
    };


    if (this.state.designId) {
      this.postUpdatedDesign(designData);
    }
    else {
      this.postNewDesign(designData);
    }
  }


  postNewDesign(designData) {
    // check if logged in first
    authMiddleware(this.props.history);
    const authToken = this.props.user.idToken;

    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

    axios
      .post("https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/design", designData)
      .then((response) => {
        if (response.status == 200) {
          // user logged-in successfully
          this.props.addToast('Saved successfully', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 1500 });
          this.setState({isSaving: false});
        }

      })
      .catch((error) => {
      });
  }  

  postUpdatedDesign(designData) {
    const authToken = this.props.user.idToken;

    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .put("https://us-central1-svgbackgroundmaker.cloudfunctions.net/api/design/" + this.state.designId, designData)
      .then((response) => {
        if (response.status == 200) {
          // user logged-in successfully
          this.props.addToast('Saved successfully', { appearance: 'success' });
          this.setState({isSaving: false});
        }

      })
      .catch((error) => {

      });
  }

  moveForward() {
    if (this.props.selectedElement) {
      this.props.selectedElement.forward();

    }
  }

  moveBackward() {
    if (this.props.selectedElement) {
      this.props.selectedElement.backward();
    }
  }

  
  bringToFront() {
    if (this.props.selectedElement) {
      this.props.selectedElement.front();
    }
  }

  bringToBack() {
    if (this.props.selectedElement) {
      this.props.selectedElement.back();
    }
  }


  deselectAllElements() {
    this.props.selectedElements.forEach((element) => {
      element.selectize(false);
    })
  }

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {console.log('Not signed in')});
  }


  componentDidMount() {
    const value = queryString.parse(this.props.location.search);
    this.state.designId = value.design;
    this.state.templateId = value.template;

    var app = this;

    this.getUser().then(userData => {
      this.props.setUserAction(userData);
        if (userData) {
          // the array of groups that the user belongs to
          let groups = userData.signInUserSession.accessToken.payload["cognito:groups"];
          this.setState({userGroups: groups  })
        }

        this.setState({userData: userData})
        
    });
  }

  render() {
    return(
      <div className="containerNav lightThemeNav">

        <nav className="headerNav">
          <div className="leftnav">
            <Link to="/get-started" className="logo">
            <svg
                className="w-8 h-8"
                viewBox="0 0 32 32"
                width="40"
                height="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <radialGradient
                    cx="21.152%"
                    cy="86.063%"
                    fx="21.152%"
                    fy="99.063%"
                    r="79.941%"
                    id="header-logo"
                  >
                    <stop stopColor="hsla(217, 100%, 50%, 1) " offset="0%" />
                    <stop stopColor="hsla(186, 100%, 69%, 1) " offset="100%" />
                  </radialGradient>
                </defs>
                <rect
                  width="32"
                  height="32"
                  rx="16"
                  fill="url(#header-logo)"
                  fillRule="nonzero"
                />
              </svg>
              
            </Link>
          </div>
          
          <div className="rightnav">
          {/* <div className={(this.state.design.designType === "animated") ? "hidden" : ""}>

            <Menu>
              <MenuButton as={Button} rightIcon="chevron-down">
                Arrange
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => this.moveForward()}>Arrange forward</MenuItem>
                <MenuItem onClick={() => this.moveBackward()}>Arrange backward</MenuItem>
                <MenuItem onClick={() => this.bringToFront()}>Bring to front</MenuItem>
                <MenuItem onClick={() => this.bringToBack()}>Move to back</MenuItem>

              </MenuList>
            </Menu>
            </div> */}
          </div>
          <div className="rightnav">
              <div className={(this.state.userGroups.includes("admins")) ? "" : "hidden"}>
                  <Box as={IoMdTrash} aria-label="Delete template element" onClick={() => this.deleteTemplateFromDirectory()} size="32px" className="icon" />
                </div>
          </div>
          {/* <div className="rightnav">
            <Button onClick={() => this.downloadSVG()} variant="solid" variantColor="pink">Download</Button>
          </div> */}

          <div className="rightnav">
            <ExportDrawer isAgent={false} design={this.props.design}></ExportDrawer>
          </div>
           <div className="rightnav">
            <Button onClick={() => this.saveUserDesign()} variant="solid" variantColor="purple" 
            loadingText="Saving" isLoading={this.state.isSaving}>Save</Button>

          </div> 
          <div className="rightnav" className={(this.state.userGroups.includes("admins")) ? "" : "hidden"}>
            <Button onClick={() => this.saveTemplate()} variant="solid" variantColor="teal" loadingText="Saving" 
            isLoading={this.state.isSavingTemplate}>Save Template</Button>

          </div>
          <div className="rightnav" className={(this.state.userGroups.includes("admins")) ? "" : "hidden"}>
            <Button onClick={() => this.saveNewTemplate()} variant="solid" variantColor="teal" loadingText="Saving" 
            isLoading={this.state.isSavingNewTemplate}>Create New Template</Button>

          </div>
          {/* <div className="rightnav">
            <Link to={"/fullscreen?design=" + this.state.designId}>
              <Maximize2 />
            </Link>
          </div> */}

        </nav>
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.design != prevProps.design && this.props.design != null) {
        this.setState({design: this.props.design});
      }

      if (this.props.user != prevProps.user) {
        this.setState({user: this.props.user});
      }
  }
}

export default withToast(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditorHeaderNav)));