import  React , {useState} from 'react';
import styles from './DashboardSidebar.css';
import { Grid, Image, User } from 'react-feather';
import {
    Link,
    withRouter,
  } from "react-router-dom";

class DashboardSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        gradients: [],
        activeBtn: "designs",
        links: [
            {id: "designs",
            name: "Designs",
            icon: <Image/>,
        },
            {
                id: "templates",
                name: "Templates",
                icon: <Grid/>,
            },
            {
                id: "account",
                name: "Account",
                icon: <User/>,
            }
        ]
        
    };

  }
  
  componentDidMount() {
    this.initActiveSidebarBtn();

  }

  initActiveSidebarBtn() {
    let array_loc = this.props.location.pathname.split("/");
    if (array_loc.length == 3) {
      let item = array_loc[array_loc.length - 1];
      item = item.toString();
      this.setState({activeBtn: item });
    }
  }

  updateActiveBtn(link) {
    this.setState({activeBtn: link.id});
  }

  render() {
    return(
        <div className="dashboard_sidebar">
        {this.state.links.map(link => {
          return (
            <div key={link.id}>
              <ul>
                <li
                  className={
                    (link.id === this.state.activeBtn ? " active" : "")
                  }
                >
                  {/* {link.name} {link.id === activeLink && "active!"} */}
                  <Link to={"/dashboard/" + link.id}
                    onClick={() => this.updateActiveBtn(link)}>
                      {link.icon}
                      {link.name}
                  </Link>
                </li>
              </ul>
            </div>
          );
        })}
      </div>


    )
  }
}

export default withRouter(DashboardSidebar);