import React, {useEffect} from 'react';
import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/FeaturesHome';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import ImageFeatureTabs from 'partials/ImageFeatureTabs';
import PricingTables from 'partials/PricingTables';
import PreviewGallery from 'pages/PreviewGallery';

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  
  });

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        
        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        <ImageFeatureTabs />
        <FeaturesBlocks />
        <PreviewGallery></PreviewGallery>
        {/* <News /> */}
        {/* <TestimonialsCarousel></TestimonialsCarousel> */}
        <PricingTables></PricingTables>
        <Cta />


      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}


export default Landing;