import firebase from "firebase/app";
require('firebase/auth');

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDum8cbsl-Zn4WVrKjjsQVZcTe0sjCyLHk",
    authDomain: "svgbackgroundmaker.firebaseapp.com",
    databaseURL: "https://svgbackgroundmaker.firebaseio.com",
    projectId: "svgbackgroundmaker",
    storageBucket: "svgbackgroundmaker.appspot.com",
    messagingSenderId: "1059159035153",
    appId: "1:1059159035153:web:cdc5916e777380771190b0",
    measurementId: "G-2CSM7N58JE",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};

// export const generateUserDocument = async (user, username) => {

//     if (!user) return;

//     const userRef = firestore.doc(`users/${user.uid}`);
//     const snapshot = await userRef.get();
    
//     if (!snapshot.exists) {
//       const { email } = user;
//       try {
//         await userRef.set({
//           email,
//           userId: user.uid,
//           username: username,
//         });
//       } catch (error) {
//         console.error("Error creating user document", error);
//       }
//     }
//     return getUserDocument(user.uid);
//   };

//   const getUserDocument = async uid => {

//     if (!uid) return null;
//     try {
//       const userDocument = await firestore.doc(`users/${uid}`).get();
//       return {
//         uid,
//         ...userDocument.data()
//       };
//     } catch (error) {
//       console.error("Error fetching user", error);
//     }
//   };