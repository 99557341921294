import  React , {useState} from 'react';
import {  Link,  } from 'react-router-dom';
import styles from "./GradientPreview.css";
import gradientData from '../GradientPacks/gradient_packs.json';
import { connect } from "react-redux";
import { withRouter, } from 'react-router-dom'
import axios from "axios";

const mapStateToProps = (state) => ({
    ...state,
  });

  const mapDispatchToProps = (dispatch) => ({

  
  });

  class GradientPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        gradients: [],
        gradient: {},
    };
  }

  

  componentDidMount() {
    this.state.gradients = gradientData.gradients;

      const gradient_id = this.props.match.params.id;

      let gradient = gradientData.gradients.filter(gradient => gradient.name === gradient_id)[0];
      this.setState({gradient: gradient});
  }

  render() {


             
    return(
        <div>
            <h1 data-aos="zoom-y-out">Gradient Preview</h1>

            <Link to={"/fullscreen-background/" + this.state.gradient.name}>
                <div className="gradient_preview rounded_border" style={{background: `linear-gradient(90deg, ${this.state.gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}}></div> 

            </Link>


            
            <h2 data-aos="zoom-y-out" data-aos-delay="100">Buttons</h2>
            <section className="btns">
                <button style={{backgroundImage: `linear-gradient(90deg, ${this.state.gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}}>Submit</button>
                <button style={{backgroundImage: `linear-gradient(90deg, ${this.state.gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}}className="med-rounded-btn">Enter</button>
                <button style={{backgroundImage: `linear-gradient(90deg, ${this.state.gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}} className="rounded-btn">Home</button>
            </section>

            <h2>Underline</h2>
            <span className="underline" style={{backgroundImage: `linear-gradient(90deg, ${this.state.gradient.color1} 0%, hsla(294, 100%, 55%, 1) 100%)`}}>Underlined text</span>
            
            <h1 className="gradient-text">{this.state.id}</h1>
  
            <h2>Blob</h2>
            <h2>Particle Background</h2>
            <h2>Non Rectangular Background</h2>
            <h2>Cards</h2>
            <h2>Animated Background</h2>
            <h2>Button Outline</h2>
            <a class="button" href="#"><span>Login</span></a>

        </div>
        
      )       
                    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GradientPreview));