import React, { useState } from "react";
import styles from "./BlobGenerator.css";
import { setBgAction } from "actions/setBgAction";
import { setElementAction } from "actions/setElementAction";
import { connect } from "react-redux";
import SVG from "svg.js";
import "svg.draggable.js";
import "svg.select.js";
import "svg.resize.js";
import { motion } from "framer-motion";
import { ChromePicker } from "react-color";
import * as blobs2 from "blobs/v2";
import Slider from "rc-slider";
import { Button } from "@chakra-ui/core";
import { ChevronLeft } from 'react-feather';

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setElementAction: (payload) => dispatch(setElementAction(payload)),
});

class BlobGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draw: null,
      gradient_color: "#897",
      isVisible: 1,
      blob_elem: null,
      blob_path: null,
      randomness: 4,
      extraPoints: 2,
      draw: null,
      extraPointsChange: false,
    };
  }

  componentDidMount() {
    this.state.draw = SVG("blob_container").size("100%", "100%");
    this.state.draw.viewbox(0, 0, 397, 310);

    this.state.gradient = this.state.draw.gradient("linear", (add) => {
      add.at(0, this.props.bgColor.color1);
      add.at(1, this.props.bgColor.color2);
    });

    this.state.blob_path = blobs2.svgPath({
      seed: Math.random(),
      extraPoints: this.state.extraPoints,
      randomness: this.state.randomness,
      size: 256,
    });

    this.state.blob_elem = this.getSVGPathElement(this.state.draw);

    this.addClickListener(this.state.blob_elem);
  }

  addClickListener(blob_element) {
    var app = this;
    var click = function () {
      app.drawElementToCanvas();
    };

    blob_element.on("click", click);
  }

  onRandomnessSliderChange = (value) => {
    this.setState({
      randomness: value,
    });
  };

  onExtraPointsSliderChange = (value) => {
    if (value != this.state.extraPoints) {


      this.setState({
        extraPoints: value,
      });

      this.state.blob_elem.remove();
      let blob_path = blobs2.svgPath({
        seed: Math.random(),
        extraPoints: value,
        randomness: this.state.randomness,
        size: 256,
      });

      this.setState({ blob_path: blob_path });

      let blob_elem = this.state.draw
        .path(blob_path)
        .fill("#F06")
        .opacity(1.0);

      this.setState({ blob_elem: blob_elem });
      this.setState({ extraPointsChange: true });
      this.addClickListener(blob_elem);

    } else {
      this.setState({ extraPointsChange: false });
    }
  };

  getSVGPathElement(draw) {
    let elem = draw.path(this.state.blob_path).fill("#F06").opacity(1.0);
    return elem;
  }

  drawElementToCanvas() {
    let element = this.props.draw
      .path(this.state.blob_path)
      .fill("#F06")
      .opacity(1.0);
    this.props.setElementAction(element);
  }

  renderComponent(component_type) {
    switch (component_type) {
      case "back":
        // move back
        this.props.history.goBack();
        break;
    }
  }

  generateBlob() {
    // this.state.blob_elem.remove();
    this.state.blob_path = blobs2.svgPath({
      seed: Math.random(),
      extraPoints: this.state.extraPoints,
      randomness: this.state.randomness,
      size: 256,
    });

    if (this.state.extraPointsChange == false) {
      this.state.blob_elem.animate(200).plot(this.state.blob_path);
    } else {
      this.state.blob_elem.plot(this.state.blob_path);
      this.state.extraPointsChange = false;
    }
  }

  handleClick(gradient) {
    this.applyGradient(gradient);
  }

  applyGradient(gradient) {
    this.props.setBgAction(gradient);
  }

  render() {
    return (
      <div>
        <article className="heading">
          <h1>Blob Generator</h1>
          <ChevronLeft
            onClick={() => this.renderComponent("back")}
          />
        </article>

        <section>
          <Button
            variantColor="pink"
            variant="solid"
            onClick={() => this.generateBlob()}
          >
            Randomize
          </Button>
        </section>

        <section id="blob_container"></section>

        <section>
          <div class="slider">
            <p>Extra Points</p>
            <Slider
              defaultValue={this.state.extraPoints}
              marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 }}
              step={null}
              max={6}
              onChange={this.onExtraPointsSliderChange}
            />
          </div>

          <div class="slider">
            <p>Randomness</p>
            <Slider
              max={60}
              defaultValue={this.state.randomness}
              marks={{
                10: 10,
                20: 20,
                30: 30,
                40: 40,
                50: 50,
                60: 60,
                70: 70,
                80: 80,
              }}
              step={null}
              onChange={this.onRandomnessSliderChange}
            />
          </div>
        </section>

        {/* <ChromePicker /> */}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BlobGenerator);
