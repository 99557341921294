export const authMiddleware = (history) => {
    const authToken = localStorage.getItem('AuthToken');
    if(authToken === null){
        history.push('/login')
    }
}

export const logoutUser = (history) => {
    localStorage.removeItem('AuthToken');  
    history.push('/')
    
}